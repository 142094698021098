import React from 'react';
import './../../stylesheets/budget.css';
import { Container, Row, Col, Button } from 'react-bootstrap';

import Slider from '@material-ui/core/Slider';
/** 
 * Redux Actions
 */
import { addBudget, editBudget } from '../../actions/budgetActions';

/**
 * Higher Level Components
 */
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'


class Budget extends React.Component {
  state = {
    values:[this.props.budget.minimum, this.props.budget.maximum]
  };

  handleSubmit = () => {
    if(this.props.mode==='CREATE'){
      const data = {
        minimum:this.state.values[0],
        maximum:this.state.values[1],
        id:this.props.canvas_id
      }
      this.props.addBudget(data)
    }
    else{
      const data = {
        minimum:this.state.values[0],
        maximum:this.state.values[1],
        id:this.props.budget._id
      }
      this.props.editBudget(data);
    }
    this.props.onContinue();
  }
  
  handleChange = (event, newValue) => {
    this.setState({values:newValue})
  }

  render() {
    
    return (
      <Container fluid={true} style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
        <h2 className="col-12 question-h2" style={{marginBottom:60, textAlign:'center'}}>What kind of budget do you have?</h2>
            {/* <h4 className="col-12 question-h4" style={{ "textAlign": "center" }}>If you know your exact budget, use the other option to let us know</h4> */}
            
            <div style={{display:'flex', justifyContent:'center', marginBottom:40}}>
              <h6 style={{marginRight:20}}>Min: ${this.state.values[0]}</h6>
              <h6>Max: ${this.state.values[1]}</h6>
            </div>
          <Slider
            defaultValue={50}
            min={1}
            max={20000}
            aria-labelledby="discrete-slider-always"
            step={50}
            value={this.state.values}
            onChange={this.handleChange}
            valueLabelDisplay="on"
          />
          <Col xs={12}>
            <Row className='justify-content-md-center'>
              <Button variant="dark" onClick={this.handleSubmit} style={{marginTop:20, marginBottom:40}}>Submit</Button>
            </Row>
          </Col>
      </Container>
    )
  }
}
/**
 * 
 * @param {*} state
 * Maps Redux state to component props 
 */
const mapStateToProps = (state) => {
  let budget = state.canvas.budget
  let mode='CREATE';
  if (budget === undefined || budget === null || Object.keys(budget).length === 0) {
      budget={
        minimum:0,
        maximum:20000
      }
  }
  else{
    mode='EDIT';
  }

  return {
      // Budget specific 
      budget: budget,

      //General Function
      canvas_id: state.canvas.id,
      mode:mode,

  }
}

/**
* Maps Redux actions to component props
* @param {*} dispatch
*  
*/
const mapDispatchToProps = (dispatch) => {

  return {
      addBudget: (data) => dispatch(addBudget(data)),
      editBudget: (data) => dispatch(editBudget(data)),
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Budget))