import React, { Component } from "react";
import { Row, Col, Button, Form } from "react-bootstrap";
import ReactCrop from "react-image-crop";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
    updateUser,
    sendVerificationEmail,
} from "../../actions/accountActions";
import "../../stylesheets/account.css";
import "react-image-crop/dist/ReactCrop.css";

class ProfileDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mode: "READ",
            name: null,
            email: null,
            vemailSent: false,
        };
    }

    handleSubmit = () => {
        let data = {
            id: this.props.user.id,
            updateType: "PROFILE_DETAILS",
            name: this.state.name,
            email: this.state.email,
        };
        this.props.updateUser(data);

        this.setState({ mode: "READ" });
    };

    handleChange = (e) => {
        e.preventDefault();
        this.setState({ [e.target.name]: e.target.value });
    };

    sendVEmail = (e) => {
        e.preventDefault();
        sendVerificationEmail(this.props.user.id);
        this.setState({
            vemailSent: true,
        });
    };

    renderReadMode = () => {
        console.log(this.props.user);
        return (
            <Col xs={12} className='profileDetails'>
                <Row>
                    <Col xs={12}>
                        <h1>
                            {" "}
                            {this.props.user.name
                                ? this.props.user.name
                                : this.props.user.email.split("@")[0]}
                        </h1>
                    </Col>
                    <Col xs={12}>
                        <p
                            style={{
                                fontWeight: "400",
                                color: "#888",
                                marginBottom: "0.5rem",
                            }}
                        >
                            {this.props.user.email}
                        </p>
                    </Col>
                    <Col xs={12}>
                        <a
                            href='#'
                            onClick={() => {
                                this.setState({
                                    mode: "EDIT",
                                    name: this.props.user.name
                                        ? this.props.user.name
                                        : this.props.user.email.split("@")[0],
                                    email: this.props.user.email,
                                });
                            }}
                            style={{ color: "black", fontWeight: 400 }}
                        >
                            Edit Details
                        </a>
                    </Col>
                    <Col xs={12}>
                        {this.props.user.isVerified ? (
                            <p style={{ fontWeight: 400 }}>You are verified</p>
                        ) : this.state.vemailSent ? (
                            <p style={{ fontWeight: 400 }}>
                                Verification email sent
                            </p>
                        ) : (
                            <a
                                href='#'
                                onClick={this.sendVEmail}
                                style={{ color: "black", fontWeight: 400 }}
                            >
                                Send verification email
                            </a>
                        )}
                    </Col>
                </Row>
            </Col>
        );
    };

    renderEditMode = () => {
        return (
            <Col xs={12} className='profileDetails'>
                <Row>
                    <Form onSubmit={this.handleSubmit}>
                        <Col xs={12}>
                            <input
                                type='text'
                                value={this.state.name}
                                name='name'
                                onChange={this.handleChange}
                                className='col-xs-12'
                                style={{
                                    fontSize: "3rem",
                                    width: "100%",
                                    marginBottom: "0.5rem",
                                    outline: "none",
                                    border: "none",
                                    borderBottom: "2px solid black",
                                }}
                            />
                            {/* <h1 style={{"fontWeight":"500"}}> {this.props.user.name? this.props.user.name: this.props.user.email.split("@")[0]}</h1> */}
                        </Col>
                        {/* <Col xs`={12}>
                                <input  type="email" 
                                        value={this.state.email} 
                                        name="email" 
                                        onChange={this.handleChange} 
                                        className="col-xs-12"
                                        style={{"marginBottom":"1rem", "width":"100%", "color":"#888", "outline":"none", "border": "none", "borderBottom":"2px solid black"}}/>
                            </Col> */}
                        <Col xs={12}>
                            <Button
                                type='submit'
                                variant='dark'
                                style={{ marginTop: "0rem" }}
                            >
                                Done
                            </Button>
                            {/* <a href="#" onClick={() => {this.setState({mode:"EDIT"})}}>Edit Details</a> */}
                        </Col>
                    </Form>
                </Row>
            </Col>
        );
    };

    render() {
        let renderView = null;
        if (this.state.mode === "READ") {
            renderView = this.renderReadMode();
        } else if (this.state.mode === "EDIT") {
            renderView = this.renderEditMode();
        }

        return renderView;
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateUser: (data) => dispatch(updateUser(data)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(ProfileDetails));
