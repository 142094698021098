import React from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {Modal} from 'react-bootstrap';
import '../../stylesheets/editTile.css';
import Budget from './Budget';

class EditTile extends React.Component{
  renderData = () => {
    switch(this.props.type){
      case 'BUDGET':
        return <Budget onContinue={this.props.onContinue}></Budget>
      case 'SKETCH':
        
        return ;
      default:
        break;
    }
  }
  render(){
    if(this.props.type==='SKETCH'){
      this.props.history.push('/sketch')
      return <></>
    }
    else{
      return(
        <Modal
          size="xl"
          show={this.props.isOpen}
          onHide={()=>this.props.onContinue()}
          aria-labelledby="example-modal-sizes-title-sm"
          id="edit-tile"
        >
          <Modal.Header closeButton>
          </Modal.Header>
          <Modal.Body>   
            {this.renderData()}
          </Modal.Body>
        </Modal>
      )
    }
  }
}

const mapStateToProps = (state) => {
  return {
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EditTile));