
let ONBOARDING_FLOW = {
  data:
  [
    {
      id:"question1",
      type:"QUESTION",
      subType:'MCQ',
      title:"How would you describe your daily work?",
      subtitle:"Your choices help us make the right decisions in design and fabrication",
      multiple_select:true,
      options:[
        {
          image:"https://storage.googleapis.com/naya-studio-uploads/google-onboarding-icons/engineering.png",
          text:"Engineering"
        },
        {
          image:"https://storage.googleapis.com/naya-studio-uploads/google-onboarding-icons/design.png",
          text:"Design"
        },
        {
          image:"https://storage.googleapis.com/naya-studio-uploads/google-onboarding-icons/marketing.png",
          text:"Marketing"
        },
        {
          image:"https://storage.googleapis.com/naya-studio-uploads/google-onboarding-icons/management.png",
          text:"Management"
        },
        {
          image:"https://storage.googleapis.com/naya-studio-uploads/google-onboarding-icons/operations.png",
          text:"Operations"
        },
        {
          image:"https://storage.googleapis.com/naya-studio-uploads/google-onboarding-icons/research.png",
          text:"Research"
        },
        {
          image:"https://storage.googleapis.com/naya-studio-uploads/google-onboarding-icons/other.png",
          text:"Other (Please Specify)",
          type:"OTHER_OPTION"
        }
      ]
    },
    {
      id:"question2",
      type:"QUESTION",
      subType:'MCQ',
      title:"Where do you usually work when you WFH?",
      subtitle:"Your choices help us make the right decisions in design and fabrication",
      multiple_select:true,
      options:[
        {
          image:"https://naya-studio-uploads.s3.amazonaws.com/1571847608372.png",
          text:"Living Room"
        },
        {
          image:"https://naya-studio-uploads.s3.amazonaws.com/1571847611202.png",
          text:"Dining Room"
        },
        {
          image:"https://naya-studio-uploads.s3.amazonaws.com/1571847615968.png",
          text:"Bedroom"
        },
        {
          image:"https://naya-studio-uploads.s3.amazonaws.com/1571847647187.png",
          text:"Home Office"
        },
        {
          image:"https://storage.googleapis.com/naya-studio-uploads/google-onboarding-icons/minimal_desk_surface.png",
          text:"Closet"
        },
        {
          image:"https://naya-studio-uploads.s3.amazonaws.com/1571847627201.png",
          text:"Outdoors"
        },
        {
          image:"https://storage.googleapis.com/naya-studio-uploads/google-onboarding-icons/other.png",
          text:"Other (Please Specify)",
          type:"OTHER_OPTION"
        }
      ]
    },
    {
      id:"question3",
      type:"QUESTION",
      subType:'MCQ',
      title:"Which of the following descriptions apply to your current workspace?",
      subtitle:"Your choices help us make the right decisions in design and fabrication",
      multiple_select:true,
      options:[
        {
          image:"https://storage.googleapis.com/naya-studio-uploads/google-onboarding-icons/fixed.png",
          text:"Fixed Setup"
        },
        {
          image:"https://storage.googleapis.com/naya-studio-uploads/google-onboarding-icons/temporary.png",
          text:"Temporary"
        },
        {
          image:"https://storage.googleapis.com/naya-studio-uploads/google-onboarding-icons/shared.png",
          text:"Shared"
        },
        {
          image:"https://storage.googleapis.com/naya-studio-uploads/google-onboarding-icons/private.png",
          text:"Private"
        },
        {
          image:"https://storage.googleapis.com/naya-studio-uploads/google-onboarding-icons/mobile_desk.png",
          text:"Mobile"
        },
      ]
    },
    {
      id:"question4",
      type:"QUESTION",
      subType:'MCQ',
      // subType:'TEXT',
      multiple_select:true,
      title:"What is currently on your desk?",
      placeholder:"Eg, Number of monitors, wired and wireless peripherals, lamps, writing utensils, etc",
      options:[
        {
          image:"https://storage.googleapis.com/naya-studio-uploads/google-onboarding-icons/laptop.png",
          text:"Laptop"
        },
        {
          image:"https://storage.googleapis.com/naya-studio-uploads/google-onboarding-icons/desktop.png",
          text:"Desktop Tower"
        },
        {
          image:"https://storage.googleapis.com/naya-studio-uploads/google-onboarding-icons/monitors.png",
          text:"External Monitors",
          instruction: "Please decribe # and type",
          type:"OTHER_OPTION"
        },
        {
          image:"https://storage.googleapis.com/naya-studio-uploads/google-onboarding-icons/keyboard_mouse.png",
          text:"External Mouse/Keyboard",
          instruction: "Please decribe # and type",
          type:"OTHER_OPTION"
        },
        {
          image:"https://storage.googleapis.com/naya-studio-uploads/google-onboarding-icons/hub_max.png",
          text:"Hub Max / Ambient Screen"
        },
        {
          image:"https://storage.googleapis.com/naya-studio-uploads/google-onboarding-icons/stationery.png",
          text:"Pens/Pencils"
        },
        {
          image:"https://storage.googleapis.com/naya-studio-uploads/google-onboarding-icons/notebooks.png",
          text:"Notebooks/Sketchbooks"
        },
        {
          image:"https://storage.googleapis.com/naya-studio-uploads/google-onboarding-icons/books.png",
          text:"Reference Books"
        },
        {
          image:"https://storage.googleapis.com/naya-studio-uploads/google-onboarding-icons/speakers.png",
          text:"Speakers"
        },
        {
          image:"https://storage.googleapis.com/naya-studio-uploads/google-onboarding-icons/paperwork.png",
          text:"Print-outs"
        },
        {
          image:"https://storage.googleapis.com/naya-studio-uploads/google-onboarding-icons/plants.png",
          text:"Plants"
        },
        {
          image:"https://storage.googleapis.com/naya-studio-uploads/google-onboarding-icons/lamp.png",
          text:"Lamps"
        },
        {
          image:"https://storage.googleapis.com/naya-studio-uploads/google-onboarding-icons/printer.png",
          text:"Printer"
        },
        {
          image:"https://storage.googleapis.com/naya-studio-uploads/google-onboarding-icons/other.png",
          text:"Other (Please Specify)",
          type:"OTHER_OPTION"
        },
      ]
    },
    {
      id:"question5",
      type:"QUESTION",
      subType:'MCQ',
      title:"What cables and outlets are you using?",
      subtitle:"Your choices help us make the right decisions in design and fabrication",
      multiple_select:true,
      options:[
        {
          image:"https://storage.googleapis.com/naya-studio-uploads/google-onboarding-icons/power_cable.png",
          text:"AC Power Devices",
          type:'OTHER_OPTION',
          instruction:'Enter # and type'
        },
        {
          image:"https://storage.googleapis.com/naya-studio-uploads/google-onboarding-icons/mobile_charger.png",
          text:"DC Power Devices",
          type:'OTHER_OPTION',
          instruction:'Enter # and type'
        },
        {
          image:"https://storage.googleapis.com/naya-studio-uploads/google-onboarding-icons/usb_hub.png",
          text:"USB Hub",
          type:'OTHER_OPTION',
          instruction:'Enter # and type'
        },
        {
          image:"https://storage.googleapis.com/naya-studio-uploads/google-onboarding-icons/wired_peripherals.png",
          text:"Wired Peripherals",
          type:'OTHER_OPTION',
          instruction:'Enter # and type'
        },
        {
          image:"https://storage.googleapis.com/naya-studio-uploads/google-onboarding-icons/usb_adapter.png",
          text:"USB Adapters",
          type:'OTHER_OPTION',
          instruction:'Enter # and type'
        },
        {
          image:"https://storage.googleapis.com/naya-studio-uploads/google-onboarding-icons/usb_wall_charger.png",
          text:"Multiport USB Wall Charger",
          type:'OTHER_OPTION',
          instruction:'Enter # and type'
        },
        {
          image:"https://storage.googleapis.com/naya-studio-uploads/google-onboarding-icons/power_strip.png",
          text:"Power Strip",
          type:'OTHER_OPTION',
          instruction:'Enter # and type'
        },
        {
          image:"https://storage.googleapis.com/naya-studio-uploads/google-onboarding-icons/docking.png",
          text:"Docking Station",
          type:'OTHER_OPTION',
          instruction:'Enter # and type'
        },
        {
          image:"https://storage.googleapis.com/naya-studio-uploads/google-onboarding-icons/other.png",
          text:"Other",
          type:'OTHER_OPTION'
        }
      ]
    },
    {
      id:"visual_moodboard",
      type:"VISUAL_MOODBOARD",
      title:"What style do you find most inspiring?",
      subtitle:"Your choices help us make the right decisions in design and fabrication",
      images:[
                'https://naya-studio-uploads.s3.amazonaws.com/1571849039875.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571849155284.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571849159774.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571849162913.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571849167205.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571849170491.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571845810900.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571845086290.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571845089242.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571845092340.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571845095913.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571845100755.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571845106701.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571845141312.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571845148787.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571845157265.png',
               'https://naya-studio-uploads.s3.amazonaws.com/1571845175240.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571845187621.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571845191932.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571845266048.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571845269557.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571845273718.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571845279202.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571845283389.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571845287163.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571845315349.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571845351247.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571845386554.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571845420958.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571845426194.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571845431084.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571845552753.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571845556100.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571845560358.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571845565330.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571845568547.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571845571495.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571845597920.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571845603943.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571845608658.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571845613256.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571845616015.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571845620163.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571845663383.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571845736337.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571845739613.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571846887924.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571845748111.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571845752483.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571846880275.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571845837898.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571845840717.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571845845526.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571845848942.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571845854408.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571845857602.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571849179927.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571849183419.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571849190470.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571849194892.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571849198580.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571849220179.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571845944888.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571845948987.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571845952562.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571845955556.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571845959559.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571845963358.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571846015537.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571846018482.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571846021584.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571846025185.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571846028642.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571846031930.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571846041729.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571846045442.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571846050824.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571846055003.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571846059980.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571846064449.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571846084551.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571846088009.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571846091327.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571846094764.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571846098011.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571846101910.png',
               'https://naya-studio-uploads.s3.amazonaws.com/1571846143076.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571846146363.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571846152807.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571846157488.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571846161595.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571846166706.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571846191168.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571846193836.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571846198623.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571846204181.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571846207969.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571846904285.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571846289730.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571846293282.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571846297758.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571846301743.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571846307987.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571846313315.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571846333013.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571846336206.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571846340185.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571846343507.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571846346924.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571846353286.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571846440097.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571846442897.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571846464872.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571976274055.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571846472472.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571846475775.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571846492114.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571846496133.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571846499856.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571846502591.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571846505408.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571846570223.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571849229055.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571849233045.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571849237315.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571849241443.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571849245274.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571849367980.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571846523054.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571846526723.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571846531150.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571846534254.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571846537427.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571846541560.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571846598773.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571846602079.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571846605340.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571846610586.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571846613444.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571846617434.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571846624902.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571846628120.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571846631497.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571846634969.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571846639073.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571846642748.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571846705067.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571846709974.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571846713288.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571846717839.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571846721520.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571846725042.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571846699918.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571846731524.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571846734839.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571846738153.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571846744178.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571846748660.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571846758856.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571846762269.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571846765868.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571846768988.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571846773784.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571846777576.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571849261178.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571849264988.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571849269205.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571849273728.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571849277891.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571849375898.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571849291090.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571849299989.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571849303659.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571849312114.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571849316649.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571849319127.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571849328859.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571849334204.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571849337118.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571849341617.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571849345707.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571849350732.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571844562884.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571844590382.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571844600819.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571844668895.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571844676379.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571844696732.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571844890651.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571844896924.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571844899941.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571844904796.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571844910720.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571844968229.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571845037771.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571845041592.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571845046997.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571845051836.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571845055821.jpeg',
               'https://naya-studio-uploads.s3.amazonaws.com/1571845061185.jpeg'
      ]
    },
    {
      id:"text_moodboard",
      type:"TEXT_MOODBOARD",
      title:"Select the words that represent your furniture style",
      subtitle:"Choose words to describe the style of your furniture",
      options: [
        {
          name:"Cultural",
          options:['African', 'Japanese', "Scandinavian", 'Italian', 'Spanish', 'Arabic']
        },
        {
          name:'Personality',
          options:['Bold', 'Casual', 'Chic', 'Classic', 'Airy', 'Colorful', 'Rustic', 'Cute', 'Brutalist', 'Eclectic', 'Experimental', 'Futuristic','Elegant', 'Masculine', 'Feminine', 'Minimalistic', 'Modern', 'Ornate', 'Retro', 'Sleek', 'Bohemian']
        },
        {
          name:'Shape',
          options:['Cubic', 'Angular', 'Circular', 'Edgy', 'Organic', 'Light', 'Rectilinear', 'Round', 'Slim', 'Modular', 'Geometric', 'Curvilinear']
        }
      ]
    },
    {
      id:"question6",
      type:"QUESTION",
      subType:'MCQ',
      title:"What else do you want to use your desk for?",
      subtitle:"Your choices help us make the right decisions in design and fabrication",
      multiple_select:true,
      options:[
        {
          image:"https://naya-studio-uploads.s3.amazonaws.com/1571848953487.png",
          text:"Work Surface"
        },
        {
          image:"https://naya-studio-uploads.s3.amazonaws.com/1571847416209.png",
          text:"Eating"
        },
        {
          image:"https://naya-studio-uploads.s3.amazonaws.com/1571847421488.png",
          text:"Drinking"
        },
        {
          image:"https://storage.googleapis.com/naya-studio-uploads/google-onboarding-icons/storage.png",
          text:"Storage"
        },
        {
          image:"https://naya-studio-uploads.s3.amazonaws.com/1571847436709.png",
          text:"Reading"
        },
        {
          image:"https://naya-studio-uploads.s3.amazonaws.com/1571847449834.png",
          text:"Heavy Objects"
        },
        {
          image:"https://naya-studio-uploads.s3.amazonaws.com/1574012550277.png",
          text:"Display Items"
        },
        {
          image:"https://storage.googleapis.com/naya-studio-uploads/google-onboarding-icons/modeling.png",
          text:"Modeling/Physical Creation"
        },
        {
          image:"https://storage.googleapis.com/naya-studio-uploads/artifacts/pinup_whiteboard.png",
          text:"Whiteboarding/Sketching"
        }
      ]
    },
    {
      id:"question7",
      type:"QUESTION",
      subType:'MULTIPLE_SECTION',
      title:"Of the features below, what is critical for you and what is a nice to have?",
      subtitle:"",
      multiple_select:true,
      sections:[
        {
          name:"Critical Features",
          options:[
            {
              image:"https://storage.googleapis.com/naya-studio-uploads/google-onboarding-icons/cord_management.png",
              text:"Integrated Cord Management"
            },
            {
              image:"https://storage.googleapis.com/naya-studio-uploads/google-onboarding-icons/height_adjust.png",
              text:"Desk Height Adjustment"
            },
            {
              image:"https://storage.googleapis.com/naya-studio-uploads/google-onboarding-icons/monitors.png",
              text:"Monitor Adjustability"
            },
            {
              image:"https://storage.googleapis.com/naya-studio-uploads/google-onboarding-icons/laptop_stand.png",
              text:"Laptop Stand"
            },
            {
              image:"https://storage.googleapis.com/naya-studio-uploads/google-onboarding-icons/whiteboard.png",
              text:"Vertical Work Surface (Eg. Pinup/Whiteboard)"
            },
            {
              image:"https://storage.googleapis.com/naya-studio-uploads/google-onboarding-icons/privacy.png",
              text:"Visual Barrier"
            },
            {
              image:"https://storage.googleapis.com/naya-studio-uploads/google-onboarding-icons/other.png",
              text:"Other",
              type:'OTHER_OPTION'
            },
          ]
        },
        {
          name:"Nice to Have",
          options:[
            {
              image:"https://storage.googleapis.com/naya-studio-uploads/google-onboarding-icons/cord_management.png",
              text:"Integrated Cord Management"
            },
            {
              image:"https://storage.googleapis.com/naya-studio-uploads/google-onboarding-icons/height_adjust.png",
              text:"Desk Height Adjustment"
            },
            {
              image:"https://storage.googleapis.com/naya-studio-uploads/google-onboarding-icons/monitors.png",
              text:"Monitor Adjustability"
            },
            {
              image:"https://storage.googleapis.com/naya-studio-uploads/google-onboarding-icons/laptop_stand.png",
              text:"Laptop Stand"
            },
            {
              image:"https://storage.googleapis.com/naya-studio-uploads/google-onboarding-icons/whiteboard.png",
              text:"Vertical Work Surface (Eg. Pinup/Whiteboard)"
            },
            {
              image:"https://storage.googleapis.com/naya-studio-uploads/google-onboarding-icons/privacy.png",
              text:"Visual Barrier"
            },
            {
              image:"https://storage.googleapis.com/naya-studio-uploads/google-onboarding-icons/other.png",
              text:"Others",
              type:'OTHER_OPTION'
            },
          ]
        },
      ]
    },
    {
      id:"question8",
      type:"QUESTION",
      subType:'MCQ',
      title:"Which desk typology would best fit your needs/wants in your home workplace?",
      subtitle:"Your choices help us make the right decisions in design and fabrication",
      multiple_select:true,
      options:[
        {
          image:"https://storage.googleapis.com/naya-studio-uploads/google-onboarding-icons/fixed_height_desk.png",
          text:"Dedicated Fixed-Height Desk"
        },
        {
          image:"https://storage.googleapis.com/naya-studio-uploads/google-onboarding-icons/height_adjust.png",
          text:"Dedicated Sit/Stand Desk"
        },
        {
          image:"https://storage.googleapis.com/naya-studio-uploads/google-onboarding-icons/convertible_desk_v2.png",
          text:"Convertible/Collapsible Desk"
        },
        {
          image:"https://storage.googleapis.com/naya-studio-uploads/google-onboarding-icons/sit_stand_surface.png",
          text:"Desktop Sit/Stand Surface"
        },
        {
          image:"https://storage.googleapis.com/naya-studio-uploads/google-onboarding-icons/minimal_desk_surface_v2.png",
          text:"Minimal Desk Surface"
        },
        {
          image:"https://storage.googleapis.com/naya-studio-uploads/google-onboarding-icons/mobile_desk.png",
          text:"Mobile Desk"
        },
        {
          image:"https://storage.googleapis.com/naya-studio-uploads/google-onboarding-icons/other.png",
          text:"Others",
          type:"OTHER_OPTION"
        }
      ]
    },
    {
      id:"question9",
      type:"QUESTION",
      subType:'TEXT',
      title:"What did you love about collaboration at the office, that you have trouble doing from your desk at home?",
      placeholder:"Eg participate in a virtual workshop, pair programming and how so?",
    },
    {
      id:"search_upload",
      type:"UPLOAD_PINTEREST",
      title:"Do you have any inspirations for office furniture?",
      subtitle:"You can also link your pinterest board or upload these later"
    },
//     {
//       id:"workspace",
//       type:"UPLOAD",
//       title:"Upload an image of your current workspace",
//       subtitle:"You can also upload this later"
//     },
    {
      id:"sketch",
      type:"SKETCH",
    },
    {
      id:"story",
      type:"STORY",
      title:"Please add any additional thoughts or ideas",
      subtitle:""
    }
  ]
}

export default ONBOARDING_FLOW;
