import React, { Component } from 'react'
import {Col} from 'react-bootstrap'
import { withRouter } from 'react-router-dom'

class BackButton extends Component{

    goBack = (e) => 
    {
        this.props.onClick()
    }
    render()
    {
        return (
                <Col xs={3} lg={3}>
                    <p className='app-nav' onClick={this.goBack} style={{fontWeight:700}}>Back</p>
                </Col>
        )
    }
}

export default withRouter(BackButton)