import React from 'react'
import Modal from 'react-bootstrap/Modal'
import {Container, Row} from 'react-bootstrap'
import { connect } from 'react-redux';   

import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import {saveAddress} from '../../actions/canvasAddOnsActions';

class Shipping extends React.Component
{
    constructor(props)
    {
        super(props)
        console.log(this.props)
        let recepient_name = ""
        let street_name = ""
        let apt_num = ""
        let city = ""
        let state_code = ""
        let zip = ""
        let carrier = ""
        let tracking_number = ""

        if(this.props.address)
        {
            if("recepient_name" in this.props.address)
            {
                recepient_name = this.props.address["recepient_name"]
            }

            if("street_name" in this.props.address)
            {
                street_name = this.props.address["street_name"]
            }

            if("apt_num" in this.props.address)
            {
                apt_num = this.props.address["apt_num"]
            }

            if("city" in this.props.address)
            {
                city = this.props.address["city"]
            }

            if("state_code" in this.props.address)
            {
                state_code = this.props.address["state_code"]
            }

            if("zip" in this.props.address)
            {
                zip = this.props.address["zip"]
            }

        }
        if(this.props.tracking){
            if( "carrier" in this.props.tracking) {
                carrier = this.props.tracking["carrier"]
            }
    
            if("tracking_number" in this.props.tracking){
                tracking_number = this.props.tracking["tracking_number"]
            }
        }
        
        this.state = {
            recepient_name: recepient_name,
            street_name: street_name,
            apt_num: apt_num,
            city: city,
            state_code: state_code,
            zip: zip,
            carrier: carrier,
            tracking_number: tracking_number
        }
    }

    handleSubmit = (e) => {
        e.preventDefault()
        console.log(this.state)
        const address = { recepient_name: this.state.recepient_name,
            street_name: this.state.street_name,
            apt_num: this.state.apt_num,
            city: this.state.city,
            state_code: this.state.state_code,
            zip: this.state.zip
        }
        const tracking = {
            carrier: this.state.carrier,
            tracking_number: this.state.tracking_number
        }
        this.props.saveAddress({"id": this.props.id, "address": address, "tracking": tracking})
        this.props.onContinue()
    }

    handleChange = (e) => {
        let {name, value} = e.target
        this.setState({[name]: value})
    }

    render()
    {
        return (
            <Container fluid>
                <Row className={"justify-content-center"}>
                    <h1>Add/Edit Address</h1>
                </Row>
                <Row className={"justify-content-center"}>
                    <Form onSubmit={this.handleSubmit}>
                        <Form.Group>
                            <Form.Label>Recepient Name</Form.Label>
                            <Form.Control type="text" placeholder="Jane Doe" name="recepient_name" value={this.state.recepient_name} onChange={this.handleChange}/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Street Name</Form.Label>
                            <Form.Control type="text" placeholder="Baker Street" name="street_name" value={this.state.street_name} onChange={this.handleChange}/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Bldg/Apt Number</Form.Label>
                            <Form.Control type="text" placeholder="221" name="apt_num" value={this.state.apt_num} onChange={this.handleChange}/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>City</Form.Label>
                            <Form.Control type="text" placeholder="New York" name="city" value={this.state.city} onChange={this.handleChange}/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>State</Form.Label>
                            <Form.Control type="text" placeholder="NY" name="state_code" value={this.state.state_code} onChange={this.handleChange}/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Zip Code</Form.Label>
                            <Form.Control type="text"  placeholder="00000" name="zip" value={this.state.zip} onChange={this.handleChange}/>
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>Carrier</Form.Label>
                            <Form.Control as="select" name="carrier" defaultValue="Choose ..." value={this.state.carrier} onChange={this.handleChange} >
                                <option>Choose ...</option>
                                <option>USPS</option>
                                <option>UPS</option>
                                <option>Fedex</option>
                                <option>DHL</option>
                            </Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Tracking Number</Form.Label>
                            <Form.Control type="text" placeholder="12345678901234567890" name="tracking_number" value={this.state.tracking_number} onChange={this.handleChange} />
                        </Form.Group>
                        <Button variant="dark" type="submit">
                            Submit
                        </Button>

                    </Form>
                </Row>
            </Container>
        )
    }
}

/**
 * 
 * @param {*} state
 * Maps Redux state to component props 
 */
const mapStateToProps = (state) => {
    let address = state.canvas.address;
    let tracking = state.canvas.tracking;
  
    return {
        id: state.canvas.id,
        address: address,
        tracking: tracking
  
    }
  }
  /**
  * Maps Redux actions to component props
  * @param {*} dispatch
  *  
  */
  const mapDispatchToProps = (dispatch) => {
  
    return {
      saveAddress: (data) => dispatch(saveAddress(data)),
    }
  }

  export default connect(mapStateToProps, mapDispatchToProps)(Shipping)