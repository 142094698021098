let API_ENDPOINT;
let GOOGLE_SEARCH_ENDPOINT;
// let INSTA_SEARCH_ENDPOINT;
let FRONTEND_ENDPOINT;
let PROJECT_BACKEND_ENDPOINT;
let UPLOAD_ENDPOINT;
let SHARING_API;
let SHARING_PORTAL_FRONTEND;
if (process.env.REACT_APP_STAGING) {
    FRONTEND_ENDPOINT = "http://canary.naya.studio";
    API_ENDPOINT = "https://naya-app-backend-staging-ziylmcndpq-ue.a.run.app";
    GOOGLE_SEARCH_ENDPOINT =
        "https://www.googleapis.com/customsearch/v1?cx=017582732796115664878%3Amjnrwzjj1j4&imgColorType=color&searchType=image&key=AIzaSyAM6brFB5bwrhn6DRw-Wj_IendGbvNbVY8&q=";
    UPLOAD_ENDPOINT =
        "https://naya-uploads-staging-ziylmcndpq-ue.a.run.app/upload";
    
    SHARING_API = "https://naya-sharing-backend-ziylmcndpq-ue.a.run.app";
    SHARING_PORTAL_FRONTEND = "https://naya-sharing-portal-ziylmcndpq-ue.a.run.app"
} else if (process.env.NODE_ENV === "production") {
    FRONTEND_ENDPOINT = "//app.naya.studio";
    API_ENDPOINT = "//api.naya.studio";
    GOOGLE_SEARCH_ENDPOINT =
        "https://www.googleapis.com/customsearch/v1?cx=017582732796115664878%3Amjnrwzjj1j4&imgColorType=color&searchType=image&key=AIzaSyAM6brFB5bwrhn6DRw-Wj_IendGbvNbVY8&q=";
    UPLOAD_ENDPOINT =
        "https://naya-uploads-production-ziylmcndpq-ue.a.run.app/upload";
    
    SHARING_API = "https://naya-sharing-backend-ziylmcndpq-ue.a.run.app";
    SHARING_PORTAL_FRONTEND = "https://sharing.naya.studio"
} else {
    FRONTEND_ENDPOINT = "http://localhost:3000";
    API_ENDPOINT = "http://localhost:3005";
    SHARING_API="http://localhost:3010";
    SHARING_PORTAL_FRONTEND="http://localhost:3002"
    UPLOAD_ENDPOINT =
        "https://naya-uploads-development-ziylmcndpq-ue.a.run.app/upload";
    PROJECT_BACKEND_ENDPOINT =
        "https://naya-project-backend-development-ziylmcndpq-ue.a.run.app";
    GOOGLE_SEARCH_ENDPOINT =
        "https://www.googleapis.com/customsearch/v1?cx=017582732796115664878%3Amjnrwzjj1j4&imgColorType=color&searchType=image&key=AIzaSyAM6brFB5bwrhn6DRw-Wj_IendGbvNbVY8&q=";
}

export {
    API_ENDPOINT,
    GOOGLE_SEARCH_ENDPOINT,
    FRONTEND_ENDPOINT,
    UPLOAD_ENDPOINT,
    PROJECT_BACKEND_ENDPOINT,SHARING_API,SHARING_PORTAL_FRONTEND
};
