import React, { Component } from 'react'
// import '../../stylesheets/visual-moodboard.css'
import cancel from '../../images/icons/selected.svg'

class Option extends Component{
  componentDidMount(){
  }

  handleDeselect = (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.props.onRemove(this.props.photo.src)
  }

  render()
  {
    let cont = {
      backgroundColor: "#eee",
      cursor: "pointer",
      overflow: "hidden",
      position: "relative"
    };
    cont.position = "absolute";
    cont.left = this.props.left;
    cont.top = this.props.top;
    let style = { margin: this.props.margin, height: this.props.photo.height, width: this.props.photo.width, ...cont }
    return (
      <div className='search-upload-option option' onClick={this.handleSelect} style={style}>
        <img src={this.props.photo.src} alt="upload" style={{width:'inherit'}}></img>
        <img alt="select-icon" onClick={this.handleDeselect} src={cancel} style={{"height": "20px", "position":"absolute", "top":"0.5rem", "right": "0.5rem", "left":"unset", "bottom":"unset"}}></img>
      </div>
    )
  }
}

export default Option