import React, { Component } from 'react'
import {Col} from 'react-bootstrap'

class Progress extends Component{

    render()
    {
        return (
                <Col xs={6} lg={6}>
                    <Col xs={12}>
                    <p className='app-nav' style={{fontWeight:700}}>Steps {this.props.step} out of {this.props.step_max}</p>
                    </Col>
                </Col>
        )
    }
}

export default Progress