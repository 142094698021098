import React from 'react'
import Modal from 'react-bootstrap/Modal'
import {Container, Row} from 'react-bootstrap'
import { connect } from 'react-redux';   

import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import {saveDimensions} from '../../actions/canvasAddOnsActions';

class Dimensions extends React.Component
{
    constructor(props)
    {
        super(props)

        let length = 0
        let width = 0
        let height = 0
        let units = "inches"
        if(this.props.dimensions)
        {
            if("length" in this.props.dimensions)
            {
                length = this.props.dimensions["length"]
            }
            if("width" in this.props.dimensions)
            {
                width = this.props.dimensions["width"]
            }
            if("height" in this.props.dimensions)
            {
                height = this.props.dimensions["height"]
            }
            if("units" in this.props.dimensions)
            {
                units = this.props.dimensions["units"]
            }
        }

        this.state = {
            length: length,
            height: height,
            width: width,
            units: units
        }
    }

    handleSubmit = (e) => {
        e.preventDefault()
        console.log(this.state)
        this.props.saveDimensions({"id": this.props.id, "dimensions_new": this.state})
        // this.saveDimensions(this.state)
        this.props.onContinue()

    }

    handleChange = (e) => {
        let {name, value} = e.target
        this.setState({[name]: value})
    }

    render()
    {
        return (
            <Container fluid>
                <Row className={"justify-content-center"}>
                    <h1>Add/Edit Dimensions</h1>
                </Row>
                <Row className={"justify-content-center"}>
                    <Form onSubmit={this.handleSubmit}>
                    <Form.Group>
                            <Form.Label>Units</Form.Label>
                            <Form.Control type="text" name="units" value={this.state.units} onChange={this.handleChange}/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Length</Form.Label>
                            <Form.Control type="number" name="length" value={this.state.length} onChange={this.handleChange}/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Width</Form.Label>
                            <Form.Control type="number" name="width" value={this.state.width} onChange={this.handleChange}/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Height</Form.Label>
                            <Form.Control type="number" name="height" value={this.state.height} onChange={this.handleChange}/>
                        </Form.Group>
                        
                        <Button variant="dark" type="submit">
                            Submit
                        </Button>

                    </Form>
                </Row>
            </Container>
        )
    }
}


/**
 * 
 * @param {*} state
 * Maps Redux state to component props 
 */
const mapStateToProps = (state) => {
    let dimensions = state.canvas.dimensions_new;
  
    return {
        id: state.canvas.id,
        dimensions: dimensions
  
    }
  }
  /**
  * Maps Redux actions to component props
  * @param {*} dispatch
  *  
  */
  const mapDispatchToProps = (dispatch) => {
  
    return {
      saveDimensions: (data) => dispatch(saveDimensions(data)),
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(Dimensions)