import React, { Component } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Button, Dropdown, Form } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { saveSketch, editSketch } from "../../actions/sketchActions";
import CanvasDraw from "react-canvas-draw";
import "../../stylesheets/sketch.css";
import axios from "axios";
import { API_ENDPOINT } from "../../constants/secrets";
import { CirclePicker } from "react-color";

axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
        href="#"
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
    </a>
));

class SketchPage extends Component {
  constructor(props) {
    super(props);
    this.fileInput = React.createRef();
    this.state = {
      showTooltip: false,
      brushRadius: 5,
      brushColor: "#000",
      height: 500,
      width: "100%",
      imgBg: this.props.backgroundImage,
    };
    this.sketchWrapper = React.createRef();
  }

  handleUpload = (e) => {
    e.preventDefault();
    var file = this.fileInput.current.files[0];
    const formData = new FormData();
    formData.append("file", file, file.name);
    const UPLOAD_FILE_DATA_URI = `${API_ENDPOINT}/api/upload`;

    axios
      .post(UPLOAD_FILE_DATA_URI, formData, { crossDomain: true })
      .then((response) => {
          // console.log(this.getAspectRatio(response.data.link))
          this.getMeta(response.data.link, this.addImage);
          // this.setState({gallery: [...this.state.gallery, {src: response.data.link, height: 1, width: 1}]})
      })
      .catch((e) => {
          console.log(e);
      });

    // this.props.uploadFile(formData)
  };

  getMeta = (url, callBack) => {
    var img = new Image();

    img.onload = function () {
        callBack(url, this.width, this.height);
    };
    img.src = url;
  };

  addImage = (url, width, height) => {
    let newHeight = height;
    newHeight =
        (height * this.saveableCanvas.canvasContainer.clientWidth) / width;
    // this.saveableCanvas.setCanvasSize(this.saveableCanvas.canvas, width, newHeight)
    this.setState({ height: newHeight, imgBg: url });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    // this.loadableCanvas.loadSaveData(this.saveableCanvas.getSaveData())
    const data = {
        sketchData: this.saveableCanvas.getSaveData(),
        sketchBg: this.state.imgBg,
        id: this.props.mode==='CREATE' ? this.props.canvas_id : this.props.sketchId,
    };
    if(this.props.mode==='CREATE'){
        this.props.saveSketch(data);
    }
    else{
        this.props.editSketch(data)
    }
    
    if(this.props.question){
      this.props.onContinue()
    }
    else{
      this.props.history.push("/canvas");
    }
  };

  renderBrushRadii = (n) => {
    let items = [];
    for (var i = 1; i < n; i++) {
      items.push(
        <Dropdown.Item
          key={i}
          name={i}
          onClick={(e) => {
              this.setState({ brushRadius: e.target.name });
          }}
        >
          {i}px
        </Dropdown.Item>
      );
    }
    return items;
  };

  changeBrushSize = (e) => {
    e.preventDefault();
    this.setState({ brushWidth: parseInt(e.target.value) });
  };

  handleColor = (color, e) => {
    this.setState({ brushColor: color.hex });
  };

  handleChange = (e) => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    let colors = ["#eeeeee","#000000","#f44336","#e91e63","#9c27b0","#673ab7","#3f51b5","#2196f3","#03a9f4","#00bcd4","#009688","#4caf50","#8bc34a","#cddc39","#ffeb3b","#ffc107","#ff9800","#ff5722","#795548","#607d8b",];

    let brushWidth = this.state.brushWidth;
    let style = {backgroundColor: "black",height: brushWidth,width: "100%",};
    return (
      <Container fluid={true} id="sketch">

        <Container>
          <Row>
            <h2 className="col-12">Give us a napkin sketch of your idea</h2>
            {this.state.showTooltip && (<h4>Labore duis aliqua eiusmod fugiat nulla est id quis velit cillum est id adipisicing sit.</h4>)}
            <Col xs={12} lg={{ span: 6, offset: 3 }}>
              <Row>
                <Col xs={12} id="annotate-tools">
                <Row style={{marginBottom:'1rem'}}>
                  <Col xs={12} lg={3}>
                    <Row>
                      <Dropdown className={"col-4"}>
                        <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                          <div className="color-dropdown-toggle" style={{width: "2rem",height: "2rem", borderRadius:"2rem",backgroundColor: this.state.brushColor,}}></div>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item eventKey="1">
                              <CirclePicker colors={colors} onChange={this.handleColor}/>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                      <Dropdown className={"col-6"}>
                        <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                          <Form.Control value={this.state.brushRadius} onChange={this.handleChange} name="brushRadius"></Form.Control>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>{this.renderBrushRadii(20)}</Dropdown.Menu>
                      </Dropdown>
                    </Row>
                  </Col>
                  <Col xs={3} lg={2} className="tools-links">
                    <a href="#" onClick={() => { this.saveableCanvas.undo(); }} style={{fontWeight:700}} >Undo</a>
                  </Col>
                  <Col xs={3} lg={2} className="tools-links">
                    <a href="#" onClick={(e) => {e.preventDefault();this.saveableCanvas.clear();}} style={{fontWeight:700}}>Clear</a>
                  </Col>
                  <Col xs={3} lg={2} className="tools-links">
                    <a href="#" onClick={(e) => {e.preventDefault();this.setState({brushColor: "#ffffff",});}} style={{fontWeight:700}}>Eraser</a>
                  </Col>
                  <Col xs={3} lg={2} className="tools-links">
                    <label htmlFor="fileinput" style={{fontWeight:700}}>
                      Upload
                      <input style={{display:'none'}} id="fileinput" ref={this.fileInput} onChange={this.handleUpload} className="file-input" type="file"></input>
                    </label>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col xs={12} lg={{ span: 8, offset: 2 }} className="canvas-wrapper" ref={this.sketchWrapper}>
            <CanvasDraw
              ref={(canvasDraw) => {
                  this.saveableCanvas = canvasDraw;
              }}
              saveData={this.props.sketchData}
              canvasWidth={this.state.width}
              canvasHeight={this.state.height}
              lazyRadius={0}
              brushRadius={this.state.brushRadius}
              brushColor={this.state.brushColor}
              imgSrc={this.state.imgBg}
            ></CanvasDraw>
          </Col>
          <Col xs={12}>
            <Row className="justify-content-center">
              <Button variant="dark" onClick={this.handleSubmit} style={{backgroundColor:'#000', padding:'0.5rem 3rem', marginTop:'2rem', marginBottom:'2rem'}}>CONTINUE</Button>
            </Row>
          </Col>
        </Row>
        </Container>
      </Container>
    );
  }

  componentDidMount() {
      if (this.sketchWrapper) {
          let width = this.sketchWrapper.current.clientWidth;
          let height = this.sketchWrapper.current.clientHeight;
          this.setState({ height: height, width: width });
      }
      if (this.saveableCanvas) {
          this.saveableCanvas.drawImage();
      }
  }
  componentDidUpdate() {
      if (this.sketchWrapper) {
      }
      if (this.saveableCanvas) {
          this.saveableCanvas.drawImage();
      }
  }
}

const mapStateToProps = (state) => {
    let sketchData = "";
    let mode='CREATE';
    let sketchId="";
    let backgroundImage=undefined;
    if (
        state.canvas.sketch !== undefined && state.canvas.sketch !== null &&
        state.canvas.sketch.sketchData !== undefined
    ) {
        backgroundImage = state.canvas.sketch.sketchBg;
        sketchData = state.canvas.sketch.sketchData;
        sketchId=state.canvas.sketch._id;
        mode="EDIT"
    }
    return {
        canvas_id: state.canvas.id,
        user: state.user,
        sketchData: sketchData,
        mode:mode,
        sketchId:sketchId,
        backgroundImage:backgroundImage,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        saveSketch: (data) => dispatch(saveSketch(data)),
        editSketch: (data) => dispatch(editSketch(data)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(SketchPage));
