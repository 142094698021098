import React, { Component } from 'react';
import { connect } from 'react-redux';   
import {Container, Row, Col, Button, Form} from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import '../../stylesheets/story.css';

import chance from 'chance';

import {saveStory} from '../../actions/storyActions';


class StoryPage extends Component{
  state = {
    name: this.props.canvas.name ? this.props.canvas.name: "",
    additional_info:this.props.canvas.additional_info ? this.props.canvas.additional_info : "",
    id:this.props.canvas.id,
    loading:false
  }

  handleSubmit = (e) =>{
    // data to be passed: name, additional_info, id
    this.props.saveStory(this.state)
    this.props.onContinue();
    this.setState({loading:true})
  }

  handleChange = (e) =>{
    e.preventDefault()
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleTextChange = (e) => {
    this.setState({ story: e });
  };

  render(){
    return (
      <Container fluid={true}>
        <Container id="story">
          <Row>
            <h2 className="col-12 question-h2" style={{"textAlign":"center"}}>Please add any information you think is relevant</h2>
            {/* for subtitle */}
            {/* <h4 className="col-12 question-h4" style={{"textAlign":"center"}}></h4> */}
            <Col xs={12} lg={{span:6, offset:3}}>
              <Row>
                <Col xs={12}>
                  <p className="title">Your Name</p>
                  <Form.Control type="text" name="name" value={this.state.name} onChange={this.handleChange} placeholder="Enter your name here" style={{fontFamily:'sofia-pro'}}></Form.Control>
                </Col>
                <Col xs={12} style={{marginTop:20}}>
                  <p className="title">Additional information</p>
                  <Form.Control as="textarea"
                    name="additional_info" 
                    value={this.state.additional_info} 
                    style={{
                        height:200,
                        border:'1px solid #cccccc',
                        marginTop:2
                    }}
                    onChange={this.handleChange}></Form.Control>
                </Col>
              </Row>
            </Col>
            <Col xs={12}>
              <Row className='justify-content-center'>
                <Button variant="dark" disabled={this.state.name.trim().length!==0 ? false : true} onClick={this.handleSubmit} style={{marginTop:'40px'}}>Continue</Button>
              </Row>
            </Col>   
          </Row>
        </Container>
      </Container>  
    )
  }
}

const mapStateToProps = (state) => {

  return { 
    canvas:state.canvas
  }
}

const mapDispatchToProps = (dispatch) => {    
  return { 
    saveStory: (data) => dispatch(saveStory(data)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(StoryPage))
