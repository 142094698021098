import React from 'react';
import { connect } from "react-redux";
import { Container} from 'react-bootstrap';
import { withRouter } from "react-router-dom";

import { css } from "@emotion/core";
import SyncLoader from "react-spinners/SyncLoader";
// CSS
import '../../stylesheets/question.css';

// ACTIONS
import { createCanvas } from "../../actions/canvasActions";

const spinnerStyle = css`
  margin-left: 50%;
  transform: translate(-50%, 0);
`;

class Question extends React.Component{
  state = {
    canvas_id:'',
  }

  static getDerivedStateFromProps(nextProps, prevState){
    if(nextProps.canvas.id!==prevState.canvas_id){
        return { canvas_id: nextProps.canvas.id};
   }
   return null;
 }

componentDidUpdate(prevProps, prevState) {
    if(prevState.canvas_id!==this.props.canvas.id){
      this.props.history.push('/onboarding')
    }
  }

  componentDidMount(){
      this.props.createCanvas();
  }

  render(){
    return(
      <Container fluid={true} id="question">
          <div style={{marginTop:'12rem'}}>
          <h1 style={{fontFamily:'bigmoore', textAlign:'center'}}>Loading data</h1>
          <SyncLoader css={spinnerStyle}  color={"#000000"}  sizeUnit={"em"} size={"1"} style={{display:'flex', justifyContent:'center', height:50 }}></SyncLoader>
          </div>
      </Container>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
      user: state.user,
      canvas: state.canvas,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
      createCanvas: () => dispatch(createCanvas()),

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Question));