import React, { Component } from 'react'
import {Col, Modal, Button} from 'react-bootstrap'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'  
import {deleteCanvas} from '../../actions/canvasActions';
class CancelButton extends Component{
    state = {
        showPrompt : false,
    }

    handleExit = () => {
        let data = {
            id:this.props.canvas_id
        }
        this.props.deleteCanvas(data);
        this.props.history.push('/')
    }
    
    render()
    {
        return (
                <Col xs={3} id="exit">
                    <Col xs={12} className="cancel">
                        <p className='app-nav' style={{fontWeight:700}} onClick={()=>{
                            // this.props.history.push('/account')
                            this.setState({showPrompt:true})
                            }}>Exit</p>
                    </Col>
                    <Modal show={this.state.showPrompt} onHide={()=>{this.setState({showPrompt:false})}} centered>
                        <Modal.Header closeButton style={{border:'none'}}></Modal.Header>
                        <Modal.Body style={{fontFamily:'sofia-pro', fontWeight:700}}>
                            Are you sure you want to exit? You'll end up losing your progress so far.
                        </Modal.Body>
                        <Modal.Footer style={{border:'none'}}>
                        <Button style={{color:'red', backgroundColor:'#FFFFFF', fontFamily:'sofia-pro', fontWeight:700, border:'none', flex:1}} onClick={this.handleExit}>
                            Yes
                        </Button>
                        <Button style={{color:'#999999', backgroundColor:'#FFFFFF', fontFamily:'sofia-pro', fontWeight:700, border:'none', flex:1}} onClick={()=>{this.setState({showPrompt:false})}}>
                            No
                        </Button>
                        </Modal.Footer>
                    </Modal>
                </Col>
        )
    }
}
const mapStateToProps = (state) => {
  
    return { 
        canvas_id                   : state.canvas.id,
        // user                        : state.user,
    }
  }
  
  const mapDispatchToProps = (dispatch) => {
    
    return { 
        // setLead: (data) => dispatch(setLead(data)),
        // updateLead: (data) => dispatch(updateLead(data)),
        deleteCanvas:(data)=>dispatch(deleteCanvas(data))
    }
  }
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CancelButton))