import React, { Component } from 'react'
import {Image, Navbar, NavDropdown} from 'react-bootstrap'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'   
import {getNotifications, readNotification} from '../../actions/notificationsActions';
import userIcon from '../../images/icons/userIcon.png'
import notificationIcon from '../../images/icons/notification.svg'
import Prompt from './../prompts/Prompt';
import '../../stylesheets/navbar.css';;

class NavBar extends Component{

    state = {
        showNotification: false,
        currentNotification: undefined,
        userId: null,
        notifications:[],
        unread:0,
    }

    componentDidMount(){
        this.props.getNotifications({user_id:this.props.user.id})
        // fetching the notifications after every 10 seconds
        // setInterval(() => this.props.getNotifications({user_id:this.props.user.id}), 10000)
        
    }

    static getDerivedStateFromProps(nextProps, prevState){
        if(nextProps.user.id!==prevState.userId){
            return { userId: nextProps.user.id};
       }
       if(nextProps.notifications.notifications!==prevState.notifications){
           return {notifications:nextProps.notifications.notifications.reverse()}
       }
       return null;
     }

    componentDidUpdate(prevProps, prevState) {
        if(prevState.userId!==this.props.user.id){
            this.props.getNotifications({user_id:this.props.user.id})
        }
        if(prevState.notifications.length!==this.props.notifications.notifications.length){
            this.getUnreadNotificationsCount()
           this.priorityNotification()
        }
    }

    getUnreadNotificationsCount = () => {
        let count=this.state.unread;
        this.state.notifications.map((notif)=>{
            if(notif.readStatus!==true && notif.priority<100){
                count=count+1;
            }
        })
        this.setState({unread:count})
    }

    // function to check the priority of the notifications 
    // if the notification is equal to or above 100, it is displayed immediately on the screen
    priorityNotification = () => {
        let notifs = [];
        this.state.notifications.map((notif)=>{
            if(notif.priority>=100 && !notif.readStatus){
                notifs.push(notif)
            }
        })
        notifs=notifs.slice().sort((a, b) => b.priority - a.priority);
        if(notifs.length!==0){
            this.props.readNotification(notifs[0]._id,this.state.userId);
            this.passDataToPrompt(notifs[0]);
        }
        
    }
    
    // function to calculate the time difference for the notifications
    timeDifference = (current, previous) => {

        var msPerMinute = 60 * 1000;
        var msPerHour = msPerMinute * 60;
        var msPerDay = msPerHour * 24;
        var msPerMonth = msPerDay * 30;
        var msPerYear = msPerDay * 365;
    
        var elapsed = current - previous;
    
        if (elapsed < msPerMinute) {
             return Math.round(elapsed/1000) + ' seconds ago';   
        }
    
        else if (elapsed < msPerHour) {
             return Math.round(elapsed/msPerMinute) + ' mins ago';   
        }
    
        else if (elapsed < msPerDay ) {
             return Math.round(elapsed/msPerHour ) + ' h ago';   
        }
    
        else if (elapsed < msPerMonth) {
            return Math.round(elapsed/msPerDay) + ' days ago';   
        }
    
        else if (elapsed < msPerYear) {
            return  Math.round(elapsed/msPerMonth) + ' months ago';   
        }
    
        else {
            return Math.round(elapsed/msPerYear ) + ' y ago';   
        }
    }

    // Checking if the device is a mobile phone or not
    isMobile = () => {
        if(window.orientation > -1){
            return true 
        }
        else{
            return false
        }
    }

    toggleNotification = () => {
        this.setState({showNotification:!this.state.showNotification})
    }

    // passing data to the notification prompt
    passDataToPrompt = (data) => {
        this.setState({currentNotification:data},this.toggleNotification())
    }


    handleSignOut = (e) =>
    {
        localStorage.setItem('nayaJwtToken', '')
        this.props.signOut()
        this.props.history.push('/')
    }

    handleAccount = (e) =>
    {
        this.props.history.push('/account')
    }

  renderUserProfile = () => {
    if(this.props.user && this.props.user.id){
      return (
        <Navbar.Text>
          <span>
          {
            !this.isMobile() && (
              <>
              {
                this.props.user.profile_pic ? (
                    <Image src={this.props.user.profile_pic} style={{"height":"3rem", "borderRadius":"5rem", "overflow":"hidden"}} fluid />
                ) : (
                    <Image src={userIcon} style={{"height":"3rem"}} fluid />
                )
                  
              }
              </>
            )
          }
          </span>
          <NavDropdown title={window.orientation > -1 ? <Image src={userIcon} style={{"height":"3rem"}} fluid /> : this.props.user.name ? this.props.user.name:this.props.user.email.split("@")[0]} id="collasible-nav-dropdown" style={{"display":"inline-block"}}>
            <NavDropdown.Item href="" onClick={this.handleAccount}>My Account</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item onClick={this.handleSignOut}>Sign Out</NavDropdown.Item>
          </NavDropdown>
          {/* Signed in as: <a href="/account">{this.props.user.email.split("@")[0]}</a> */}
        </Navbar.Text>
      )
    }
    else{
      return <Navbar.Text>
        <a href="/login" alt="" onClick={(e) => {e.preventDefault(); this.props.history.push("/login")}}>Sign Up/Log in</a>
      </Navbar.Text>
    }
  }

  renderNotifications = () => {
    if(this.props.user && this.props.user.id){
      if(this.state.notifications!==undefined || this.state.notifications.length!==0){
        return (
          <Navbar.Text>
            <NavDropdown title={
              <>
              <div style={{position:'absolute', left:37, top:12, "backgroundColor":this.state.unread!==0 && "red", "borderRadius":"5px", "padding":"0.2rem", "width":"10px", "height":"10px","paddingTop":"0.1rem","textAlign":"center", "color":"white", "fontSize":"0.7rem", "fontWeight":"400"}}>
                {/* {this.state.unread!==0 && this.state.unread} */}
                </div>
              <Image src={notificationIcon} style={{height:35, width:35}} fluid />
              </>
              } id="collasible-nav-dropdown" style={{"display":"inline-block", padding:'0px'}}>
              {
                this.state.notifications.length===0 ? <div style={{width:200, paddingTop:20, paddingBottom:20, textAlign:'center', color:'#a3a3a3'}}>No notifications found</div> 
                : 
                this.state.notifications.map((notif, index) => {
                    let date = new Date(notif.date)
                    let now = new Date()
                    let diff = this.timeDifference(now, date)
                    return(
                      <div>
                        <NavDropdown.Item 
                          href="" 
                          style={{ padding:'10px', backgroundColor:notif.readStatus && '#F5F5F5'}} 
                          onClick={()=>{
                            this.passDataToPrompt(notif);
                            this.props.readNotification(notif._id,this.state.userId); 
                            if(notif.readStatus===false && this.state.unread!==0){
                                this.setState({unread:this.state.unread-1})}}
                            }
                        >
                        <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                          <div>
                            <div style={{whiteSpace: 'nowrap', overflow:'hidden', textOverflow: 'ellipsis', width:'200px', fontSize:14}} dangerouslySetInnerHTML={{__html: notif.content.title}}/>
                            <div style={{color:'#a3a3a3', fontSize:12}}>{diff}</div>
                          </div>
                          {
                            !notif.readStatus && <div style={{width:'10px', height:'10px', borderRadius:'5px', backgroundColor:'blue'}}/>
                          }
                        </div>
                      </NavDropdown.Item>
                      {index!==this.state.notifications.length-1 && (<NavDropdown.Divider style={{margin:0}}/>)}
                    </div>
                  )
                })
              }
            </NavDropdown>
          </Navbar.Text>
        )
      }
    }
      
  }

  render(){
    return (
      <>
        <Navbar id="naya-navbar">
          <Navbar.Brand href="https://www.naya.studio/">naya</Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
              {this.renderUserProfile()}
              {this.renderNotifications()}
          </Navbar.Collapse>
        </Navbar>
        {/* {
            this.state.showNotification && (<Prompt data={this.state.currentNotification} toggleNotification={this.toggleNotification}/>)
        } */}
    </>
    )
  }
}

const mapStateToProps = (state) => {
  return { 
    user: state.user,
    notifications: state.notifications
  }
}

const mapDispatchToProps = (dispatch) => {
  
  return { 
    signOut: () => dispatch({type:"SIGN_OUT"}),
    getNotifications: (data) => dispatch(getNotifications(data)),
    readNotification: (notifId,userId) => dispatch(readNotification(notifId,userId)),
  }
}
export default  connect(mapStateToProps, mapDispatchToProps)(withRouter(NavBar))
