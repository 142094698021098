import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import queryString from "query-string";

import {  loginToken } from "../../actions/authActions"

class Loading extends Component {

    checkForUser = () => {
        if (this.props.user && this.props.user.id) {
            localStorage.setItem("nayaAppStateRestore", "false");
            this.props.history.push("loading");
        } else {
            // passport-google redirect
            const values = queryString.parse(this.props.location.search);
            if (values.token) {
                localStorage.setItem("nayaJwtToken", values.token);
                this.props.loginToken();
            }
        }
    };

    componentDidUpdate() {
        this.checkForUser();
    }

    componentDidMount() {
        this.checkForUser();
    }


    render() {

        return (<div>

            Loading...

        </div>)
    }
}

const mapStateToProps = (state) => {
    
    return {
        user: state.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        loginToken: () => dispatch(loginToken()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Loading))