import userIcon from '../images/icons/userIcon.png'
const initUser = {
    id                  : null,
    email               : null,
    user_type           : null,
    user_canvas         : null,
    design_canvas       : null,
    fabrication_canvas  : null,
    name                : null,
    profile_pic          : userIcon,
    profile_name        : ('').split('@')[0],
    loggedIn            : false,
    isVerified          : false
}

let DEBUG = false

const userReducer = (state = initUser, action) => {
    let newState

    if(DEBUG) console.debug("USER REDUCER:")
    if(DEBUG) console.debug("ACTION:")
    if(DEBUG) console.debug(action)

    if(DEBUG) console.debug("CURRENT STATE:")
    if(DEBUG) console.debug(state)
    let user
    switch(action.type)
    {
        case "LOGIN_TOKEN":
                user = action.data
                newState = {
                            ...state, 
                            id                  : user._id,
                            email               : user.email, 
                            user_type           : user.user_type === undefined? "USER": user.user_type, 
                            user_canvas         : user.user_canvas, 
                            design_canvas       : user.design_canvas, 
                            fabrication_canvas  : user.fabrication_canvas,
                            name                : user.name,
                            profile_pic         : user.profile_pic,
                            loggedIn            : true,
                            isVerified          : user.isVerified
                        }
                break
        case 'SIGN_OUT':
            newState = initUser
            break
        default:
            newState = state
    }

    if(DEBUG) console.debug("NEW STATE:")
    if(DEBUG) console.debug(newState)

    return newState
}

export default userReducer