import React from 'react';
import { connect } from "react-redux";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Question from './components/question/Question';
import Canvas from './components/canvas/Canvas';
import NewCanvas from './components/canvas/NewCanvas';

import Sketch from './components/tools/Sketch';
import Login from './components/auth/Login';
import Landing from './components/auth/Landing';
import Loading from './components/auth/Loading';
import { loginToken } from "./actions/authActions";
import Annotate from './components/annotate/Annotate';
import Account from "./components/account/Account";
import Verify from "./components/account/Verify";
import LoadingScreen from './components/loadingScreen/LoadingScreen';

// ACTIONS
import { getCanvas } from "./actions/canvasActions";

function CanvasRouter({ match }) {
  localStorage.setItem("nayaCanvasID", match.params.canvas_id);
  getCanvas(match.params.canvas_id);
  return <Canvas id={match.params.canvas_id}></Canvas>;
}

function LatestCanvasRouter({ match }) {
  localStorage.setItem("nayaCanvasID", match.params.canvas_id);
  getCanvas(match.params.canvas_id);
  return <NewCanvas id={match.params.canvas_id}></NewCanvas>;
}

function NewCanvasRouter({ match }) {
  localStorage.removeItem("nayaCanvasID");
  return <LoadingScreen></LoadingScreen>;
}

class App extends React.Component {
  componentDidMount() {
    this.props.getCanvas(undefined);
    if (localStorage.getItem("nayaJwtToken") !== undefined) {
        this.props.loginToken(localStorage.getItem("nayaJwtToken"));
    }
  }
  render() {
    return (
      <Router>
        <Switch>
          <Route path="/canvas/:canvas_id" component={CanvasRouter} />
          <Route path="/new_canvas/:canvas_id" component={LatestCanvasRouter} />

          <Route path="/canvas">
            <Canvas></Canvas>
          </Route>

          <Route path="/new_canvas">
            <NewCanvas></NewCanvas>
          </Route>
          
          <Route path="/sketch">
            <Sketch></Sketch>
          </Route>
          <Route path="/annotate">
            <Annotate></Annotate>
          </Route>
          <Route path="/onboarding">
            <Question></Question>
          </Route>
          <Route path="/loading"component={NewCanvasRouter} />
          <Route path="/login" component={Login}>
          </Route>
          <Route path="/account">
            <Account></Account>
          </Route>
          <Route path="/verify">
            <Verify></Verify>
          </Route>
          <Route path="/">
            <Landing></Landing>
          </Route>
        </Switch>
      </Router>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
      // user: state.user,
      // canvas: state.canvas,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
      getCanvas: (data) => dispatch(getCanvas(data)),
      // getInitData: () => dispatch(getInitData()),
      loginToken: (data) => dispatch(loginToken(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
