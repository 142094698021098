import React from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {Container, Row, Col, Button, Form} from 'react-bootstrap';
import Option from './Option';
import OtherOption from './OtherOption';
import {addAnswerToCanvas, updateAnswerToCanvas} from '../../actions/canvasActions';

class Quiz extends React.Component{
  state = {
    selected_options:[],
    q_title:"",
    edit:false,
    text:'',
    selected_sections:{},
    otherOptions:[],
    onboarding_questions : this.props.canvas.onboarding_questions,
  }

  checkIfQuestionExists = () => {
    let questions = this.props.canvas.onboarding_questions;
    for(let i=0;i<questions.length;i++){
      if(questions[i].id===this.props.question.id){
        return questions[i];
      }
    }
    return null;
  }

  componentDidMount(){
    let data = this.checkIfQuestionExists();
    if(data!==null){
      if(this.props.question.subType==='MCQ'){
        let normal_options=[];
        let other_options=[];
        for(let i=0;i<data.selected_options.length;i++){
          if(data.selected_options[i].type==='OTHER_OPTION'){
            other_options.push(data.selected_options[i])
          }
          else{
            normal_options.push(data.selected_options[i])
          }
        }
        this.setState({selected_options:normal_options, otherOptions:other_options,  edit:true})
      }
      else if(this.props.question.subType==='TEXT'){
        this.setState({text:data.text, edit:true})
      }
      else if(this.props.question.subType==='MULTIPLE_SECTION'){
        let sections = Object.keys(data.selected_sections);
        let other_options = [];
        let normal_sections = {};
        for(let i=0;i<sections.length;i++){
          for(let j=0;j<data.selected_sections[sections[i]].length;j++){
            if(data.selected_sections[sections[i]][j].type === 'OTHER_OPTION'){
              other_options.push(data.selected_sections[sections[i]][j]);
            }
            else{
              if(normal_sections[sections[i]]===undefined){
                normal_sections[sections[i]] = [];
              }
              normal_sections[sections[i]].push(data.selected_sections[sections[i]][j]);
            }
          }
        }
        this.setState({selected_sections:normal_sections, otherOptions:other_options, edit:true})
      }
    }
  }

  static getDerivedStateFromProps(nextProps, prevState){
    if(nextProps.question.title!==prevState.q_title ){
      return { q_title: nextProps.question.title};
   }
   if(nextProps.canvas.onboarding_questions.length!==prevState.onboarding_questions.length){
     return {onboarding_questions:nextProps.canvas.onboarding_questions}
   }
   else return null;
 }
 
 componentDidUpdate(prevProps, prevState) {
   if(prevProps.question.title!==this.props.question.title || this.props.canvas.onboarding_questions.length!==prevState.onboarding_questions.length){
     let data = this.checkIfQuestionExists();
      if(data!==null){
        if(this.props.question.subType==='MCQ'){
          let normal_options=[];
        let other_options=[];
        for(let i=0;i<data.selected_options.length;i++){
          if(data.selected_options[i].type==='OTHER_OPTION'){
            other_options.push(data.selected_options[i])
          }
          else{
            normal_options.push(data.selected_options[i])
          }
        }

        this.setState({selected_options:normal_options, otherOptions:other_options,  edit:true})
        }
        else if(this.props.question.subType==='TEXT'){
          this.setState({text:data.text, edit:true})
        }
        else if(this.props.question.subType==='MULTIPLE_SECTION'){
          let temp = {};
          let other_options=[];
          let sections = Object.keys(data.selected_sections)
          for(let i=0;i<sections.length;i++){
            temp[sections[i]] = [];
            for(let j=0;j<data.selected_sections[sections[i]].length;j++){
              if(data.selected_sections[sections[i]][j].type==="OTHER_OPTION"){
                other_options.push(data.selected_sections[sections[i]][j])
              }
              else{
                temp[sections[i]].push(data.selected_sections[sections[i]][j])
              }
            }
          }
          this.setState({otherOptions:other_options, selected_sections:temp})
          // this.setState({selected_sections:data.selected_sections, edit:true}, ()=>{console.log("selected sections:", this.state.selected_sections)})
        }
      }
   }
 }


  checkIfSelected = (data) => {
    for(let i=0;i<this.state.selected_options.length;i++){
      if(this.state.selected_options[i].text===data.text){
        return true;
      }
    }
    return false;
  }

  selectOption = (data) => {
    if(!this.checkIfSelected(data)){
      if(this.props.question.multiple_select){
        this.setState({selected_options:[...this.state.selected_options, data]});
      }
      else{
        this.setState({selected_options:[data]});
      }
    }
    else{
      let temp = this.state.selected_options.filter(x=>x.text!==data.text)
      this.setState({selected_options:temp})
    }
  }

  handleChange = (e) =>{
    e.preventDefault()
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  

  // double section question type
  checkIfSelectedSection = (section, data) => {
    if(this.state.selected_sections[section]){
      for(let i=0;i<this.state.selected_sections[section].length;i++){
        if(this.state.selected_sections[section][i].text===data.text){
          return true;
        }
      }
      return false;
    }
    else{
      return false;
    }
  }

  selectSectionOption = (section, data) => {
    if(this.checkIfSelectedSection(section, data)){
      let temp = this.state.selected_sections;
      temp[section] = temp[section].filter(x=>x.text!==data.text)
      this.setState({selected_sections:temp})
    }
    else{
      let temp = this.state.selected_sections;
      let keys = Object.keys(temp);
      for(let i=0;i<keys.length;i++){
        // if(temp[keys[i]]!==section){
          temp[keys[i]] = temp[keys[i]].filter(x=>x.text!==data.text)
        // }
      }
      if(temp[section]===undefined){
        temp[section]=[];
      }
      temp[section].push(data)
      this.setState({selected_sections:temp})
    }
  }

  // other option
  handleOtherOption = (option, text) => {

    let temp = this.state.otherOptions;
    for(let i=0;i<temp.length;i++){
      if(temp[i].text===option.text){
        temp[i]['other_text'] = text;
      }
    }
    this.setState({otherOptions:temp})
  };

  checkIfOtherOptionIsAnswered = (option) => {
   for(let i=0;i<this.state.otherOptions.length;i++){
     if(this.state.otherOptions[i].text===option.text){
       return this.state.otherOptions[i];
     }
   }
     return undefined;
  }

  selectOtherOption = (option, section) => {
    let data = this.checkIfOtherOptionIsAnswered(option);
    if(data===undefined){
      let temp = this.state.otherOptions;
      if(section!==undefined){
        temp['section']=section;
      }
      option['section'] = section;
      temp.push(option);
      this.setState({otherOptions:temp})
    }
    else{
      let temp =  this.state.otherOptions;
      temp = temp.filter(x => x.text!==option.text);
      this.setState({otherOptions:temp})
    }
  }

  renderOptions = () => {
    if(this.props.question.subType==='MCQ'){
      let options = this.props.question.options.map((option, index)=>(
        <>
        {
          option.type==='OTHER_OPTION' ? (
            <OtherOption
              selected={this.checkIfOtherOptionIsAnswered(option) === undefined ? false : true}
              onClick={this.selectOtherOption}
              onValue={this.handleOtherOption}
              optionData={option}
            ></OtherOption>
          ) : (
            <Option
              key={index}
              selectOption={this.selectOption}
              selected={this.checkIfSelected(option)}
              optionData={option}
            />
          )
        }
        </>
      ));
      return options;
    }
    else if(this.props.question.subType==='TEXT'){
      return(
          <Form.Control 
            as="textarea"
            name="text" 
            className="text"
            placeholder={this.props.question.placeholder}
            value={this.state.text} 
            style={{
                height:200,
                border:'1px solid #cccccc',
                marginTop:2,
            }}
            onChange={this.handleChange}></Form.Control>
      )
    }
    else if(this.props.question.subType==='MULTIPLE_SECTION'){
      return(
        <div>
          {
            this.props.question.sections.map((section, index) => (
              <div key={index}>
                <h6 style={{textAlign:'center', fontWeight:'bold', fontSize:'1.5rem', fontFamily:'sofia-pro'}}>{section.name}</h6>
                <Row className="justify-content-md-center">
                {
                  section.options.map((option, index) => (
                    <>
                    {
                      option.type==='OTHER_OPTION' ? (
                        <OtherOption
                          selected={this.checkIfOtherOptionIsAnswered(option) === undefined ? false : true}
                          onClick={this.selectOtherOption}
                          onValue={this.handleOtherOption}
                          section={section.name}
                          optionData={option}
                        ></OtherOption>
                      ) : (
                        <Option
                          key={index}
                          selectOption={this.selectSectionOption}
                          selected={this.checkIfSelectedSection(section.name,option)}
                          section={section.name}
                          optionData={option}
                        />
                      )
                    }
                    </>
                  ))
                }
                </Row>
              </div>
            ))
          }
        </div>
      )
    }
  }

  getOtherOptionData = () => {
    let options;
    if(this.checkIfOtherOptionIsAnswered()!==undefined){
      options= this.state.selected_options
    }
    else{
      options = this.props.question.options;
    }
    for(let i=0;i<options.length;i++){
      if(options[i].type==="OTHER_OPTION"){
        return options[i]
      }
    }
  }

  submit = () => {
    if(this.props.question.subType==='MCQ'){
      let options = [...this.state.selected_options, ...this.state.otherOptions];
      let data = {
        data:this.props.question,
        id:this.props.canvas.id
      }
      data['data']['selected_options']=options;
      // add a check here if the question is already answered or not
      if(this.state.edit){
        this.props.updateAnswerToCanvas(data);
      }
      else{
        this.props.addAnswerToCanvas(data);
      }
      this.setState({selected_options:[], otherOptions:[]});
      this.props.onContinue();
    }
    else if(this.props.question.subType==='TEXT'){
      // add a check here if the question is already answered or not
      if(this.state.edit){
        let data = {
          data:this.props.question,
          id:this.props.canvas.id
        }
        data['data']['text']=this.state.text;
        this.props.updateAnswerToCanvas(data);
      }
      else{
        let data = {
          data:this.props.question,
          id:this.props.canvas.id
        }
        data['data']['text']=this.state.text;
        this.props.addAnswerToCanvas(data);
      }
      this.setState({text:''});
      this.props.onContinue();
    }
    else if(this.props.question.subType==='MULTIPLE_SECTION'){
      let other = this.state.otherOptions;
      let sections = this.state.selected_sections;
      for(let i=0;i<other.length;i++){
        if(sections[other[i].section]===undefined){
          sections[other[i].section]=[];
        }
        sections[other[i].section].push(other[i])
        this.setState({selected_sections:sections})
      }
      let data = {
        data:this.props.question,
        id:this.props.canvas.id
      }
      data['data']['selected_sections']=sections;
      // add a check here if the question is already answered or not
      if(this.state.edit){
        this.props.updateAnswerToCanvas(data);
      }
      else{
        this.props.addAnswerToCanvas(data);
      }
      this.setState({selected_sections:{},otherOptions:[]});
      this.props.onContinue();
    }
  }


  isDisabled = () => {
    if(this.props.question.subType==="MCQ"){
      if(this.state.selected_options.length===0 && this.state.otherOptions.length===0){
        return true;
      }
      else{
        return false;
      }
    }
    else if(this.props.question.subType==="TEXT"){
      if(this.state.text.trim().length===0){
        return true;
      }
      else{
        return false;
      }
    }
    else{
      if(Object.keys(this.state.selected_sections).length<2 && this.state.otherOptions.length<2){
        return true;
      }
      else{
        return false;
      }
    }
  }

  render(){
    return(
      <Container className="quiz">
        <Row className="justify-content-md-center">
          <h2 className="col-12 question-h2"  style={{ textAlign: "center" }}>
            {this.props.question.title}
          </h2>
          <h4
            className="col-12 question-h4"
            style={{ textAlign: "center" }}
          >
            {this.props.question.subtitle}
          </h4>
          {this.renderOptions()}
          <Col xs={12}>
            <Row className="justify-content-center">
              <Button
                disabled={this.isDisabled()}
                variant="dark"
                onClick={this.submit}
              >
                Continue
              </Button>
            </Row>
          </Col>
        </Row>
      </Container>
    )
  }
}

/**
 *
 * @param {*} state
 * @param {*} ownProps
 * Maps the Redux state to component props
 */
const mapStateToProps = (state, ownProps) => {
  return {
    canvas:state.canvas
  };
};

/**
*
* @param {*} dispatch
* Maps redux actions to component props
*/
const mapDispatchToProps = (dispatch) => {
  return {
      addAnswerToCanvas: (data) => dispatch(addAnswerToCanvas(data)),
      updateAnswerToCanvas: (data) => dispatch(updateAnswerToCanvas(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter((Quiz)));
