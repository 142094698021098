import React from 'react';
import { connect } from "react-redux";
import {Row, Container} from 'react-bootstrap';
import ProgressBar from '../utilities/ProgressBar';
import Progress from '../utilities/Progress';
import BackButton from '../utilities/BackButton';
import CancelButton from '../utilities/CancelButton';
import data from './questions';
// Importing onboarding components

import Quiz from '../quiz/Quiz';
import Upload from '../upload/Upload';
import VisualMoodboard from '../visualMoodboard/VisualMoodboard';
import TextMoodboard from '../TextMoodboard/TextMoodboard';
import UploadPinterest from '../uploadPinterest/UploadPinterest';
import Story from '../story/Story';
import Sketch from '../tools/Sketch';

import { css } from "@emotion/core";
import SyncLoader from "react-spinners/SyncLoader";
// CSS
import '../../stylesheets/question.css';

// ACTIONS
import { createCanvas, addCanvasToUser, saveVersion } from "../../actions/canvasActions";
import { CLIENT } from '../../constants/roles';

const spinnerStyle = css`
  margin-left: 50%;
  transform: translate(-50%, 0);
`;

class Question extends React.Component{
  state = {
    questions:data.data,
    currentIndex:0,
    class:"fade-in", 
    canvas_created:false,
    // screen_loading:true,
    canvas_id:''
  }

  // componentDidMount(){
  //   let millisecondsToWait = 5000;
  //   if(!this.state.canvas_created){
  //     this.props.createCanvas();
  //     setTimeout(() => {
  //       this.setState({screen_loading:false})
  //   }, millisecondsToWait);
  //   }
  // }

  static getDerivedStateFromProps(nextProps, prevState){
    if(prevState.canvas_id===''){
      return { canvas_id: nextProps.canvas.id};
    }
   else return null;
 }

  renderProgressIndicator = () => {
    return(
      <>
      <ProgressBar width={((this.state.currentIndex+1)/this.state.questions.length)*100}></ProgressBar>
      <Row style={{justifyContent:'center'}}>
        {
          this.state.currentIndex!==0 && <BackButton onClick={this.handleBack}></BackButton>
        }
        <Progress
            step={this.state.currentIndex+1}
            step_max={this.state.questions.length}
        ></Progress>
        {
          this.state.currentIndex!==0 && <CancelButton></CancelButton>
        }
      </Row>
      </>
    )
  }

  handleSkip = (e) => {
    e.preventDefault();
    if(!this.state.canvas_created && !this.props.canvas.users.some(user=> user.role  === CLIENT)){
      // ----- CREATING A CANVAS AND ADDING IT TO THE USER --------
      // this.props.createCanvas();
      const data = {
        user_id:'5f286086f008d790207aa2bd',
        canvas_id:this.state.canvas_id
      }
      this.props.addCanvasToUser(data);
      const versionData = {
        version:'GOOGLE',
        id:this.state.canvas_id
      }
      this.props.saveVersion(versionData);
      this.setState({canvas_created:true})
    }
    let millisecondsToWait = 50;
    if(this.state.currentIndex+1!==this.state.questions.length){
      this.setState({class:'fade-out'})
      setTimeout(() => {
          this.setState({currentIndex:this.state.currentIndex+1,class:'fade-in'})
      }, millisecondsToWait);
    }
    else{
      setTimeout(() => {
          window.location.href="/canvas"
      }, millisecondsToWait);
    }
  }

  handleNextQuestion = () => {
    if(!this.state.canvas_created && !this.props.canvas.users.some(user=> user.role  === CLIENT)){
      // ----- CREATING A CANVAS AND ADDING IT TO THE USER --------
      // this.props.createCanvas();
      const data = {
        user_id:'5f286086f008d790207aa2bd',
        canvas_id:this.state.canvas_id
      }
      this.props.addCanvasToUser(data);
      const versionData = {
        version:'GOOGLE',
        id:this.state.canvas_id
      }
      this.props.saveVersion(versionData);
      this.setState({canvas_created:true})
    }
    let millisecondsToWait = 50;
    if(this.state.currentIndex+1!==this.state.questions.length){
      this.setState({class:'fade-out'})
      setTimeout(() => {
          this.setState({currentIndex:this.state.currentIndex+1,class:'fade-in'})
      }, millisecondsToWait);
    }
    else{
      setTimeout(() => {
          window.location.href="/canvas"
      }, millisecondsToWait);
    }
  }

  handleBack = () => {
    this.setState({class:'fade-out'})
    var millisecondsToWait = 500;
    if(this.state.currentIndex-1>=0){
      setTimeout(() => {
          this.setState({currentIndex:this.state.currentIndex-1, class:'fade-in'})
      }, millisecondsToWait);
    }
  }

  renderQuestion = () => {
    // for(let i=0; i<this.state.questions.length; i++){
      switch(this.state.questions[this.state.currentIndex].type){
        case 'QUESTION':
          return <Quiz question={this.state.questions[this.state.currentIndex]} canvas_id={this.state.new_canvas_id} onContinue={this.handleNextQuestion}></Quiz>
        case 'UPLOAD':
          return <Upload question={this.state.questions[this.state.currentIndex]} onContinue={this.handleNextQuestion}/>
        case "VISUAL_MOODBOARD":
          return <VisualMoodboard question={this.state.questions[this.state.currentIndex]} onContinue={this.handleNextQuestion}/>
          case "TEXT_MOODBOARD":
            return <TextMoodboard question={this.state.questions[this.state.currentIndex]} onContinue={this.handleNextQuestion}/>
        case "UPLOAD_PINTEREST":
          return <UploadPinterest question={this.state.questions[this.state.currentIndex]} onContinue={this.handleNextQuestion}/>
        case 'STORY':
          return <Story question={this.state.questions[this.state.currentIndex]} onContinue={this.handleNextQuestion}/>
        case 'SKETCH':
          return <Sketch question={this.state.questions[this.state.currentIndex]} onContinue={this.handleNextQuestion}/>
        default:
          break;
      }
    // }
  }

  render(){
    return(
      <Container fluid={true} id="question">
        {/* {
          this.state.screen_loading ? (
            <div style={{marginTop:'12rem'}}>
            <h1 style={{fontFamily:'bigmoore', textAlign:'center'}}>Loading data</h1>
            <SyncLoader css={spinnerStyle}  color={"#000000"}  sizeUnit={"em"} size={"1"} style={{display:'flex', justifyContent:'center', height:50 }}></SyncLoader>
            </div>
          ) : ( */}
            <>
            {this.renderProgressIndicator()}
            <div className={this.state.class}>
            {this.renderQuestion()}
            {
              this.state.questions.length-1 !== this.state.currentIndex && (
                <div style={{display:'flex', justifyContent:'center', marginBottom:'2rem'}}>
                  <a href="" onClick={this.handleSkip} style={{color:'#000', fontFamily:'sofia-pro', fontWeight:'bold'}}>SKIP THIS QUESTION</a>
                </div>
              )
            }
            </div>
            </>
          {/* )
        } */}
      </Container>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
      user: state.user,
      canvas: state.canvas,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
      // getCanvas: (data) => dispatch(getCanvas(data)),
      saveVersion: (data) => dispatch(saveVersion(data)),
      addCanvasToUser: (data) => dispatch(addCanvasToUser(data)),
      createCanvas: () => dispatch(createCanvas()),

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Question);