import React from 'react';
import {Modal} from 'react-bootstrap';
import Quiz from '../quiz/Quiz';
import Upload from '../upload/Upload';
import VisualMoodboard from '../visualMoodboard/VisualMoodboard';
import TextMoodboard from '../TextMoodboard/TextMoodboard';
import UploadPinterest from '../uploadPinterest/UploadPinterest';
import Story from '../story/Story';
import Dimensions from '../CanvasAddOns/Dimensions'
import Shipping from '../CanvasAddOns/Shipping'
import AdditionalText from '../CanvasAddOns/AdditionalText'

import '../../stylesheets/editTile.css';

class EditTile extends React.Component{
  renderData = () => {
    console.log(this.props)
    if(this.props.editType==="STORY"){
      return <Story onContinue={this.props.onContinue}/>
    } 
    else if(this.props.editType === "DIMENSIONS")
    {
      return <Dimensions data={this.props} onContinue={this.props.onContinue}></Dimensions>
    }

    else if(this.props.editType === "SHIPPING")
    {
      return <Shipping data={this.props} onContinue={this.props.onContinue}></Shipping>
    }

    else if(this.props.editType === "ADDITIONAL_TEXT")
    {
      return <AdditionalText data={this.props} onContinue={this.props.onContinue}></AdditionalText>
    }
    else{
      switch(this.props.question.type){
        case 'QUESTION':
          return <Quiz question={this.props.question} onContinue={this.props.onContinue}></Quiz>
        case 'UPLOAD':
          return <Upload question={this.props.question} onContinue={this.props.onContinue}/>
        case "VISUAL_MOODBOARD":
          return <VisualMoodboard question={this.props.question} onContinue={this.props.onContinue}/>
        case "TEXT_MOODBOARD":
          return <TextMoodboard question={this.props.question} onContinue={this.props.onContinue}/>
        case "UPLOAD_PINTEREST":
          return <UploadPinterest question={this.props.question} onContinue={this.props.onContinue}/>
        default:
          break;
      }
    }
  }
  render(){
    return(
      <Modal
        size="xl"
        show={this.props.isOpen}
        onHide={()=>this.props.onContinue()}
        aria-labelledby="example-modal-sizes-title-sm"
        id="edit-tile"
      >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>   
          {this.renderData()}
        </Modal.Body>
      </Modal>
    )
  }
}

export default EditTile;