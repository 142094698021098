import React from 'react';
import { Container, Col, Row, Button, Image, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import '../../stylesheets/canvas.css';
import CanvasTile from './CanvasTile';
import CommentTileInput from './CommentInputTile';
import expand from '../../images/icons/expand.png';
import collapse from '../../images/icons/collapse.png';
import EditTile from '../editTile/EditTile';
import Navbar from '../utilities/Navbar';
import { getCanvas, updateCanvas } from '../../actions/canvasActions';
import CommentTile from './CommentTile';
import more from '../../images/icons/more.png'
import less from '../../images/icons/less.png'
import close from '../../images/icons/close.svg'
import check from '../../images/icons/selected.svg'
import questionSet from '../question/questions';
import googleBrief from './google-brief';
import ProgressIndicator from './ProgressIndicator';

class Canvas extends React.Component {
  constructor(props) {
    super(props);
    this.commentTileRef = React.createRef();
  }

  componentDidMount() {
    if (localStorage.getItem("nayaCanvasID") !== undefined && localStorage.getItem("nayaCanvasID") !== "") {
      this.props.getCanvas({ id: localStorage.getItem("nayaCanvasID") });
    }
  }

  state = {
    tileExpand: true,
    replyTo: undefined,
    editStory: false,
    showAllComments: false, // handles show and hide of comments
    showPrompt: false, // handles the modal displayed on clicking submit
    showNextSteps: true,
  }

  renderNextSteps = () => {
    let render = (
      <Col style={{ border: "3px solid #ffe300", paddingTop: "2rem", paddingBottom: "1rem" }}>
        {/* <Row style={{justifyContent:'flex-end'}}>
          <img src={close} style={{width:'1rem', height:'1rem', cursor:'pointer', marginRight:"1rem", marginTop:"1rem"}} onClick={()=>{this.setState({showNextSteps:false})}}></img>
        </Row> */}
        <Row style={{ marginBottom: '1.5rem' }}>

          <h4
            style={{
              width: "100%",
              textAlign: "center",
              marginLeft: 10,
              marginRight: 10,
              marginTop: 10,
              fontWeight: "bold",
            }}
          >
            Next Steps
            </h4>

        </Row>
        <Row style={{ padding: "1rem" }}>
          <h6 style={{ fontWeight: 700, fontSize: "1.25rem", textAlign: "center", width: "100%" }}>Please review your responses and hit submit to save!</h6>
          <p style={{ fontWeight: 400, fontSize: "1.1rem", textAlign: "center" }}>Feel free to upload images of your space, inpirations, ideas etc. You can also edit your information here. All your information can be used by our designer to build the best product. Once you are done, click on the button below.</p>
        </Row>
        <Row style={{ marginBottom: '1.5rem' }} className="justify-content-center">
          <Button variant="dark" style={{
            backgroundColor: '#000000', marginTop: '1.5rem',
            paddingLeft: '4rem', paddingRight: '4rem', paddingTop: '0.5rem',
            paddingBottom: '0.5rem', fontFamily: 'sofia-pro', fontSize: '1.25rem'
          }} onClick={
            this.handleSubmit
            // ()=>{this.setState({showPrompt:true})}
          }>Submit</Button>
        </Row>
        <Row>
          <Col xs={12} lg={{ span: 10, offset: 1 }}>
            <Row className="justify-content-center">
              <Col xs={12} lg={3} style={{ textAlign: "center", marginBottom: "0.5rem" }}>
                <span><i className={"fa fa-link"}></i> <a href="https://www.naya.studio">www.naya.studio</a></span>
              </Col>
              <Col xs={12} lg={3} style={{ textAlign: "center", marginBottom: "0.5rem" }}>
                <span><i className={"fa fa-instagram"}></i> <a href="https://www.instagram.com/naya__studio/">naya__studio</a></span>
              </Col>
              <Col xs={12} lg={3} style={{ textAlign: "center", marginBottom: "0.5rem" }}>
                <span><i className={"fa fa-linkedin"}></i> <a href="https://www.linkedin.com/company/studio-naya">naya__studio</a></span>
              </Col>
              <Col xs={12} lg={3} style={{ textAlign: "center", marginBottom: "0.5rem" }}>
                <span><i className={"fa fa-envelope"}></i> <a href="mailto:info@naya.studio">info@naya.studio</a></span>
              </Col>
            </Row>
          </Col>

        </Row>
        <Row>

        </Row>
        {/* <Modal show={this.state.showPrompt} onHide={()=>{this.setState({showPrompt:false})}} centered>
          <Modal.Header closeButton style={{border:'none'}}></Modal.Header>
          <Modal.Body>
            <div style={{width:'100%', display:'flex', justifyContent:'center'}}>
              <Image src={check} style={{width:'3rem'}}/>
            </div>
            <h6 style={{fontFamily:'sofia-pro', fontWeight:700, textAlign:'center', fonTSize:'1.15rem', marginTop:'1rem'}}>Your canvas has been submitted!<br/>You can continue to make changes and submit again.</h6>
          </Modal.Body>
          <Modal.Footer style={{border:'none'}}>
          <Button style={{color:'#999999', backgroundColor:'#FFFFFF', fontFamily:'sofia-pro', fontWeight:700, border:'none', flex:1}} onClick={()=>{this.setState({showPrompt:false})}}>
              Close
          </Button>
            </Modal.Footer>
        </Modal> */}
      </Col>
    );
    return render;
  };

  handleSubmit = () => {
    let data = {
      id: this.props.canvas.id,
      submitted: true
    }
    this.props.updateCanvas(data)
    this.setState({ showPrompt: true })
  }

  checkIfAlreadyAnswered = (q_id) => {
    let questions = this.props.canvas.onboarding_questions;
    for (let i = 0; i < questions.length; i++) {
      if (questions[i].id === q_id) {
        return questions[i];
      }
    }
    return undefined;
  }

  renderCanvasTiles = (questions) => {
    console.log(questions)
    const ORDER = ["question1", "question2", "question3", "question4", "question5", "question6", "question7", "question8", "question9", "story", "text_moodboard", "visual_moodboard", "search_upload", "sketch"]
    if (questions && questions.length > 1) {
      questions = questions.sort(function (a, b) {
        return ORDER.indexOf(a["id"]) > ORDER.indexOf(b["id"])
      })
    }
    let tiles = [];
    for (let i = 0; i < questions.length; i++) {
      switch (questions[i].type) {
        case 'QUESTION':
          let questionData = this.checkIfAlreadyAnswered(questions[i].id);
          tiles.push(
            <CanvasTile
              replyToFunction={this.replyTo}
              type={questions[i].type}
              question={questions[i]}
              data={questionData}
            />
          )
          break;
        case 'UPLOAD':
          let uploadData = this.checkIfAlreadyAnswered(questions[i].id);
          if (uploadData === undefined) {
            tiles.push(
              <CanvasTile
                replyToFunction={this.replyTo}
                type={questions[i].type}
                question={questions[i]}
                data={uploadData}
              />
            )
          }
          else {
            let uploads = uploadData.uploads;
            if (uploads.length == 0) {
              tiles.push(
                <CanvasTile
                  replyToFunction={this.replyTo}
                  type={questions[i].type}
                  question={questions[i]}
                  data={undefined}
                />
              )
            }
            else {
              for (let j = 0; j < uploads.length; j++) {
                tiles.push(
                  <CanvasTile
                    replyToFunction={this.replyTo}
                    type={questions[i].type}
                    question={questions[i]}
                    data={uploads[j]}
                  />
                )
              }
            }
          }
          break;

        case 'TEXT_MOODBOARD':
          let textMoodboardData = this.checkIfAlreadyAnswered(questions[i].id);
          if (textMoodboardData === undefined) {
            tiles.push(
              <CanvasTile
                replyToFunction={this.replyTo}
                type={questions[i].type}
                question={questions[i]}
                data={textMoodboardData}
              />
            )
          }
          else {
            // let options = textMoodboardData.selected_options;
            tiles.push(
              <CanvasTile
                replyToFunction={this.replyTo}
                type={questions[i].type}
                question={questions[i]}
                data={textMoodboardData}
              />
            )
          }
          break;
        case 'VISUAL_MOODBOARD':
          let moodboardData = this.checkIfAlreadyAnswered(questions[i].id);
          if (moodboardData === undefined) {
            tiles.push(
              <CanvasTile
                replyToFunction={this.replyTo}
                type={questions[i].type}
                question={questions[i]}
                data={moodboardData}
              />
            )
          }
          else {
            let options = moodboardData.selected_options;
            for (let j = 0; j < options.length; j++) {
              tiles.push(
                <CanvasTile
                  replyToFunction={this.replyTo}
                  type={questions[i].type}
                  question={questions[i]}
                  data={options[j]}
                />
              )
            }
          }
          break;
        case 'UPLOAD_PINTEREST':
          let uploadPinterestData = this.checkIfAlreadyAnswered(questions[i].id);
          if (uploadPinterestData === undefined) {
            tiles.push(
              <CanvasTile
                replyToFunction={this.replyTo}
                type={questions[i].type}
                question={questions[i]}
                data={uploadPinterestData}
                subtype='upload'
              />
            )
          }
          else {
            if (uploadPinterestData.links && uploadPinterestData.links.length > 0) {
              tiles.push(<CanvasTile
                replyToFunction={this.replyTo}
                type={questions[i].type}
                question={questions[i]}
                data={uploadPinterestData}
                subtype='links'
              />)
            }
            if (uploadPinterestData.uploads) {
              let uploads = uploadPinterestData.uploads;
              for (let j = 0; j < uploads.length; j++) {
                tiles.push(
                  <CanvasTile
                    replyToFunction={this.replyTo}
                    type={questions[i].type}
                    question={questions[i]}
                    data={uploads[j]}
                    subtype='upload'
                  />
                )
              }
            }
          }
          break;
        case 'SKETCH':
          tiles.push(
            <CanvasTile
              replyToFunction={this.replyTo}
              type={"SKETCH"}
              data={this.props.sketch}
            />
          )
          break;
        default:
          break;
      }
    }
    tiles.push(
      <CanvasTile
        replyToFunction={this.replyTo}
        type={"DIMENSIONS"}
        data={this.props.canvas.dimensions_new}
      />
    )

    tiles.push(
      <CanvasTile
        replyToFunction={this.replyTo}
        type={"BUDGET"}
        data={this.props.budget}
      />
    )


    // tiles.push(
    //   <CanvasTile
    //     replyToFunction={this.replyTo}
    //     type={"STORY"}
    //     data={this.props.canvas.additional_info}
    //   />
    // )

    tiles.push(
      <CanvasTile
        replyToFunction={this.replyTo}
        type={"SHIPPING"}
        data={this.props.canvas.address}
      />
    )


    if (this.props.canvas.additional_text && this.props.canvas.additional_text.length > 0) {
      for (let i = 0; i < this.props.canvas.additional_text.length; i++) {
        tiles.push(
          <CanvasTile
            replyToFunction={this.replyTo}
            type={"ADDITIONAL_TEXT"}
            data={this.props.canvas.additional_text[i]}
          />
        )
      }
    }

    tiles.push(
      <CanvasTile
        replyToFunction={this.replyTo}
        type={"ADDITIONAL_TEXT"}
        data={""}
      />
    )

    if (this.state.tileExpand) {
      return tiles
    }
  }

  renderCommentTile = () => {
    return (
      <CommentTileInput
        replyTo={this.state.replyTo}
        clearReplyTo={() =>
          this.setState({ replyTo: undefined })
        }
        getRef={(ref) => (this.commentTileRef = ref)}
      ></CommentTileInput>
    )
  }


  filterComments = (list) => {
    let comments = list.slice()
    return comments.filter(comment => {

      // if(this.props.user && this.props.user["id"] === this.props.canvas["client"])
      // {
      //   return true
      // }
      // else if(this.props.user && "email" in this.props.user && (this.props.user["email"] === "hvvivek2007@gmail.com" || this.props.user["email"] === "saad@naya.studio"))
      // {
      //   return true
      // }
      // else if(this.props.user && comment["user"]["_id"] === this.props.user["id"])
      // {
      //   return true
      // }
      // else if(comment["admin_approved"])
      // {
      //   return true
      // }
      // else
      // {
      //   return false
      // }

      return true

    })

  }

  renderComments = () => {
    let comments_list = [];
    let render;
    try {
      let comments = this.props.comments[
        localStorage.getItem("nayaCanvasID")
      ];

      comments = this.filterComments(comments)

      if (comments !== undefined && comments.length > 0) {
        let len = comments.length;
        for (let index = 0; index < len; index++) {
          const commentData = comments[index];
          if (
            commentData.comment_text !== "" ||
            commentData.comment_image_uploads.length > 0 ||
            commentData.comment_model_uploads.length > 0
          ) {
            // console.log(commentData)
            comments_list.push(
              <CommentTile
                replyToFunction={this.replyTo}
                data={commentData}
                key={commentData._id}
                refId={commentData._id}
              ></CommentTile>
            );
          }
        }
      }

      if (this.state.showAllComments || comments_list.length < 5) {
        render = comments_list.reverse();
      }
      else {
        let temp = comments_list.reverse();
        render = temp.slice(0, 5);
        console.log("DIsplay 5:", render)
      }
    } catch (error) {
      // console.log(error)
      render = <div></div>;
    }
    // console.log(render)
    return render;
  };

  displayMoreComments = () => {
    let comments = this.props.comments[
      localStorage.getItem("nayaCanvasID")
    ];

    if (comments !== undefined && comments.length > 5) {
      return true;
    }
    else {
      return false;
    }
  }

  replyTo = (comment) => {
    this.setState({ replyTo: comment });
    window.setTimeout(() => {
      this.commentTileRef.scrollIntoView();
    }, 200);
  };

  getStage = () => {
    let data = this.props.canvas.stage_history[this.props.canvas.stage_history.length - 1]
    if (data === undefined) {
      return 0;
    }
    else {
      if (['START', 'SKETCH_PHASE', 'WHITE_MODEL_PHASE', 'MCF', 'FABRICATION', 'COMPLETE'].indexOf(data.stage) === -1) {
        return 0;
      }
      else {
        return ['START', 'SKETCH_PHASE', 'WHITE_MODEL_PHASE', 'MCF', 'FABRICATION', 'COMPLETE'].indexOf(data.stage);
      }
    }
  }

  renderGoogleBrief = () => {
    let data = googleBrief;
    let tiles = [];
    for (let i = 0; i < data.length; i++) {
      tiles.push(<CanvasTile
        type={'STATIC-TILE'}
        data={data[i]}
      />)
    }
    return tiles;
  }

  generateTrackingUrl = (carrier, tracking_num) => {
    switch (carrier) {
      case ("UPS"):
        return (`https://www.ups.com/WebTracking/track?track=yes&trackNums=${tracking_num}`);
      case ("USPS"):
        return (`https://tools.usps.com/go/TrackConfirmAction?qtc_tLabels1=${tracking_num}`)
      case ("Fedex"):
        return (`http://www.fedex.com/Tracking?tracknumbers=${tracking_num}`)
      case ("DHL"):
        return (`http://www.dhl.com/en/express/tracking.html?AWB=${tracking_num}&brand=DHL`)
    }
  }

  renderTrackingTab = () => {
    console.log("props", this.props)
    if (this.props.canvas) {
      if (this.props.canvas.shipping.tracking) {
        console.log("Tracking Data", this.props.canvas.shipping.tracking);
        const { carrier, tracking_number } = this.props.canvas.shipping.tracking;
        return (<CanvasTile type="TRACKING" url={this.generateTrackingUrl(carrier, tracking_number)} data={{ "carrier": carrier, "tracking_number": tracking_number }} />)
      } else {
        console.log("No Shipping")
      }
    } else {
      console.log("No Canvas")
    }
  }

  render() {
    return (
      <Container fluid>
        <Navbar></Navbar>
        <Container id="canvas">
          <ProgressIndicator stage={this.getStage()}></ProgressIndicator>
          {
            <EditTile isOpen={this.state.editStory} onContinue={() => { this.setState({ editStory: false }) }} editType="STORY" />
          }
          <Col style={{ marginTop: '3rem' }}>
            <Row className="canvas-name" style={{ marginBottom: '2rem' }}>
              <h4 style={{ fontWeight: 400, fontSize: '2.3rem' }}>{this.props.canvas.name ? this.props.canvas.name : 'Canvas'}</h4>
              <div className="edit-canvas-name" style={{ fontWeight: 700, marginLeft: '1rem', cursor: 'pointer' }} onClick={() => { this.setState({ editStory: true }) }}>edit</div>
            </Row>
            {/* {
            this.state.showNextSteps && (
              <Row>
                {this.renderNextSteps()}
              </Row>
            )
          } */}

            {/* additional info */}
            {
              // this.props.canvas.additional_info && (
              //   <>
              //   {/* <Row>
              //     <hr style={{flex: 1,height: "2px",backgroundColor: "black", }}></hr>
              //   </Row> */}
              //   <Row className="canvas-name" style={{marginBottom:'2rem'}}>
              //     <h6 style={{fontFamily:'sofia-pro'}}>{this.props.canvas.additional_info}</h6>
              //     <div className="edit-canvas-name" style={{fontWeight:700, marginLeft:'1rem', cursor:'pointer'}} onClick={()=>{this.setState({editStory:true})}}>edit</div>
              //   </Row>
              //   </>
              // )
            }

            {/* Tracking */}
            {
              this.renderTrackingTab()
            }
            {/* comments */}
            <Row>
              {this.renderCommentTile()}
              {this.renderComments()}
            </Row>
            {
              this.displayMoreComments() && (
                <Row style={{ marginBottom: '1.5rem', cursor: 'pointer', marginTop: '2rem' }}
                  onClick={() => {
                    this.setState({ showAllComments: !this.state.showAllComments })
                  }}>
                  <hr
                    style={{
                      flex: 1,
                      height: "2px",
                      backgroundColor: "white",
                    }}
                  ></hr>
                  <Image src={this.state.showAllComments ? less : more} fluid style={{ width: '2rem', height: '2rem', marginLeft: 10 }} />
                  <h4
                    style={{
                      marginLeft: 10,
                      marginRight: 10,
                      fontWeight: "bold",
                    }}
                  >
                    {
                      this.state.showAllComments ? "show fewer comments" : "show all comments"
                    }
                  </h4>
                  <hr
                    style={{
                      flex: 1,
                      height: "2px",
                      backgroundColor: "white",
                    }}
                  ></hr>
                </Row>
              )
            }

            {/* canvas tiles */}
            <Row style={{ alignItems: 'center', marginTop: '3rem' }}>
              <Image src={!this.state.tileExpand ? expand : collapse} onClick={() => { this.setState({ tileExpand: !this.state.tileExpand }) }} style={{ width: '2rem', cursor: 'pointer', marginRight: '0.5rem' }} />
              <h6 style={{ fontSize: '1.5rem', fontWeight: 700 }}>Your Design Brief</h6>
            </Row>
            <Row>
              {
                this.props.canvas.onboarding_questions !== undefined && <>{this.renderCanvasTiles(questionSet.data)}</>
              }
              {/* {
              this.renderGoogleBrief()
            } */}
            </Row>
            {/* <Row>
            <hr style={{flex: 1,height: "2px",backgroundColor: "black",}}></hr>
          </Row> */}
          </Col>
        </Container>
      </Container>
    )
  }
}

const mapStateToProps = (state) => {
  let budget = state.canvas.budget;
  if (budget === undefined || budget === null || Object.keys(budget).length === 0) {
    budget = undefined
  }
  return {
    user: state.user,
    canvas: state.canvas,
    comments: state.comments,
    budget: budget,
    sketch: state.canvas.sketch
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getCanvas: (data) => dispatch(getCanvas(data)),
    updateCanvas: (data) => dispatch(updateCanvas(data)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Canvas))
