let data = [
    {
        title:"Design Principles",
        text:"With this desk, we are aiming to create a sense of belonging, pride, and identity for the client while providing the tools and setup for the best possible work from home experience. We would like to bring a sense of conversation and connection with these pieces. Can we be more playful?"
    },
    {
        title:"Personalization",
        text:"In the future, this co:designed piece might be adapted and personalized to suit the tastes of a range of different employees. When designing, consider how aspects of the desk could be further customized. Are there signatures that can be applied?"
    },
    {
        title:"Budget",
        text:"Please design within the allocated budget range. Note that this may be mass produced so the costs might come down"
    },
    {
        title:"Technical",
        text:"Please consider the speed of fabrication and possible mass production of this unit when designing. Can we use technology in some innovative way?"
    },
    {
        title:"Sustainability",
        text:"Each Naya piece is codesigned with its user and made locally which gives the piece unique meaning, story, and provides valuable income. The maker’s story will be included in the delivery of the piece. Sustainable conscious materials will be chosen for each design. Please feel free to research and suggest unique, new, sustainable materials."
    },
    {
        title:"Color/Materials",
        text:"Using neutral hues from natural materials, subtle textures, and small pops of bright color, we can bring the style to life. We will review this in more depth at the MCF stage."
    },
    {
        title:"Style",
        text:"Pay careful attention to the style words that were most popular but also to those voices that should be heard."
    },
    {
        title:"Durability",
        text:"Design for residential use. We may turn this into a commercial use in the future."
    },
]
export default data;