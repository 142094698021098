import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "../../stylesheets/login.css"
import { signUpInGoogle } from "../../actions/authActions"

import Logo from "../../images/logonaya.jpg"
// import GoogleIcon from "../../images/g-logo.svg"
import GoogleIcon from "../../images/grd_logo_white.png"

class Login extends Component {

    render() {

        return (<div id="login">

            <div className="login-body">
                
                {/*
                <div style={{marginBottom: 40, fontSize: 60}}>
                    <img className="landing-img" src={GoogleIcon} alt="Google" style={{padding:'1.6rem'}}/>  +  <img className="landing-img" src={Logo} alt="Naya Studio"  style={{backgroundColor: "white"}} />
                </div>
                */}

                <div>
                    <h1>Welcome</h1>
                    <p style={{marginTop: 20}}>A Collaborative design experience</p>
                </div>

                <div style={{ marginTop: "60px" }}>
                    <p style={{marginBottom:0}}>The onboarding process takes 5-10 minutes.</p>
                    <p>Your choices help make the right decisions during the co-design process.</p>
                </div>

                <button className="btn" onClick={()=>{this.props.history.push('loading')}} style={{marginTop: 20}}>
                    Start
                </button>

            </div>
        </div>)
    }
}

const mapStateToProps = (state) => {
    
    return {
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        // loginToken: () => dispatch(loginToken()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login))