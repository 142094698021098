import React, { Component } from "react";

/**
 * Components
 */
import Option from "./TextMoodboardOption";


/**
 * Higher Level Components
 */
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

/**
 * Style
 */
import { Container, Row, Col, Button, Form } from "react-bootstrap";

import {addAnswerToCanvas, updateAnswerToCanvas} from '../../actions/canvasActions';
import '../../stylesheets/moodboard.css';

class TextMoodboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected_options:[],
            edit:false,
        };
    }

    checkIfQuestionExists = () => {
        let questions = this.props.canvas.onboarding_questions;
        for(let i=0;i<questions.length;i++){
          if(questions[i].id===this.props.question.id){
            return questions[i];
          }
        }
        return null;
      }

    componentDidMount(){
        let data = this.checkIfQuestionExists();
        if(data!==null){
            this.setState({selected_options:data.selected_options, edit:true})
        }
    }

    toggleOption = (option) => {
        if(this.state.selected_options.includes(option)){
            let temp = this.state.selected_options.filter(x=> x!==option);
            this.setState({selected_options:temp})
        }
        else{
            let temp=this.state.selected_options;
            temp.push(option);
            this.setState({selected_option:temp})
        }
    }

    handleKeyUp = (e) => {
        if (e.keyCode == 13) {
            this.toggleOption(e.target.value);
        }
    };

    handleSubmit = () => {
        let data = {
            data:this.props.question,
            id:this.props.canvas.id
          }
          data['data']['selected_options']=this.state.selected_options;
       // add a check here if the question is already answered or not
      if(this.state.edit){
        this.props.updateAnswerToCanvas(data);
      }
      else{
        this.props.addAnswerToCanvas(data);
      }
      this.setState({selected_options:[]});
      this.props.onContinue();
    }

    /**
     * Main Render method
     */
    render() {
        let selected_options = [...this.state.selected_options];
        let selected_options_render = <div></div>;
        if (selected_options && selected_options.length > 0) {
            selected_options_render = selected_options.map((option, index) => (
                <p
                    style={{fontFamily:"sofia-pro"}}
                    key={option + "" + index}
                    id={option}
                    selected={true}
                    onClick={() => this.toggleOption(option)}
                >
                    #{option}
                </p>
            ));
        }

        return (
            <Container fluid={true}>
                <Container>
                    <Row>
                        <h2 className="col-12 question-h2" style={{textAlign:'center'}}>
                            {this.props.question.title}
                        </h2>
                        <h4
                            className="col-12 question-h4"
                            style={{ textAlign: "center" }}
                        >
                        {this.props.question.subtitle}
                        </h4>
                        <Row style={{width:'100%'}}>
                            <Col
                                xs={{ span: 10, offset: 1, order: 1 }}
                                lg={{ span: 5, offset: 0, order: 2 }}
                                className="text-moodboard-left"
                            >
                                <Col xs={{ span: 12 }}>
                                    <h6 className="col-12" style={{fontFamily:'sofia-pro'}}>
                                        Choose from the list of tags
                                    </h6>
                                    <h6 className="col-12" style={{fontFamily:'sofia-pro'}}>Or</h6>
                                    <h6 className="col-12" style={{fontFamily:'sofia-pro'}}>
                                        Add your own tag below
                                    </h6>
                                    <Form.Control
                                        type="text"
                                        onKeyUp={this.handleKeyUp}
                                        placeholder="Add your own tag..."
                                    ></Form.Control>

                                    <Row className="selected-options">
                                        <h6
                                            className="col-12"
                                            style={{ fontWeight: "700" , fontFamily:"sofia-pro"}}
                                        >
                                            Selected Tags
                                        </h6>
                                        {selected_options_render}
                                    </Row>
                                </Col>
                            </Col>
                            <Col
                                xs={{ span: 10, offset: 1, order: 2 }}
                                lg={{ span: 7, offset: 0, order: 1 }}
                                className="text-moodboard-right"
                            >
                                <Col>
                                    {
                                        this.props.question.options.map((option, index) => (
                                            <div key={index}>
                                                <h5 className="col-12">
                                                    {option.name}
                                                    <Row>
                                                    {
                                                        option.options.map((item, index) => (
                                                            <Option
                                                                key={index}
                                                                id={item}
                                                                cols={3}
                                                                selected={this.state.selected_options.includes(item)}
                                                                onClick={this.toggleOption}
                                                                text={item}
                                                            ></Option>
                                                        ))
                                                    }
                                                    </Row>
                                                </h5>
                                            </div>
                                        ))
                                    }
                                </Col>
                            </Col>
                        </Row>

                        <Col xs={12}>
                            <Row className="justify-content-center" style={this.props.edit && {marginTop:20}}>
                                <Button
                                    style={{marginTop:20}}
                                    variant="dark"
                                    onClick={this.handleSubmit}
                                >
                                    Continue
                                </Button>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </Container>
        );
    }
}

/**
 *
 * @param {*} state
 * @param {*} ownProps
 * Maps the Redux state to component props
 */
const mapStateToProps = (state, ownProps) => {
    return {
      canvas:state.canvas
    };
  };
  
  /**
  *
  * @param {*} dispatch
  * Maps redux actions to component props
  */
  const mapDispatchToProps = (dispatch) => {
    return {
        addAnswerToCanvas: (data) => dispatch(addAnswerToCanvas(data)),
        updateAnswerToCanvas: (data) => dispatch(updateAnswerToCanvas(data)),
    };
  };

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(TextMoodboard));
