import React, { Component } from "react";
import { Container, Row, Col, Button, Form, Alert, ThemeProvider } from "react-bootstrap";
// import "../../stylesheets/style.css";
// import "../../stylesheets/pre-canvas.css";
import "../../stylesheets/auth.css";

import { withRouter } from 'react-router-dom';

import BounceLoader from "react-spinners/BounceLoader";
import BarLoader from "react-spinners/BarLoader";
import axios from "axios";
import queryString from "query-string";
import { API_ENDPOINT } from "../../constants/secrets";

import { ReactComponent as GoogleIcon } from "../../images/g-logo.svg";
import { ReactComponent as FacebookIcon } from "../../images/f-logo.svg";

const config = {
  headers: {
      "Access-Control-Allow-Origin": "*",
  },
};
axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

class Auth extends Component {
  constructor(props) {
    super(props);
    this.state = {
        email: "",
        password: "",
        confirmPassword: "",
        agreeToTM: false,
        showSignupForm: false,
        showPasswordResetRequestForm: false,

        errorMessage: this.props.authError,
        user:null,
        loginError:undefined, authLoading:false, signUpError:undefined,
    };
  }

    ////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////

  showSignUp = (e) => {
    e.preventDefault();
    // this.props.clearErrorMessage();
    this.setState({
        showSignupForm: true,
        showPasswordResetRequestForm: false,
    });
  };

  showForgotPassword = (e) => {
    e.preventDefault();
    // this.props.clearErrorMessage();
    this.setState({ showPasswordResetRequestForm: true });
  };

  showLogin = (e) => {
    e.preventDefault();
    // this.props.clearErrorMessage();
    this.setState({
        showSignupForm: false,
        showPasswordResetRequestForm: false,
    });
  };

  handleChange = (e) => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value, signUpError: null });
  };
    ////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////

  handleLoginLocal = (e) => {
    e.preventDefault();
    this.setState({ loading: true, authLoading:true });
    const AUTH_LOGIN_LOCAL_URI = `${API_ENDPOINT}/auth/login`;
    axios.post(AUTH_LOGIN_LOCAL_URI, this.state, config)
    .then((response)=>{
      if(response.data.status){
        localStorage.setItem('nayaJwtToken', response.data.token)
        this.setState({user:response.data.user, authLoading:false}, () => this.checkForUser())
      }
      else{
        this.setState({loginError:response.data.message, authLoading:false})
      }
    })
    .catch((e)=>{
      console.log("login logical error:",e)
      this.setState({loginError:"Our systems are encountering problems. Please try again in some time", authLoading:false})
    })
  };

  handleInputChange = (event) => {
    const { name } = event.target;
    const value = name === 'agreeToTM' ? event.target.checked : event.target.value;

    this.setState({
        [name]: value,
        showFunctionError: false,
        showPasswordError: false,
        showRegistrationError: false,
    });
  };

  handleForgotPassword = async (e) => {
    e.preventDefault();
    const FIND_USER_URI = `${API_ENDPOINT}/api/user`;
    try {
        let user = await axios.get(FIND_USER_URI, {
            params: { email: this.state.email },
        });
        user = user.data.user;
        if (user) {
            const SEND_RESET_PASSWORD_EMAIL_URI = `${API_ENDPOINT}/auth/send-reset-email`;
            let email_sent = await axios.post(
                SEND_RESET_PASSWORD_EMAIL_URI,
                { user_id: user._id, email: user.email }
            );
            email_sent = email_sent.data.status;
            if (email_sent) {
                this.setState({
                    forgotPasswordMessage:
                        "The link to reset your password has been sent to your email",
                });
            } else {
                this.setState({
                    forgotPasswordMessage:
                        "Error sending email. Please check your internet connection",
                });
            }
        } else {
            this.setState({
                forgotPasswordMessage:
                    "We did not find a user with this email id",
            });
        }
    } catch (error) {
        this.setState({
            forgotPasswordMessage:
                "We did not find a user with this email id",
        });
    }
  };

  handleSignupLocal = (e) => {
    e.preventDefault();
    if(this.state.agreeToTM){
        if (this.state.password === this.state.confirmPassword) {
          this.setState({authLoading:true})
            const data = {
                email: this.state.email,
                password: this.state.password,
            };
            const AUTH_SIGNUP_LOCAL_URI = `${API_ENDPOINT}/auth/register`;
            data.user_type = "USER";
            axios.post(AUTH_SIGNUP_LOCAL_URI, this.state, config)
            .then((response)=>{
              if(response.data.status){
                this.setState({authLoading:false})
                localStorage.setItem('nayaJwtToken', response.data.token);
                this.setState({user:response.data.user})

              }
              else{
                this.setState({signUpError:response.data.message, authLoading:false})
              }
            })
            .catch((e)=>{
              this.setState({authLoading:false, signUpError:"Our systems are encountering problems. Please try again in some time"})
              console.log("sign up local error:", e)
            })
        } else {
            this.setState({ signUpError: "Passwords do not match" , authLoading:false});
        }
    }
    else {
        this.setState({ signUpError: "Please agree to the terms and conditions", authLoading:false });
    }
  };

  handleSignupGoogle = (e) => {
    e.preventDefault();
    // to make sure the saved state is restored when return from oauth
    localStorage.setItem("nayaAppStateRestore", "true");
    window.location.href = `${API_ENDPOINT}/auth/google?callbackURL=${window.location.protocol}//${window.location.host}`
  };

  handleSignupFacebook = (e) => {
    e.preventDefault();
    // to make sure the saved state is restored when return from oauth
    localStorage.setItem("nayaAppStateRestore", "true");
    window.location.href = `${API_ENDPOINT}/auth/facebook?callbackURL=${window.location.protocol}//${window.location.host}`
  };

    ////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////

  renderPasswordResetForm = () => {
    return (
      <Container fluid id='auth'>
          <Row>
              <Col
                  xs={{ span: 10, offset: 1 }}
                  lg={{ span: 4, offset: 4 }}
                  className='auth-wrapper'
              >
                  {this.state.authLoading && (
                      <BarLoader
                          height={6}
                          css={{ width: "100%", borderRadius: "10px" }}
                      ></BarLoader>
                  )}

                  <Col
                      xs={12}
                      style={{ paddingTop: "2rem", marginBottom: "4rem" }}
                  >
                      <h2
                          className='col-12'
                          style={{
                              marginBottom: "0rem",
                              color: "#888",
                              fontSize: "2rem",
                              fontFamily:'bigmoore',
                              fontWeight: "bold",
                              textAlign:'center'
                          }}
                      >
                          naya
                      </h2>
                      <h3
                          className='col-12'
                          style={{
                              marginBottom: "0rem",
                              textAlign: "center",
                              color: "black",
                              fontWeight: "bold",
                              fontSize: "2.2rem",
                          }}
                      >
                          Forgot Password
                      </h3>
                  </Col>
                  <Col
                      xs={12}
                      lg={{ span: 10, offset: 1 }}
                      style={{ fontSize: "1.2rem" }}
                  >
                      <Form>
                          {this.state.forgotPasswordMessage &&
                              this.state.forgotPasswordMessage !== "" && (
                                  <Alert variant={"warning"}>
                                      {this.state.forgotPasswordMessage}
                                  </Alert>
                              )}
                          <Form.Group controlId='formBasicEmail'>
                              <Form.Label style={{fontWeight:'bold'}}>Email address</Form.Label>
                              <Form.Control
                                  type='email'
                                  placeholder='Enter email'
                                  name='email'
                                  value={this.state.email}
                                  onChange={this.handleChange}
                              />
                          </Form.Group>
                          <Col xs={12}>
                              <Row style={{justifyContent:'center'}}>
                                  <Button
                                      variant='dark'
                                      onClick={this.handleForgotPassword}
                                      id='login-btn'
                                  >
                                      Send Email
                                  </Button>
                              </Row>
                              <Row
                                  className='justify-content-md-center'
                                  style={{
                                      marginBottom: "8rem",
                                      marginTop: "2rem",
                                  }}
                              >
                                  <p
                                      className='skip-button'
                                      onClick={this.showLogin}
                                      style={{ fontSize: "1.2rem", fontWeight:'bold' }}
                                  >
                                      Go back to Login
                                  </p>
                                  <p
                                      className='skip-button'
                                      onClick={this.showSignUp}
                                      style={{ fontSize: "1.2rem", fontWeight:'bold' }}
                                  >
                                      Don't have an account? Sign Up
                                  </p>
                              </Row>
                          </Col>
                      </Form>
                  </Col>
              </Col>
          </Row>
        </Container>
    );
  };

  renderLoginForm = () => {
      return (
          <Container fluid id='auth'>
              <Row>
                  <Col
                      xs={{ span: 10, offset: 1 }}
                      lg={{ span: 4, offset: 4 }}
                      className='auth-wrapper'
                  >
                      {this.state.authLoading && (
                          <BarLoader
                              height={6}
                              css={{ width: "100%", borderRadius: "10px" }}
                          ></BarLoader>
                      )}

                      <Col
                          xs={12}
                          style={{ paddingTop: "2rem", marginBottom: "4rem" }}
                      >
                          <h2
                              className='col-12'
                              style={{
                                  marginBottom: "0rem",
                                  color: "#888",
                                  fontSize: "2rem",
                                  fontFamily:'bigmoore',
                                  fontWeight: "bold",
                                  textAlign:'center'
                              }}
                          >
                              naya
                          </h2>
                          <h3
                              className='col-12'
                              style={{
                                  marginBottom: "0rem",
                                  textAlign: "center",
                                  color: "black",
                                  fontWeight: "bold",
                                  fontSize: "2.2rem",
                              }}
                          >
                              Welcome
                          </h3>
                      </Col>

                      <Col
                          xs={12}
                          lg={{ span: 10, offset: 1 }}
                          style={{ fontSize: "1.2rem" }}
                      >
                          {this.state.loginError !== undefined &&
                              this.state.loginError !== "jwt malformed" &&
                              this.state.loginError !== "jwt expired" && (
                                  <Alert variant={"danger"}>
                                      {this.state.loginError}
                                  </Alert>
                              )}
                          <Form>
                              <Form.Group controlId='formBasicEmail'>
                                  <Form.Label style={{fontWeight:'bold'}}>Email address</Form.Label>
                                  <Form.Control
                                      type='email'
                                      placeholder='Enter email'
                                      name='email'
                                      value={this.state.email}
                                      onChange={this.handleChange}
                                      disabled={this.state.authLoading}
                                  />
                              </Form.Group>

                              <Form.Group controlId='formBasicPassword'>
                                  <Form.Label style={{fontWeight:'bold'}}>Password</Form.Label>
                                  <Form.Control
                                      type='password'
                                      placeholder='Password'
                                      name='password'
                                      value={this.state.password}
                                      onChange={this.handleChange}
                                      disabled={this.state.authLoading}
                                  />
                              </Form.Group>
                              <Col xs={12} style={{ padding: "0rem" }}>
                                  <div>
                                      <Button
                                          variant='dark'
                                          className='col-12 col-lg-10 order-1 order-lg-2'
                                          onClick={this.handleLoginLocal}
                                          id='login-btn'
                                          style={{marginLeft:'auto', marginRight:'auto'}}
                                      >
                                          Login
                                      </Button>
                                      <Row
                                          className="justify-content-md-center"
                                          style={{
                                              marginTop: "2rem",
                                          }}
                                      >
                                          <p
                                              className="skip-button"
                                              onClick={this.showSignUp}
                                              style={{ fontSize: "1.2rem", fontWeight:'bold' }}
                                          >
                                              Don't have an account? Sign up
                                          </p>
                                      </Row>
                                      <Row
                                          className='justify-content-md-center'
                                          style={{ marginTop: "1rem" }}
                                      >
                                          <Button
                                              className='col-6 order-2 order-lg-1'
                                              onClick={
                                                  this.showForgotPassword
                                              }
                                              id='forgot-password-btn'
                                          >
                                              Forgot Password?
                                          </Button>
                                      </Row>

                                      <p
                                          style={{
                                              borderBottom: "1px solid black",
                                              textAlign: "center",
                                              lineHeight: "0.1em",
                                              marginTop: "1rem",
                                          }}
                                      >
                                          <span
                                              style={{
                                                  backgroundColor: "white",
                                                  padding: "0 1rem",
                                              }}
                                          >
                                              or
                                          </span>
                                      </p>
                                      <Button
                                          onClick={this.handleSignupGoogle}
                                          className='col-12 col-lg-10 order-1 order-lg-2'
                                          style={{
                                              color: "black",
                                              textTransform: "none",
                                              fontSize: "1.1rem",
                                          }}
                                          id='google-btn'
                                      >
                                          <GoogleIcon
                                              style={{
                                                  height: "18px",
                                                  width: "18px",
                                                  marginRight: "15px",
                                              }}
                                          />
                                          Sign in with Google
                                      </Button>
                                      <Button
                                          onClick={this.handleSignupFacebook}
                                          className='col-12 col-lg-10 order-1 order-lg-2'
                                          style={{
                                              textTransform: "none",
                                              fontSize: "1.1rem",
                                              marginBottom:30
                                          }}
                                          id='facebook-btn'
                                      >
                                          <FacebookIcon
                                              style={{
                                                  height: "18px",
                                                  width: "18px",
                                                  marginRight: "15px",
                                              }}
                                          />
                                          Sign in with Facebook
                                      </Button>   
                                  </div>
                              </Col>
                          </Form>
                      </Col>
                  </Col>
              </Row>
          </Container>
      );
  };

  renderSignupForm = () => {
      return (
          <Container fluid id='auth'>
              <Row>
                  <Col
                      xs={{ span: 10, offset: 1 }}
                      lg={{ span: 4, offset: 4 }}
                      className='auth-wrapper'
                  >
                      {this.state.authLoading && (
                          <BarLoader
                              height={6}
                              css={{ width: "100%", borderRadius: "10px" }}
                          ></BarLoader>
                      )}

                      <Col
                          xs={12}
                          style={{ paddingTop: "2rem", marginBottom: "4rem" }}
                      >
                          <h2
                              className='col-12'
                              style={{
                                  marginBottom: "0rem",
                                  color: "#888",
                                  fontSize: "2rem",
                                  fontFamily:'bigmoore',
                                  fontWeight: "bold",
                                  textAlign:'center'
                              }}
                          >
                              naya
                          </h2>
                          <h3
                              className='col-12'
                              style={{
                                  marginBottom: "0rem",
                                  textAlign: "center",
                                  color: "black",
                                  fontWeight: "bold",
                                  fontSize: "2.2rem",
                              }}
                          >
                              Sign Up
                          </h3>
                      </Col>

                      <Col
                          xs={12}
                          lg={{ span: 10, offset: 1 }}
                          style={{ fontSize: "1.2rem" }}
                      >
                          {this.props.signUpError !== undefined && (
                              <Alert variant={"danger"}>
                                  {this.props.signUpError}
                              </Alert>
                          )}
                          {this.state.signUpError !== undefined && (
                              <Alert variant={"danger"}>
                                  {this.state.signUpError}
                              </Alert>
                          )}
                          <Form>
                              <Form.Group controlId='formBasicEmail'>
                                  <Form.Label style={{fontWeight:'bold'}}>Email address</Form.Label>
                                  <Form.Control
                                      type='email'
                                      placeholder='Enter email'
                                      name='email'
                                      value={this.state.email}
                                      onChange={this.handleChange}
                                  />
                              </Form.Group>

                              <Form.Group controlId='formBasicPassword'>
                                  <Form.Label style={{fontWeight:'bold'}}>Password</Form.Label>
                                  <Form.Control
                                      type='password'
                                      placeholder='Password'
                                      name='password'
                                      value={this.state.password}
                                      onChange={this.handleChange}
                                  />
                              </Form.Group>
                              <Form.Group controlId='formBasicPassword'>
                                  <Form.Label style={{fontWeight:'bold'}}>Confirm Password</Form.Label>
                                  <Form.Control
                                      type='password'
                                      placeholder='Confirm Password'
                                      name='confirmPassword'
                                      value={this.state.confirmPassword}
                                      onChange={this.handleChange}
                                  />
                              </Form.Group>
                              <Form.Group controlId='formBasicCheckbox'>
                                  <Form.Check>
                                      <Form.Check.Input type="checkbox" name="agreeToTM" checked={this.state.agreeToTM} onChange={this.handleInputChange} isValid required/>
                                      <Form.Check.Label style={{ "color": "black" }}>I agree to the <a target="_blank" rel="noopener noreferrer" href="https://docs.google.com/document/d/e/2PACX-1vTov12Yo4MCqPhMYsXgHgngMOTRAyeqNHCfwnEfMVCvZywTrMROtX9wFF9kBaS7XTPR0x6wSQ8Dj-vV/pub">terms and conditions</a></Form.Check.Label>
                                  </Form.Check>
                              </Form.Group>

                              <Col xs={12} style={{ padding: "0rem" }}>
                                  <div>
                                      <Button
                                          variant='dark'
                                          onClick={this.handleSignupLocal}
                                          className='col-12 col-lg-10 order-1 order-lg-2'
                                          id='login-btn'
                                          style={{marginLeft:'auto', marginRight:'auto'}}
                                      >
                                          Sign Up
                                      </Button>
                                      <p
                                          style={{
                                              borderBottom: "1px solid black",
                                              textAlign: "center",
                                              lineHeight: "0.1em",
                                              marginTop: "2rem",
                                              marginBottom: "2rem",
                                          }}
                                      >
                                          <span
                                              style={{
                                                  backgroundColor: "white",
                                                  padding: "0 1rem",
                                              }}
                                          >
                                              or
                                          </span>
                                      </p>
                                      <Button
                                          onClick={this.handleSignupGoogle}
                                          className='col-12 col-lg-10 order-1 order-lg-2'
                                          style={{
                                              color: "black",
                                              textTransform: "none",
                                              fontSize: "1.1rem",
                                          }}
                                          id='google-btn'
                                      >
                                          <GoogleIcon
                                              style={{
                                                  height: "18px",
                                                  width: "18px",
                                                  marginRight: "15px",
                                              }}
                                          />
                                          Sign up with Google
                                      </Button>
                                      <Button
                                          onClick={this.handleSignupFacebook}
                                          className='col-12 col-lg-10 order-1 order-lg-2'
                                          style={{
                                              textTransform: "none",
                                              fontSize: "1.1rem",
                                          }}
                                          id='facebook-btn'
                                      >
                                          <FacebookIcon
                                              style={{
                                                  height: "18px",
                                                  width: "18px",
                                                  marginRight: "15px",
                                              }}
                                          />
                                          Sign up with Facebook
                                      </Button>
                                  </div>
                                  <Row
                                      className='justify-content-md-center'
                                      style={{
                                          marginTop: "2rem",
                                      }}
                                  >
                                      <p
                                          className='skip-button'
                                          style={{ fontSize: "1.2rem", fontWeight:'bold' }}
                                          onClick={this.showLogin}
                                      >
                                          Already have an account? Login here
                                      </p>
                                  </Row>
                              </Col>
                          </Form>
                      </Col>
                  </Col>
              </Row>
          </Container>
      );
  };

  render() {
      let renderedForm = (
          <Container>
              <Row>
                  <Col xs={12}>
                      <div className='pre-canvas-h2'>
                          <BounceLoader></BounceLoader>
                      </div>
                  </Col>
              </Row>
          </Container>
      );

      // if (this.state.user) {
        // if (this.state.user || this.state.user.id) {
          if (!this.state.showSignupForm) {
              if (this.state.showPasswordResetRequestForm) {
                  renderedForm = this.renderPasswordResetForm();
              } else {
                  renderedForm = this.renderLoginForm();
              }
          } else {
              renderedForm = this.renderSignupForm();
          }
      // }

      return renderedForm;
  }

  checkForUser = () => {
    if(this.state.user && this.state.user._id){
        this.props.history.push("/")
    }
    if(localStorage.getItem('nayaJwtToken') && localStorage.getItem('nayaJwtToken')!=='undefined'){
    //   if(!this.state.authLoading){
    //     this.setState({authLoading:true})
    //   }
    //   const AUTH_TOKEN_LOCAL_URI = `${API_ENDPOINT}/auth/jwt`;
    //   let data = localStorage.getItem("nayaJwtToken");
    //   if (data !== "") {
    //     axios.get(AUTH_TOKEN_LOCAL_URI, {headers: { Authorization: `Bearer ${data}` },})
    //     .then((response) => {
    //         if (response.data.status) {
    //           this.setState({user:response.data.user, authLoading:false})
    //         } 
    //         else{
    //           this.setState({loginError:response.data.message, authLoading:false})
    //         }
    //     })
    //     .catch((e) => {
    //       this.setState({authLoading:false})
    //       console.log("login error:", e)
    //     });
    //   } 
        this.props.history.push("/account")
    }
    if(queryString.parse(this.props.location.search).token) {
      // passport-google redirect 
      const values = queryString.parse(this.props.location.search);
      if (values.token) {
        // if(!this.state.authLoading){
        //   this.setState({authLoading:true})
        // }
          localStorage.setItem("nayaJwtToken", values.token);
          this.props.history.push("/account")
        //   const AUTH_TOKEN_LOCAL_URI = `${API_ENDPOINT}/auth/jwt`;
        //   axios.get(AUTH_TOKEN_LOCAL_URI, {headers: { Authorization: `Bearer ${values.token}` },})
        // .then((response) => {
        //   this.setState({authLoading:false})
        //     if (response.data.status) {
        //       this.setState({user:response.data.user})
        //     } 
        //     else{
        //       this.setState({loginError:response.data.message})
        //     }
        // })
        // .catch((e) => {
        //   this.setState({authLoading:false})
        //   console.log("login error:", e)
        // });
      }
  }
    
  };

  componentDidUpdate() {
    //   this.checkForUser();
  }

    /**
     * Called immediately after render
     */
  componentDidMount() {
      this.checkForUser();
  }
}

export default withRouter(Auth);
