import canvasReducer from "./canvasReducer";
import userReducer from "./userReducer";
import {combineReducers} from 'redux'
// import UIReducer from "./UIReducer";
import commentsReducer from './commentsReducer'
import notificationsReducer from './notificationsReducer'
// import errorsReducer from "./errorsReducer";
// import transactionsReducer from "./transactionsReducer";
import loadingReducer from "./loadingReducer";
import annotationsReducer from "./annotationsReducer";

const rootReducer = combineReducers({
    canvas: canvasReducer, 
    user: userReducer,
    // transactions: transactionsReducer,
    // UI: UIReducer,
    comments: commentsReducer,
    // errors: errorsReducer,
    loading: loadingReducer,
    annotations: annotationsReducer,
    notifications:notificationsReducer
})

export default rootReducer