import React, { Component } from 'react'
import { connect } from 'react-redux'   
import {Container, Row, Col, Button, Form} from 'react-bootstrap'
import { withRouter } from 'react-router-dom'
import {API_ENDPOINT} from '../../constants/secrets'
import axios from 'axios'
import CanvasWidget from './CanvasWidget'
import '../../stylesheets/account.css'


class CanvasManager extends Component{
    
    constructor(props)
    {
        super(props)
        this.state = {
            canvas_arr: []
        }
    }

    componentDidMount()
    {
        if(this.props.canvas_ids !== undefined)
        {
            let canvas_ids = new Set(this.props.canvas_ids)
            canvas_ids = [...canvas_ids]

            for(var i=0; i< canvas_ids.length; i++)
            {
                var GET_CANVAS_URL = `${API_ENDPOINT}/api/canvas`

                axios.get(GET_CANVAS_URL, {params: {id: canvas_ids[i]}})
                .then(response => 
                    {
                        if(response.data.status)
                        {
                            let all_canvases = [...this.state.canvas_arr, response.data.canvas]
                            all_canvases = all_canvases.sort(function(c1, c2){ if(c1.updatedAt < c2.updatedAt) { return 1} else {return -1}})
                            this.setState({canvas_arr: all_canvases})
                        }
                    })
                .catch(e => 
                    {
                        console.log(e)
                    })
                
            }
        }
    }

    render()
    {

        let CanvasWidgets = this.state.canvas_arr.map(canvas_data => <CanvasWidget key={canvas_data._id} data={canvas_data}></CanvasWidget>)
        
        return (
            <Container id="canvas-manager">
                <Row>
                    <h2 className="col-12">My Projects</h2>
                    {CanvasWidgets}
                    <hr style={{"height": "2px", "color":"black", "width": "100%"}}></hr>
                    
                </Row>
            </Container>
        )
    }
}



const mapStateToProps = (state) => {
    return { 
        user: state.user,
    }
}

export default connect(mapStateToProps)(withRouter(CanvasManager))