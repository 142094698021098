import React, { Component } from "react";
/**
 * Higher Order Components
 */
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

/**
 * Style
 */
import { Container, Row, Col, Button } from "react-bootstrap";
// import "../../stylesheets/style.css";
import Option from './Option';
import {addAnswerToCanvas, updateAnswerToCanvas} from '../../actions/canvasActions';

class VisualMoodboard extends Component {
  state = {
    selected_options:[],
    edit:false, 
    options:[], startIndex:0, endIndex:9
  }

  checkIfQuestionExists = () => {
    let questions = this.props.canvas.onboarding_questions;
    for(let i=0;i<questions.length;i++){
      if(questions[i].id===this.props.question.id){
        return questions[i];
      }
    }
    return null;
  }

  shuffleArray = (selected_options) => {
    let temp = this.props.question.images;
    temp.sort(() => Math.random() - 0.5);
    for(let i=0; i<selected_options.length;i++){
      temp = temp.filter(x => x!==selected_options[i])
    }
    this.setState({options:temp, endIndex:9-this.state.selected_options.length})
  }

  showMore = () => {
    this.shuffleArray(this.state.selected_options)
    // let temp = this.state.options;
    // for(let i=0; i<this.state.selected_options.length;i++){
    //   temp = temp.filter(x => x!==this.state.selected_options[i])
    // }
    // this.setState({options:temp})
    // let toIncrease = 9-this.state.selected_options.length;
    // if(this.state.endIndex+1>=this.state.options.length){
    //   this.setState({startIndex:0, endIndex: 9 - this.state.selected_options.length})
    // }
    // else if(this.state.endIndex+toIncrease > this.state.options.length){
    //   let temp = this.state.options.slice(this.state.endIndex, this.state.options.length).concat(this.state.options.slice(0, this.state.endIndex));
    //   this.setState({options:temp})
    // }
    // else{
    //   this.setState({startIndex:this.state.startIndex+this.state.selected_options.length,
    //   endIndex:this.state.endIndex + 9 - this.state.selected_options.length})
    // }
  }

  componentDidMount(){
    let data = this.checkIfQuestionExists();
    if(data!==null){
      this.setState({selected_options:data.selected_options, edit:true}, ()=>{
        this.shuffleArray(this.state.selected_options);
      })
    }
    else(
      this.shuffleArray([])
    )
  }

  /**
   * Selects or deselects the options
   */
  toggleOption = (option) => {
    if(this.state.selected_options.includes(option)){
      let temp = this.state.selected_options.filter(x=>x!==option);
      this.setState({selected_options:temp, options:[...this.state.options, option], endIndex:this.state.endIndex+1})
    }
    else{
      let tempOptions = this.state.options.filter(x=>x!==option);
      this.setState({selected_options:[...this.state.selected_options, option], options:tempOptions, endIndex:this.state.endIndex-1})
    }
  };

  /**
   * Handles form submit - Continue button
   */
  handleSubmit = (e) => {
    let data = {
      data:this.props.question,
      id:this.props.canvas.id
    }
    data['data']['selected_options']=this.state.selected_options;
    console.log("TO SEND DATA: ", data);
    // add a check here if the question is already answered or not
    if(this.state.edit){
      this.props.updateAnswerToCanvas(data);
    }
    else{
      this.props.addAnswerToCanvas(data);
    }
    this.setState({selected_options:[]})
    this.props.onContinue();
  };

  /**
   * Main Render method
   */
  render() {
    const optionsRender = this.state.selected_options.concat(this.state.options.slice(this.state.startIndex, this.state.endIndex)).map((option, index) => (
      <Option
        key={index}
        id={option}
        selected={this.state.selected_options.includes(option)}
        onClick={this.toggleOption}
        link={option}
      ></Option>
    ));

        return (
          <Container fluid={true}>
            <Container>
              <Row style={{justifyContent:'center'}}>
                  <h2 className="col-9 question-h2" style={{ textAlign: "center" }}>
                      {this.props.question.title}
                  </h2>
                  <h4
                      className="col-9 question-h4"
                      style={{ textAlign: "center" }}
                  >
                  {this.props.question.subtitle}
                  </h4>
                <Col xs={9}>
                  <Row style={{justifyContent:'center'}}>
                    <Button 
                      onClick = {this.showMore} 
                      style={{marginTop:0, padding:'0.5rem 2rem', backgroundColor:'#FFFFFF', color:'#000', fontFamily:'sofia-pro', border:'1px solid #000', borderRadius:0}}>SHOW ME MORE</Button>
                  </Row>
                </Col>                  
                <Col xs={9} lg={{ span: 6, offset: 3 }} style={{margin:'auto'}}>
                  <Row>
                    {optionsRender}
                  </Row>
                </Col>
                <Col xs={9}>
                  <Row className="justify-content-center">
                    <Button variant="dark" onClick={this.handleSubmit} disabled={this.state.selected_options.length===0 ? true : false}>
                        Continue
                    </Button>
                  </Row>
                </Col>
              </Row>
            </Container>
          </Container>
        );
    }
}

/**
 * Maps the Redux state to component props
 * @param {*} state
 */
const mapStateToProps = (state) => {
    return {
      canvas:state.canvas
    };
};

/**
 * Maps redux actions to component props
 * @param {*} dispatch
 */
const mapDispatchToProps = (dispatch) => {
    return {
      addAnswerToCanvas: (data) => dispatch(addAnswerToCanvas(data)),
      updateAnswerToCanvas: (data) => dispatch(updateAnswerToCanvas(data)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(VisualMoodboard));