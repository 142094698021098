import React from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {Container, Row, Col, Button} from 'react-bootstrap';
import {UPLOAD_ENDPOINT } from "../../constants/secrets";
import Gallery from "react-photo-gallery";
import axios from "axios";

import { css } from "@emotion/core";
import SyncLoader from "react-spinners/SyncLoader";

import Dropzone from "react-dropzone";
// importing icons
import documentIcon from "../../images/icons/document.svg";
import audio from "../../images/icons/audio.svg";
import video from "../../images/icons/video.svg";
import close from "../../images/icons/close.svg";
import cloudUpload from "../../images/icons/cloud_upload_icon.png";

import Option from './Option';

import {addAnswerToCanvas, updateAnswerToCanvas} from '../../actions/canvasActions';

// css for dropzone
const style = {
  container: {
    cursor: "pointer",
    border: "1px dashed black",
    borderRadius: "5px",
    // backgroundColor: "black",
    padding: "50px",
    // minHeight: "500px",
    textAlign: "center",
  },
};

const spinnerStyle = css`
  margin-left: 50%;
  transform: translate(-50%, 0);
`;

class Upload extends React.Component{
  state = {
    uploads:[],
    gallery:[],
    otherDocuments:[],
    uploading:false,
    edit:false,
  }

  checkIfQuestionExists = () => {
    let questions = this.props.canvas.onboarding_questions;
    for(let i=0;i<questions.length;i++){
      if(questions[i].id===this.props.question.id){
        return questions[i];
      }
    }
    return null;
  }

  componentDidMount(){
    let data = this.checkIfQuestionExists();
    if(data!==null){
      this.setState({uploads:data.uploads, edit:true});
      for(let i=0;i<data.uploads.length;i++){
        if (["jpg","jpeg","gif","png","svg","raw","webp","tiff","psd","bmp","heif",].includes(data.uploads[i].ext)) {
          this.getMeta(data.uploads[i].link, this.addImageUpload);
        } 
        else {
          this.setState({otherDocuments: [...this.state.otherDocuments,data.uploads[i]],});
        }
      }
    }
  }

  handleUpload = (fileData) => {
    const formData = new FormData();
    const file = fileData.file;
    formData.append("file", file, file.name);
    const UPLOAD_FILE_DATA_URI = UPLOAD_ENDPOINT;
    this.setState({uploading:true})
    axios
      .post(UPLOAD_FILE_DATA_URI, formData, { crossDomain: true })
      .then((response) => {
        let temp = this.state.uploads;
        temp.push(response.data);
        this.setState({uplaods:temp, uploading:false})
        // Checking if the uploaded file is image or not
        // if it is an image, adding it to the gallery
        if (["jpg","jpeg","gif","png","svg","raw","webp","tiff","psd","bmp","heif",].includes(response.data.ext)) {
          this.getMeta(response.data.link, this.addImageUpload);
        } 
        else {
          this.setState({otherDocuments: [...this.state.otherDocuments,{id: response.data.id,link: response.data.link,name: response.data.name,ext: response.data.ext,},],});
      }
    })
    .catch((e) => {
      console.log(e);
      this.setState({uploading:false});
    });
  };

  // handling files upload
  handleFilesAdd = (files) => {
    files.forEach((file) => {
      const fileData = { file: file, uploaded: false };
      this.handleUpload(fileData);
    });
  };

  // Adding the uploaded images to the gallery
  addImageUpload = (url, width, height) => {
    this.setState({gallery: [...this.state.gallery,{ src: url, height: height, width: width },],});
  };

  removeImage = (url) => {
    let temp = this.state.uploads.filter(y=>y.link!==url);
    let tempGallery = this.state.gallery.filter(x=>x.src!==url);
    this.setState({gallery:tempGallery, uploads:temp})
  };

  getMeta = (url, callBack) => {
    var img = new Image();
    img.onload = function () {
      callBack(url, this.width, this.height);
    };
    img.src = url;
  };

  imageRenderer = ({left, top, key, photo }) => {
    return (
      <Option
        onRemove={this.removeImage}
        value={photo.src}
        // selected={this.state.selected_options.has(photo.src)}
        margin={"2px"}
        left={left}
        top={top}
        photo={photo}
        key={key}
      ></Option>
    );
  };

  handleSubmit = () => {
    let data = {
      data:this.props.question,
      id:this.props.canvas.id
    }
    data['data']['uploads']=this.state.uploads;
    // add a check here if the question is already answered or not
    if(this.state.edit){
      this.props.updateAnswerToCanvas(data);
    }
    else{
      this.props.addAnswerToCanvas(data);
    }
    // OR
    this.setState({uploads:[], gallery:[], otherDocuments:[]})
    this.props.onContinue();
  }

  render(){
    return(
      <Container>
        <Row className="justify-content-md-center">
          <h2 className="col-12 question-h2"  style={{ textAlign: "center" }}>
            {this.props.question.title}
          </h2>
          <h4
            className="col-12 question-h4"
            style={{ textAlign: "center" }}
          >
            {this.props.question.subtitle}
          </h4>
          <Col className='media-drop-wrapper' xs={12} lg={{ span: 6, offset: 3 }}style={{ padding: "0rem", margin:'auto', backgroundColor:'#ebebeb'}}>
            <Dropzone onDrop={this.handleFilesAdd}>
              {({ getRootProps, getInputProps }) => (
                <Container style={style.container} {...getRootProps()}>
                  {this.state.gallery.length > 0 && (
                    <Gallery
                      photos={this.state.gallery}
                      direction={"column"}
                      columns={2}
                      renderImage={this.imageRenderer}
                    ></Gallery>
                  )}
                  <div style={{display: "flex",flexWrap: "wrap",}} >
                    {this.state.otherDocuments.length !== 0 &&
                      this.state.otherDocuments.map((doc, index) => (
                        <div className='other-documents-item'>
                          <div style={{display:"flex",flexDirection:"column",justifyContent:"center", alignItems:"center", }}>
                            <img
                              alt="close"
                              src={close}
                              style={{width: 12,height: 20,alignSelf:"flex-end",}}
                              onClick={() => {
                                let temp = this.state.otherDocuments.filter((x) => x.link !==doc.link);
                                this.setState({otherDocuments: temp,});
                              }}/>
                            <img alt="icon" src={["mp3","wav","wma","aac","m4a","flac",].includes(doc.ext)? audio: ["webm","mpg","mp2","mpeg","mpe","mpv","ogg","mp4","m4p","m4v","avi","wmv",].includes(doc.ext)? video: documentIcon} style={{width: 40,height: 40,}}/>
                        </div>
                        <div style={{width: 80,height: 20,whiteSpace:"break-spaces",textOverflow:"ellipsis",overflow:"hidden",textAlign:"center",}}>
                          {doc.name}
                        </div>
                      </div>
                    ))}
                  </div>
                  <Row className='justify-content-md-center'>
                    <input {...getInputProps()} />
                    {this.state.uploading ? (
                        <SyncLoader css={spinnerStyle}  color={"#000000"}  sizeUnit={"em"} size={"1"} style={{ margin: "auto" }}></SyncLoader>
                    ) : (
                      <Col>
                        <Col xs={{span: 4,offset: 4,}} >
                          <img src={cloudUpload} className={"img-fluid"} alt="upload-icon"></img>
                        </Col>
                        <p style={{fontSize: "1.2rem",fontWeight: "400",marginBottom:"2rem",fontFamily:'sofia-pro'}}>
                          Drag and drop some files here<br/> or
                        </p>
                        {/* <p style={{"borderBottom":"1px solid grey", "lineHeight":"0.1rem"}}><span style={{"padding":"0.1rem 1rem 0.1rem 1rem", "backgroundColor":"rgb(245, 245, 245)"}}>Or</span></p> */}
                        <Button style={{marginTop: "0rem",textTransform:"none",}}>
                          Select Files
                        </Button>
                      </Col>
                    )}
                  </Row>
                </Container>
              )}
            </Dropzone>
          </Col>
          <Col xs={12}>
            <Row className='justify-content-center'>
              <Button
                variant='dark'
                onClick={this.handleSubmit}
              >Continue
              </Button>
            </Row>
          </Col>
        </Row>
      </Container>
    )
  }
}

/**
 *
 * @param {*} state
 * @param {*} ownProps
 * Maps the Redux state to component props
 */
const mapStateToProps = (state, ownProps) => {

  return {
    canvas:state.canvas
  };
};

/**
*
* @param {*} dispatch
* Maps redux actions to component props
*/
const mapDispatchToProps = (dispatch) => {
  return {
    addAnswerToCanvas: (data) => dispatch(addAnswerToCanvas(data)),
    updateAnswerToCanvas: (data) => dispatch(updateAnswerToCanvas(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter((Upload)));