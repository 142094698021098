import { API_ENDPOINT } from "../constants/secrets";
import axios from "axios";

const config = {
    headers: {
        "Access-Control-Allow-Origin": "*",
    },
};

axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

export const loginLocal = (data) => {
    return (dispatch) => {
        const AUTH_LOGIN_LOCAL_URI = `${API_ENDPOINT}/auth/login`;
        axios
            .post(AUTH_LOGIN_LOCAL_URI, data, config)
            .then((response) => {
                if (response.data.status) {
                    dispatch({ type: "LOGIN_INIT", data: false });
                    dispatch({ type: "LOGIN_LOCAL", data: response.data });
                    dispatch(
                        getSecondaryUserInformation({
                            user_id: response.data.user._id,
                        })
                    );
                } else {
                    dispatch({ type: "LOGIN_INIT", data: false });
                    dispatch({
                        type: "LOGIN_ERROR",
                        message: response.data.message,
                    });
                }
            })
            .catch((e) => {
                dispatch({ type: "LOGIN_INIT", data: false });
                dispatch({
                    type: "LOGIN_ERROR",
                    message:
                        "Our systems are encountering problems. Please try again in some time",
                });
            });

        dispatch({ type: "LOGIN_INIT", data: true });
    };
};

export const loginToken = () => {
    return (dispatch) => {
        const AUTH_TOKEN_LOCAL_URI = `${API_ENDPOINT}/auth/jwt`;
        let data = localStorage.getItem("nayaJwtToken");
        if (data !== "") {
            axios
                .get(AUTH_TOKEN_LOCAL_URI, {
                    headers: { Authorization: `Bearer ${data}` },
                })
                .then((response) => {
                    if (response.data.status) {
                        dispatch({
                            type: "LOGIN_TOKEN",
                            data: response.data.user,
                        });
                        dispatch(
                            getSecondaryUserInformation({
                                user_id: response.data.user._id,
                            })
                        );
                    } else {
                        dispatch({
                            type: "LOGIN_ERROR",
                            message: response.data.message,
                        });
                        dispatch({ type: "TOKEN_ERROR" });
                    }
                    dispatch({ type: "LOGIN_INIT", data: false });
                })
                .catch((e) => {
                    dispatch({ type: "LOGIN_INIT", data: false });
                    dispatch({
                        type: "LOGIN_ERROR",
                        message:
                            "Our systems are encountering problems. Please try again in some time",
                    });
                });

            dispatch({ type: "LOGIN_INIT", data: true });
        } else {
            dispatch({ type: "LOGIN_INIT", data: false });
        }
    };
};

export const signOut = () => {
    return (dispatch) => {
        localStorage.setItem("nayaJwtToken", undefined);
        dispatch({ type: "SIGN_OUT" });
    };
};

export const changeProfilePic = (data) => {
    return (dispatch) => {
        const CHANGE_PROFILE_PIC_URL = `${API_ENDPOINT}/api/userprefs`;
        axios
            .put(CHANGE_PROFILE_PIC_URL, data, { crossDomain: true })
            .then((response) => {
                if (response.data.status) {
                    dispatch({ type: "CREATE_PROFILE_PIC" });
                    dispatch(
                        getSecondaryUserInformation({ user_id: data.user_id })
                    );
                } else {
                    dispatch({
                        type: "ERROR_CREATE_PROFILE_PIC",
                        message: response.data.message,
                    });
                }
            })
            .catch((e) => {
                console.log(e);
                dispatch({
                    type: "ERROR_CREATE_PROFILE_PIC",
                    message:
                        "Our systems are experiencing issues. Please try again after some time",
                });
            });
    };
};

export const getProfilePic = (data) => {
    return (dispatch) => {
        const GET_PROFILE_PIC_URL = `${API_ENDPOINT}/api/userprefs`;
        axios
            .get(GET_PROFILE_PIC_URL, { params: data }, { crossDomain: true })
            .then((response) => {
                if (response.data.status) {
                    dispatch({
                        type: "SET_PROFILE_PIC",
                        link: response.data.data.options[0].value,
                    });
                } else {
                    dispatch({
                        type: "ERROR_GET_PROFILE_PIC",
                        message: response.data.message,
                    });
                }
            })
            .catch((e) => {
                dispatch({
                    type: "ERROR_GET_PROFILE_PIC",
                    message:
                        "Our systems are experiencing issues. Please try again after some time",
                });
            });
    };
};

export const changeProfileName = (data) => {
    return (dispatch) => {
        const CHANGE_PROFILE_NAME_URL = `${API_ENDPOINT}/api/userprefs`;
        axios
            .put(CHANGE_PROFILE_NAME_URL, data, { crossDomain: true })
            .then((response) => {
                console.log(response);
                if (response.data.status) {
                    dispatch({ type: "CREATE_PROFILE_NAME" });
                    dispatch(
                        getSecondaryUserInformation({ user_id: data.user_id })
                    );
                } else {
                    dispatch({
                        type: "ERROR_CREATE_PROFILE_NAME",
                        message: response.data.message,
                    });
                }
            })
            .catch((e) => {
                dispatch({
                    type: "ERROR_CREATE_PROFILE_NAME",
                    message:
                        "Our systems are experiencing issues. Please try again after some time",
                });
            });
    };
};

export const getProfileName = (data) => {
    return (dispatch) => {
        const GET_PROFILE_NAME_URL = `${API_ENDPOINT}/api/userprefs`;
        axios
            .get(GET_PROFILE_NAME_URL, { params: data }, { crossDomain: true })
            .then((response) => {
                if (response.data.status) {
                    dispatch({
                        type: "SET_PROFILE_NAME",
                        name: response.data.data.options[0].value,
                    });
                } else {
                    dispatch({
                        type: "ERROR_GET_PROFILE_NAME",
                        message: response.data.message,
                    });
                }
            })
            .catch((e) => {
                dispatch({
                    type: "ERROR_GET_PROFILE_NAME",
                    message:
                        "Our systems are experiencing issues. Please try again after some time",
                });
            });
    };
};

export const getSecondaryUserInformation = (data) => {
    return (dispatch) => {
        let pic_data = {
            ...data,
            key: "PROFILE_PIC",
        };
        dispatch(getProfilePic(pic_data));

        let name_data = {
            ...data,
            key: "PROFILE_NAME",
        };
        dispatch(getProfileName(name_data));
    };
};

export const signUpLocal = (data) => {
    return (dispatch) => {
        //Create Answer
        const AUTH_SIGNUP_LOCAL_URI = `${API_ENDPOINT}/auth/register`;
        data.user_type = "USER";
        axios
            .post(AUTH_SIGNUP_LOCAL_URI, data, config)
            .then((response) => {
                if (response.data.status) {
                    dispatch({ type: "SIGN_UP_LOCAL", data: response.data });
                    dispatch({ type: "LOGIN_INIT", data: false });
                } else {
                    dispatch({
                        type: "SIGNUP_ERROR",
                        message: response.data.message,
                    });
                    dispatch({ type: "LOGIN_INIT", data: false });
                }
            })
            .catch((e) => {
                dispatch({ type: "LOGIN_INIT", data: false });
                dispatch({
                    type: "SIGNUP_ERROR",
                    message:
                        "Our systems are encountering problems. Please try again in some time",
                });
            });
        dispatch({ type: "LOGIN_INIT", data: true });
    };
};

export const getUser = (data) => {
    return (dispatch) => {
        //Create Answer
        const AUTH_GET_USER_URI = `${API_ENDPOINT}/api/user`;
        axios
            .get(AUTH_GET_USER_URI, { params: data }, config)
            .then((response) => {
                if (response.status === 200) {
                    dispatch({ type: response.data.type, data: response.data });
                }
            })
            .catch((e) => {
                console.log("Error");
                // dispatch({ type: 'SIGNUP_ERROR', message: "Our systems are encountering problems. Please try again in some time"})
            });
    };
};

// if the user is logged in
export const addCanvasToUser = (data) => {
    return (dispatch) => {
        //Create Answer
        const AUTH_SIGNUP_LOCAL_URI = `${API_ENDPOINT}/api/user/canvas`;
        axios
            .post(AUTH_SIGNUP_LOCAL_URI, data, config)
            .then((response) => {
                // dispatch({ type: "SIGN_UP_LOCAL", data: response.data });
            })
            .catch((e) => {});
        // dispatch({ type: "SIGN_UP_LOCAL", data: {} });
    };
};

// if the user isn't logged in
export const addCanvasToUserProfile = (data) => {
    return (dispatch) => {
        //Create Answer
        const AUTH_SIGNUP_LOCAL_URI = `${API_ENDPOINT}/api/user/canvas`;
        axios
            .post(AUTH_SIGNUP_LOCAL_URI, data, config)
            .then((response) => {
                console.log("Canvas added to user successfully!");
            })
            .catch((e) => {});
    };
};

// redirect to passport
export const signUpInGoogle = () => {
    // TEMPORARY - STAGING ONLY
    // let API_ENDPOINT = "https://naya-app-backend-staging-ziylmcndpq-ue.a.run.app"
    window.location.href = `${API_ENDPOINT}/auth/google?callbackURL=${window.location.protocol}//${window.location.host}`
}

export const signUpInFacebook = () => {
    // TEMPORARY - STAGING ONLY
    // let API_ENDPOINT = "https://naya-app-backend-staging-ziylmcndpq-ue.a.run.app"
    window.location.href = `${API_ENDPOINT}/auth/facebook?callbackURL=${window.location.protocol}//${window.location.host}`
}

export const setAuthCallback = (href) => {
    return (dispatch) => {
        dispatch({ type: "AUTH_CALLBACK", data: href });
    };
};
