import React from 'react';
import {Row, Col, Modal} from 'react-bootstrap';
import maximize from '../../images/icons/maximize.svg';
import EditTile from '../editTile/EditTile';
import Tool from '../tools/Tools';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CanvasDraw from "react-canvas-draw";
import {createAnnotation} from '../../actions/annotationActions';
import { useScrollTrigger } from '@material-ui/core';

class CanvasTile extends React.Component{
  constructor(props){
    super(props);
    this.replyToData = {
      text: undefined,
      image: undefined,
      type:''
    }
    this.imgContainer = React.createRef();
  }
  state = {
    maximizeImage: false,
    edit:false,
    tool:false,
    clientWidth: 100,
    clientHeight: undefined,
    showActions:true,
    showImage:false,
    dimensions:{}, 
    canvasTileClass:'',
    aspectRatio: 0,
    uploadIsImage: false,
  }

  componentWillReceiveProps() {
    console.log(this.props)


    //check if upload is image
    if (this.props.subtype === 'upload' && this.props.data) {
      if ([
        "jpg",
        "jpeg",
        "gif",
        "png",
        "svg",
        "raw",
        "webp",
        "tiff",
        "psd",
        "bmp",
        "heif",
      ].includes(this.props.data.ext.split(".")[this.props.data.ext.split(".").length - 1].toLowerCase())) {
        this.setState({ ...this.state, uploadIsImage: true })
      }
    }

    // find aspect ratios for images on load for SEARCH and UPLOAD
    if ((this.props.subtype === 'upload' && this.props.data)) {
      const img = document.createElement('img')
      // console.log('uploadIsImage: ' + this.state.uploadIsImage)
      // console.log('contentType: ' + this.props.contentType)
      // console.log('uploadData: ' + this.props.data)

      img.onload = () => {
        const dimensions = {
          height: img.naturalHeight,
          width: img.naturalWidth
        }
        this.setState({ aspectRatio: dimensions.width / dimensions.height });
      }

      switch (this.props.subtype) {
        // case 'SEARCH':
        //   img.src = this.props.data
        //   break;
        case 'upload':
          img.src = this.props.data.link
          break;
        default:
          break;
      }
      // console.log('img: ' + img)
    }
  }
  componentDidUpdate() {
    if (this.loadableCanvas && this.props.data.sketchData) {
      this.loadableCanvas.loadSaveData(this.props.data.sketchData);
    }
  }


  getMeta = (url, callBack) => {
    if(!this.state.showImage){
      var img = new Image();
      img.onload = function() {
        callBack(url, this.width, this.height);
      };
      img.src = url;
    }
  };

  setDimensions = (url, width, height) => {
    if(width>450 && height<250){
      this.setState({canvasTileClass:'canvas-tile-2x'})
    }
    this.setState({dimensions:{width:width, height:height}, showImage:true})
  }

  toggleEditModal = () => {
    this.setState({edit:false})
  }

  toggleTool = () => {
    this.setState({tool:false})
  }

  renderTileData = () => {
    switch(this.props.type){
      case 'QUESTION':
        return this.renderQuestion();
      case 'UPLOAD':
        return this.renderUploads();
      case 'VISUAL_MOODBOARD':
        return this.renderVisualMoodBoard();
      case 'TEXT_MOODBOARD':
        return this.renderTextMoodBoard();
      case 'UPLOAD_PINTEREST':
        if(this.props.subtype==="upload"){
          if(this.props.data!==undefined){
            if(this.props.data.link){
            this.getMeta(this.props.data.link, this.setDimensions);
            }
          }
        }
        return this.renderUploadPinterest();
      case 'BUDGET':
        return this.renderBudget();
      case 'SKETCH':
        return this.renderSketch();
      case 'STORY':
        return this.renderAdditionalInfo();
      case 'STATIC-TILE':
        return this.renderStaticTile();
      case 'SHIPPING':
        return this.renderShipping();
      case 'DIMENSIONS':
        return this.renderDimensions();
      case 'ADDITIONAL_TEXT':
        return this.renderAdditionalFields();
      case 'TRACKING':
        return this.renderTracking();
      default:
        return <h1>default</h1>
    }
  }

  renderBudget= () => {
    if(this.props.data===undefined){
      return(
        <div className="question-tile" onClick={()=>{this.setState({tool:true})}}>
          <h5>Budget</h5>
          <h4 style={{fontSize:'10rem', textAlign:'center', fontWeight:100}}>+</h4>
        </div>
      )
    }
    this.replyToData["type"] = "TEXT"
    this.replyToData["text"] = "Budget"
    return(
      <div className="question-tile" style={{width:'100%'}}>
      <h5>Budget</h5>
        <div>
          <p style={{textAlign:'center', fontSize:16, wordBreak:'break-all', marginBottom:0, marginTop:'2rem'}}>Minimum</p>
          <p style={{textAlign:'center', fontSize:18, wordBreak:'break-all', fontWeight:'bold'}}>$ {this.props.budget.minimum}</p>
          <p style={{textAlign:'center', fontSize:16, wordBreak:'break-all', marginBottom:0}}>Maximum</p>
          <p style={{textAlign:'center', fontSize:18, wordBreak:'break-all', fontWeight:'bold'}}>$ {this.props.budget.maximum}</p>
        </div>
      </div>
    )
  }

  renderSketch = () => {
    if(this.props.data===undefined || this.props.data===null){
      return(
        <div className="question-tile" onClick={()=>{this.setState({tool:true})}}>
          <h5>Sketch</h5>
          <h4 style={{fontSize:'10rem', textAlign:'center', fontWeight:100}}>+</h4>
        </div>
      )
    }
    else if(this.props.data.sketchData===undefined){
      return(
        <div className="question-tile" onClick={()=>{this.setState({tool:true})}}>
          <h5>Sketch</h5>
          <h4 style={{fontSize:'10rem', textAlign:'center', fontWeight:100}}>+</h4>
        </div>
      )
    }
    else{
      this.replyToData["type"] = "SKETCH"
      this.replyToData["image"] = this.props.data.sketchData
      return(
        <Col xs={12} ref={ (divElement) => this.divElement = divElement} style={{"minHeight":"100%", display:'flex', alignItems:'center'}} className="tile-content-wrapper">
          {this.props.data.sketchBg && <img src={this.props.data.sketchBg} 
            ref={this.imgContainer} 
            alt = ""
            onLoad={this.handleImageLoaded}
            style={{"maxWidth":"100%"}}></img>}
            <CanvasDraw className="canvas-sketch" 
              disabled 
              canvasWidth={200}
              canvasHeight={200} 
              backgroundColor={"transparent"}
              ref={canvasDraw => (this.loadableCanvas = canvasDraw)} 
              saveData={this.props.data.sketchData}
              imgSrc={this.props.data.sketchBg} />
          </Col>
      )
    }
  }

  handleImageLoaded = (e) => {
    let height =
      (this.imgContainer.current.clientHeight *
          this.divElement.clientWidth) /
      this.imgContainer.current.clientWidth;
    this.setState({
      clientWidth: this.divElement.clientWidth,
      clientHeight: height,
    });
  };

  verfiyDimensionsData = (data) => {
    let returnValue = false
    if(data)
    {
      if("length" in data && "width" in data && "height" in data && "units" in data)
      {
        returnValue = true
      }
    }
    return returnValue
  }
  renderDimensions = (e) => {

    if(!this.props.data || !this.verfiyDimensionsData(this.props.data))
    {
      return(
        <div className="question-tile" onClick={()=>{this.setState({edit:true})}}>
          <h5>{"Dimensions"}</h5>
          <h4 style={{fontSize:'10rem', textAlign:'center', fontWeight:100}}>+</h4>
        </div>
      )
    }
    else{
      
      let length = this.props.data["length"]
      let width =  this.props.data["width"]
      let height =  this.props.data["height"]
      let units =  this.props.data["units"]

      this.replyToData["type"] = "TEXT"
      this.replyToData["text"] = `<h5>Dimensions:</h5> <p>Length: ${length}${units}</p> <p>Width: ${width}${units} </p> <p>Height: ${height}${units}</p>`;

      return <div className="question-tile" style={{"textAlign":"center"}}>
        <h5>{"Dimensions"}</h5>
        <p style={{"textAlign":"center"}}>Length: {length} {units}</p>
        <p style={{"textAlign":"center"}}>Width: {width} {units}</p>
        <p style={{"textAlign":"center"}}>Height: {height} {units}</p>
    </div>
    }
  }

  renderShipping = (e) => {
    if(!this.props.data || !("city" in this.props.data))
    {
      return(
        <div className="question-tile" onClick={()=>{this.setState({edit:true})}}>
          <h5>{"Shipping"}</h5>
          <h4 style={{fontSize:'10rem', textAlign:'center', fontWeight:100}}>+</h4>
        </div>
      )
    }
    else{
      let recepient_name = this.props.data["recepient_name"]
      let apt_num =  this.props.data["widapt_numth"]
      let state_code =  this.props.data["state_code"]
      let street_name =  this.props.data["street_name"]
      let city =  this.props.data["city"]
      let zip =  this.props.data["zip"]

      this.replyToData["type"] = "TEXT"
      this.replyToData["text"] = `<h5>Address:</h5> <p>${recepient_name? recepient_name: ""}</p> <p>${street_name? street_name: ""}, ${apt_num? apt_num: ""}</p> <p>${city? city:""}, ${state_code? state_code: ""} ${zip? zip:""}</p>`;

      return <div className="question-tile">
        <h5>{"Shipping"}</h5>
        <p style={{"textAlign":"center"}}>{recepient_name}</p>
        <p style={{"textAlign":"center"}}>{street_name}</p>
        <p style={{"textAlign":"center"}}>{apt_num}</p>
        <p style={{"textAlign":"center"}}>{city}</p>
        <p style={{"textAlign":"center"}}>{state_code}</p>
        <p style={{"textAlign":"center"}}>{zip}</p>
      </div>
    }
  }

  renderTracking = (e) => {
    let carrier = this.props.data["carrier"];
    let tracking_number = this.props.data["tracking_number"];
    return(
      <div className="question-tile" onClick={()=>window.open(this.props.url, "_blank")} >
        <h5>{"Tracking"}</h5>
        <p style={{"textAlign":"center"}}>{carrier}</p>
        <p style={{"textAlign":"center"}}>{tracking_number}</p>
      </div>
    )
  }

  renderAdditionalFields = (e) => {
    if(!this.props.data)
    {
      return(
        <div className="question-tile" onClick={()=>{this.setState({edit:true})}}>
          <h5>{"Additional Fields"}</h5>
          <h4 style={{fontSize:'10rem', textAlign:'center', fontWeight:100}}>+</h4>
        </div>
      )
    }
    else{
      this.replyToData["type"] = "TEXT"
      this.replyToData["text"] = `<h5>${this.props.data["description"]}</h5><p>${this.props.data["text"]}</p>`;
      return <div className="question-tile">
        <h5>{this.props.data["description"]}</h5>
        <p style={{"padding":"1rem"}}>{this.props.data["text"]}</p>
    </div>
    }
  }

  renderQuestion = () => {
    if(this.props.data===undefined){
      return(
        <div className="question-tile" onClick={()=>{this.setState({edit:true})}}>
          <h5>{this.props.question.title}</h5>
          <h4 style={{fontSize:'10rem', textAlign:'center', fontWeight:100}}>+</h4>
        </div>
      )
    }
    else{
      if(this.props.question.subType==='MCQ'){
        this.replyToData["type"] = "TEXT_IMG"
        this.replyToData["text"] = this.props.data.title;
        this.replyToData["image"] = this.props.data.selected_options[0].image;
        return(
          <div className="question-tile">
            <h5>{this.props.data.title}</h5>
            <div className="options-container">
              {
                this.props.data.selected_options.map((option, index) => (
                  <div key={index} className="option">
                    <img src={option.image} alt="option" style={{width:60}}></img>
                    <p style={{textAlign:'center', fontSize:"0.7rem", wordBreak:'break-word', marginBottom: option.type==="OTHER_OPTION" && option.other_text && 0}}>{option.type!=="OTHER_OPTION" && !option.other_text && option.text}</p>
                    {
                      option.type==="OTHER_OPTION" && option.other_text && <p style={{textAlign:'center', fontSize:14, wordBreak:'break-all'}}>{option.other_text}</p>
                    }
                  </div>
                ))
              }
            </div>
          </div>
        )
      }
      else if(this.props.question.subType==='TEXT'){
        this.replyToData["type"] = "TEXT"
        this.replyToData["text"] = this.props.data.title;
        return(
          <div className="question-tile">
            <h5>{this.props.data.title}</h5>
            <h6 style={{paddingLeft:'1rem', paddingRight:'1rem', fontSize:'0.8rem'}}>{this.props.data.text}</h6>
          </div>
        )
      }
      else if(this.props.question.subType==='MULTIPLE_SECTION'){
        this.replyToData["type"] = "TEXT"
        this.replyToData["text"] = this.props.data.title;
        let sections = Object.keys(this.props.data.selected_sections)
        return(
          <div className="question-tile">
            <h5>{this.props.data.title}</h5>
            {
              sections.map((section, index) => (
                <div key={index}>
                  <h6 style={{paddingLeft:'1rem', paddingRight:'1rem', textAlign:'center', fontWeight:'bold', fontSize: "0.9rem"}}>{section}</h6>
                  <div className="options-container">
                  {
                    this.props.data.selected_sections[section].map((option, index) => (
                      <div key={index} className="option">
                        <img src={option.image} alt="option" style={{width:60}}></img>
                        <p style={{textAlign:'center', fontSize:14, wordBreak:'break-all', marginBottom: option.type==="OTHER_OPTION" && option.other_text && 0}}>{option.type!=="OTHER_OPTION" && !option.other_text && option.text}</p>
                        {
                          option.type==="OTHER_OPTION" && option.other_text && <p style={{textAlign:'center', fontSize:14, wordBreak:'break-all'}}>{option.other_text}</p>
                        }
                      </div>
                    ))
                  }
                </div>
                </div>
              ))
            }
          </div>
        )
      }
    }
  }

  renderAdditionalInfo = () => {
    if(!this.props.data){
      return(
        <div className="question-tile" onClick={()=>{this.setState({edit:true})}}>
          <h5>Additional Information</h5>
          <h4 style={{fontSize:'10rem', textAlign:'center', fontWeight:100}}>+</h4>
        </div>
      )
    }
    else{
      this.replyToData["type"] = "TEXT"
        this.replyToData["text"] = this.props.data;
        return(
          <div className="question-tile">
            <h5>Additional Information</h5>
            <h6 style={{paddingLeft:'1rem', paddingRight:'1rem', fontSize:'0.8rem'}}>{this.props.data}</h6>
          </div>
        )
    }
  }

  renderUploads = () => {
    if(this.props.data===undefined){
      return(
        <div className="question-tile" onClick={()=>{this.setState({edit:true})}}>
          <h5>{this.props.question.title}</h5>
          <h4 style={{fontSize:'10rem', textAlign:'center', fontWeight:100}}>+</h4>
        </div>
      )
    }
    else{
      this.replyToData["type"] = "IMAGE"
      this.replyToData["image"] = this.props.data.link;
      return (
        <div className="image-icons-container">
          <img src={this.props.data.link} alt="upload" className="canvas-images"></img>
          <div className="icons-container">
            <img src={maximize} alt="maximize" style={{width:'2rem', cursor:'pointer'}} onClick={()=>{this.setState({maximizeImage:true})}}></img>
          </div>
        </div>
      )
    }
  }

  renderVisualMoodBoard = () => {
    if(this.props.data===undefined){
      return(
        <div className="question-tile" onClick={()=>{this.setState({edit:true})}}>
          <h5>{this.props.question.title}</h5>
          <h4 style={{fontSize:'10rem', textAlign:'center', fontWeight:100}}>+</h4>
        </div>
      )
    }
    else{
      this.replyToData["type"] = "IMAGE"
      this.replyToData["image"] = this.props.data;
      return (
        <div className="image-icons-container">
          <img src={this.props.data} alt="upload" className="canvas-images-visual-moodboard img-fluid"></img>
          <div className="icons-container">
            <img src={maximize} alt="maximize" style={{width:'2rem', cursor:'pointer'}} onClick={()=>{this.setState({maximizeImage:true})}}></img>
          </div>
        </div>
      )
    }
  }


  renderTextMoodBoard = () => {
    if(this.props.data===undefined){
      return(
        <div className="question-tile" onClick={()=>{this.setState({edit:true})}}>
          <h5>{this.props.question.title}</h5>
          <h4 style={{fontSize:'10rem', textAlign:'center', fontWeight:100}}>+</h4>
        </div>
      )
    }
    else{
      this.replyToData["type"] = "TEXT"
      this.replyToData["text"] = this.props.data.title;
      return(
        <div className="question-tile">
          <h5>{this.props.data.title}</h5>
          <div className="options-container">
            {
              this.props.data.selected_options.map((option, index) => (
                <div key={index} className="option">
                  <p style={{textAlign:'center', fontSize:14, wordBreak:'break-all'}}>{option}</p>
                </div>
              ))
            }
          </div>
        </div>
      )
    }
  }

  renderUploadPinterest = () => {
    if(this.props.data===undefined){
      return(
        <div className="question-tile" onClick={()=>{this.setState({edit:true})}}>
          <h5>{this.props.question.title}</h5>
          <h4 style={{fontSize:'10rem', textAlign:'center', fontWeight:100}}>+</h4>
        </div>
      )
    }
    else{
      if(this.props.subtype==='upload'){
        this.replyToData["type"] = "IMAGE"
        this.replyToData["image"] = this.props.data.link;
        return (
          <div className="image-icons-container">
            {
              this.state.showImage && (
                <>
                <img src={this.props.data.link} alt="upload" className="canvas-images img-fluid"></img>
                <div className="icons-container">
                  <img src={maximize} alt="maximize" style={{width:'2rem', cursor:'pointer'}} onClick={()=>{this.setState({maximizeImage:true})}}></img>
                </div>
                </>
              )
            }
        </div>
        )
      }
      else{
        this.replyToData["type"] = "TEXT"
        this.replyToData["text"] = this.props.data.title;
        // this.replyToData["image"] = this.props.question.selected_options[0].image;
        return(
          <div className="question-tile">
            <h5>{this.props.data.title}</h5>
            <div className="options-container" style={{flexDirection:'column'}}>
              {
                this.props.data.links.map((link, index) => (
                  <div key={index} className="option" style={{width:'100%'}}>
                    <p style={{textAlign:'center', fontSize:14, wordBreak:'break-all'}}>{link}</p>
                  </div>
                ))
              }
            </div>
          </div>
        )
      }
    }
  }

  renderStaticTile = () => {
    return (
      <div className="question-tile">
        <h5>{this.props.data.title}</h5>
        <h6 style={{paddingLeft:'1rem', paddingRight:'1rem', fontSize:'0.8rem'}}>{this.props.data.text}</h6>
      </div>
    )
  }

  handleAnnotate = (e) => {
    e.preventDefault();
    if (this.replyToData.image) {
        this.props.createAnnotation({
            annotationSrc: this.replyToData.image,
        });
    }
    this.props.history.push("/annotate");
  };

  handleReplyTo = () => {
    this.props.replyToFunction({
        ...this.replyToData,
    });
  } ;


  showActions = () => {
    if(this.props.type==='SKETCH'){
      if(this.props.data===undefined || this.props.data===null){
        return false;
      }
      else if(this.props.data.sketchData===undefined){
        return false;
      }
      else{
        return true;
      }
    }
    else if(this.props.data===undefined){
      return false;
    }
    else{
      return true;
    }
  }

  checkIsAdditionalText = () => {
    if (this.props.type === 'ADDITIONAL_TEXT' && this.props.data) {
      if (this.props.data.text.length > 550) {
        return true
      }
    }
    return false
  }

  setXLColLength = () => {
    if (this.checkIsAdditionalText())
      return '12'
    if ((this.props.type === 'UPLOAD_PINTEREST') || this.state.uploadIsImage) {
      if (this.state.aspectRatio > 1.65) {
        if (this.state.aspectRatio > 2.2) {
          return '12'
        }
        return '6'
      }
    }
    return '3'
  }

  setLGColLength = () => {
    if (this.checkIsAdditionalText())
      return '12'
    if ((this.props.type === 'UPLOAD_PINTEREST') || this.state.uploadIsImage) {
      if (this.state.aspectRatio > 1.65) {
        if (this.state.aspectRatio > 2.2) {
          return '12'
        }
        return '8'
      }
    }
    return '4'
  }

  render(){
    // console.log('-----setLGColLength()-----')
    // console.log(this.setLGColLength())
    // console.log('-----setXLColLength()-----')
    // console.log(this.setXLColLength())

    return(
      <Col xs={12} md={this.checkIsAdditionalText() ? '12' : '6'} lg={this.setLGColLength()} xl={this.setXLColLength()} className={`tile-action-container ${this.state.canvasTileClass}`} style={{padding:'0.5rem', marginTop:'1.5rem'}}>
        {
          this.state.edit && (
            <EditTile isOpen={this.state.edit} onContinue={this.toggleEditModal} question={this.props.question} editType={this.props.type}/>
          )
        }
        {
          this.state.tool && (
            <Tool isOpen={this.state.tool} onContinue={this.toggleTool} type={this.props.type}/>
          )
        }
      <Col xs={12} className='canvas-tile'>
          <Row>{this.renderTileData()}</Row>
      </Col>
      {
        this.props.type!=='STATIC-TILE' && this.showActions() && (
          <Col xs={12} className='action-handler'>
            <Row className='justify-content-end'>
              {this.props.data && <div style={{marginRight:'1rem', fontWeight:'bold', cursor:'pointer'}} onClick={this.handleReplyTo}>reply</div>}
              {this.props.data && <div style={{fontWeight:'bold', cursor:'pointer'}} onClick={()=>{
                if(['BUDGET', 'SKETCH'].includes(this.props.type)){
                  this.setState({tool:true})
                }
                else{
                  this.setState({edit:true})
                }
              }}>edit</div>}
              {this.replyToData.type === "IMAGE" ? (
                <Col>
                    <div style={{fontWeight:'bold', cursor:'pointer'}} onClick={this.handleAnnotate}>
                        annotate
                    </div>
                </Col>
            ) : null}
            </Row>
          </Col>
        )
      }

      {/* maximize modal */}
      <Modal
        size='xl'
        show={this.state.maximizeImage}
        onHide={() =>{this.setState({maximizeImage:false})}}
        aria-labelledby='example-modal-sizes-title-xl'
        id='instructions-modal'
      >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body style={{ overflowY: "auto" }}>
        <Row className='justify-content-center'>
          {
            this.props.type==='VISUAL_MOODBOARD' ? (
              <img
                  src={this.props.data}
                  style={{
                      maxWidth: "100%",
                      height: 'auto',
                  }}
                  alt='lightbox'
              ></img>
            ) : (
              <img
                  src={this.props.data !==undefined && this.props.data !==null && this.props.data.link}
                  style={{
                      maxWidth: "100%",
                      height: 'auto',
                  }}
                  alt='lightbox'
              ></img>
            )
          }
        </Row>
      </Modal.Body>
    </Modal>
      </Col>
    )
  }
}

/**
 * 
 * @param {*} state
 * Maps Redux state to component props 
 */
const mapStateToProps = (state) => {
  let budget = state.canvas.budget;
  if (budget === undefined || budget === null || Object.keys(budget).length === 0) {
      budget=undefined
  }

  return {
      // Budget specific 
      budget: budget,
      sketch:state.canvas.sketch

  }
}
/**
* Maps Redux actions to component props
* @param {*} dispatch
*  
*/
const mapDispatchToProps = (dispatch) => {

  return {
    createAnnotation: (data) => dispatch(createAnnotation(data)),
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CanvasTile))
