import React, { Component } from 'react'
import { connect } from 'react-redux'   
import {Row, Col, Image} from 'react-bootstrap'
import { withRouter } from 'react-router-dom'
import {setCanvas} from '../../actions/accountActions'
import '../../stylesheets/account.css'

class CanvasWidget extends Component{

    timeDifference(current, previous) {

        var msPerMinute = 60 * 1000;
        var msPerHour = msPerMinute * 60;
        var msPerDay = msPerHour * 24;
        var msPerMonth = msPerDay * 30;
        var msPerYear = msPerDay * 365;
    
        var elapsed = current - previous;
    
        if (elapsed < msPerMinute) {
             return Math.round(elapsed/1000) + ' s ago';   
        }
    
        else if (elapsed < msPerHour) {
             return Math.round(elapsed/msPerMinute) + ' mins ago';   
        }
    
        else if (elapsed < msPerDay ) {
             return Math.round(elapsed/msPerHour ) + ' hr ago';   
        }
    
        else if (elapsed < msPerMonth) {
            return '~' + Math.round(elapsed/msPerDay) + ' d ago';   
        }
    
        else if (elapsed < msPerYear) {
            return '~' + Math.round(elapsed/msPerMonth) + ' m ago';   
        }
    
        else {
            return '~' + Math.round(elapsed/msPerYear ) + ' y ago';   
        }
    }

    handleClick = (e) =>
    {
        e.preventDefault()
        localStorage.setItem('nayaCanvasID', this.props.data._id)
        // this.props.setCanvas(this.props.data._id)
        this.props.history.push('canvas')
    }

    render()
    {
        let visual_moodboard = this.props.data.moodboards.filter(x => !x.isText)
        if(visual_moodboard.length>0)
        {
            visual_moodboard = visual_moodboard[0].options
        }

        let all_images = []
        try {
            all_images = [...this.props.data.uploads.map(upload => upload.link)]
        } catch (error) {
            all_images = all_images
        }

        try {
            all_images = [...visual_moodboard]
        } catch (error) {
            all_images = all_images
        }
        let src = "https://via.placeholder.com/300"
        if(all_images.length> 0 )
        {
            src = all_images[Math.floor(Math.random() * all_images.length)]
        }

        let date = new Date(this.props.data.updatedAt)
        let now = new Date()
        let diff = this.timeDifference(now, date)

        return (
            <Col xs={12} lg={4} xl={3} className="canvas-widget" onClick={this.handleClick} style={{"cursor":"pointer"}}>
                <Row>
                    <Col xs={12} className="img-container">
                        <Image src={src}></Image>
                    </Col>
                    <Col xs={12} style={{"textAlign": "center"}}><h5>{this.props.data.name? this.props.data.name: "Canvas"}</h5></Col>
                    <Col xs={12} style={{"textAlign": "center", "color": "grey", fontWeight:400, fontSize:'0.9rem'}}>Last updated {diff}</Col>
                </Row>
            </Col>
        )
    }
}



const mapStateToProps = (state) => {
    return { 
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    
    return { 
        setCanvas: (data) => dispatch(setCanvas(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CanvasWidget))