import React, { Component } from 'react'
import { connect } from 'react-redux'   
import {Container, Row, Col,Image, Button} from 'react-bootstrap'
import { withRouter } from 'react-router-dom'
import {loginToken} from '../../actions/authActions'
import CanvasManager from './CanvasManager'
import '../../stylesheets/account.css'
import NavBar from '../utilities/Navbar'
import ProfilePic from './ProfilePic'
import ProfileDetails from './ProfileDetails'
import placeholder from '../../images/logo_placeholder.png';

class Account extends Component{
    
    componentDidMount()
    {
        if(localStorage.getItem('nayaJwtToken'))
        {
            this.props.loginToken(localStorage.getItem('nayaJwtToken'))
        }
        else
        {
            this.props.history.push('/login')
        }
    }

    renderCanvasManager = () =>
    {
        let CanvasManagerApp = <div></div>
        if(this.props.user !== undefined && this.props.user.user_canvas != undefined && this.props.user.user_canvas.length>0)
        {
                CanvasManagerApp =  <CanvasManager canvas_ids={this.props.user.user_canvas}></CanvasManager>
        }
        else{
            return(
                <Container style={{paddingTop:'5rem'}}>
                    <Col style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                        <Image src={placeholder} fluid style={{width:'10rem', height:'10rem', borderRadius:'50%'}}/>
                        <h5 style={{fontFamily:'sofia-pro', marginTop:'1rem', fontWeight:'bold'}}>Have an idea?</h5>
                    </Col>
                </Container>
            )
        }

        return CanvasManagerApp

    }

    render()
    {
        if(this.props.user.loggedIn)
        {
            return (<Container fluid>
                <NavBar></NavBar>
                <Container id="account">
                    <Row>
                        {/* <h2 className="col-12">Welcome!</h2> */}
                        <Col xs={12}>
                            <Row >
                                <Col lg={{span:8, offset:2}} style={{"textAlign":"center"}}>
                                    {/* <Image src={userIcon} fluid></Image> */}
                                    <Row className="align-items-center">
                                        <Col xs={12} lg={6}>
                                            <ProfilePic></ProfilePic>
                                        </Col>
                                        <Col xs={12} lg={6}>
                                            <ProfileDetails></ProfileDetails>
                                        </Col>
                                    </Row>
                                    {/* <p>{this.props.user.email.split("@")[0]}</p> */}
                                </Col>
                            </Row>
                        </Col>
                        {/* <hr style={{"height": "1px", "backgroundColor":"black", "width": "100%"}}></hr> */}
                        {this.renderCanvasManager()}
                    </Row>
                    <Row style={{justifyContent:'center', marginTop:'1.5rem'}}>
                        <Button 
                            style={{backgroundColor:'#fff', padding: '0.75rem 3rem', color:'#000', border:'1px solid #000', fontFamily:'sofia-pro', borderRadius:0}}
                            onClick={()=>{this.props.history.push('/loading')}}>
                                <span>+</span> Create New Project
                        </Button>
                    </Row>
                </Container>
                </Container>
            )
        }
        else{
            return <p>Logging In</p>
        }
    }
}



const mapStateToProps = (state) => {
    return { 
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    
    return { 
        loginToken: (data) => dispatch(loginToken(data)), 
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Account))