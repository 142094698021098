import { API_ENDPOINT } from  '../constants/secrets';
import axios from 'axios'

const config = { headers: {"Access-Control-Allow-Origin": "*"}}
axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

const createCanvas = () => {
  return (dispatch) => {
    //Get UI data and store in database
    const CREATE_CANVAS_URI = `${API_ENDPOINT}/api/canvas`
    axios.post(CREATE_CANVAS_URI, config)
      .then(response => {
        console.log("created canvas:", response.data)
        dispatch({ type: 'CREATE_CANVAS', id: response.data.id, canvas: response.data.canvas });
      }).catch(e => {
        dispatch({ type: 'ERROR_CREATE_CANVAS'});
      })
  }
}

const getCanvas = (data) => {
  console.log("get canvas called:",localStorage.getItem('nayaCanvasID'))
  return (dispatch) => {
    const GET_CANVAS_DATA_URI = `${API_ENDPOINT}/api/canvas`
    if(data===undefined && localStorage.getItem('nayaCanvasID')){
      data = {id: localStorage.getItem('nayaCanvasID')}
    }

    axios.get(GET_CANVAS_DATA_URI, {params: data} , config)
    .then(response => {
      console.log("get canvas response:",response.data)
      dispatch({ type: 'SET_CANVAS', data: response.data.canvas})
    }).catch(e => {
      console.log(e)
    })
  }
}

// if the user is logged in
const addCanvasToUser = (data) => {
  return (dispatch) => {
    const AUTH_SIGNUP_LOCAL_URI = `${API_ENDPOINT}/api/user/canvas`;
    axios
        .post(AUTH_SIGNUP_LOCAL_URI, data, config)
        .then((response) => {
          console.log("added canvas to user:", response.data)
            dispatch({ type: "SIGN_UP_LOCAL", data: response.data });
        })
        .catch((e) => {});
    dispatch({ type: "SIGN_UP_LOCAL", data: {} });
  };
};

const addAnswerToCanvas = (data) => {
  return (dispatch) => {
    //Create Answer
    const CREATE_ANSWER_DATA = `${API_ENDPOINT}/api/update-canvas`
    console.log(data)
    axios.post(CREATE_ANSWER_DATA, data, config)
    .then(response => {
      console.log("added answer to canvas: ", response.data)
      dispatch(getCanvas())
    }).catch(e => {
      console.log(e)
    })
  }
}

const updateAnswerToCanvas = (data) => {
  return (dispatch) => {
    //Create Answer
    const UPDATE_ANSWER_DATA = `${API_ENDPOINT}/api/update-canvas`
    console.log(data)
    axios.put(UPDATE_ANSWER_DATA, data, config)
    .then(response => {
      console.log("updated answer to canvas: ", response.data)
      dispatch(getCanvas())
    }).catch(e => {
      console.log(e)
    })
  }
}

const saveVersion = (data) => {
  return (dispatch) => {
    const UPDATE_CANVAS_DATA_URI = `${API_ENDPOINT}/api/canvas`
    axios.put(UPDATE_CANVAS_DATA_URI, data, config)
    .then(response => {
      console.log("saved verison to canvas: ",response.data)
      dispatch(getCanvas())
    }).catch(e => {
      console.log("Error")
    })
  }
}

const deleteCanvas = (data) => {
  return (dispatch) => {
    //Get UI data and store in database
    const DELETE_CANVAS_URI = `${API_ENDPOINT}/api/canvas`
    axios.delete(DELETE_CANVAS_URI, {params:data}, config)
      .then(response => {
        console.log("Canvas deleted successfully!");
      }).catch(e => {
        console.log("ERROR DELETING THE CANVAS")
      })
  }
}

export const updateCanvas = (data) => {
  return (dispatch) => {
    const UPDATE_CANVAS_DATA_URI = `${API_ENDPOINT}/api/canvas`
    axios.put(UPDATE_CANVAS_DATA_URI, data, config)
    .then(response => {
      console.log("updated canvas canvas: ",response.data)
      dispatch(getCanvas())
    }).catch(e => {
      console.log("Error")
    })
  }
}

export {
  createCanvas,getCanvas, addCanvasToUser, addAnswerToCanvas, updateAnswerToCanvas, saveVersion, deleteCanvas
}