import React, { Component } from 'react'
import {Container, Row, Col, Button, Form, Image, Modal} from 'react-bootstrap'
import ReactCrop from 'react-image-crop';

import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'   
import { css } from '@emotion/core';
import {setCanvas, updateUser} from '../../actions/accountActions'
import axios from 'axios'

import {API_ENDPOINT} from '../../constants/secrets'
import userIcon from '../../images/icons/userIcon.png'
import editIcon from '../../images/icons/editIcon.svg'
import '../../stylesheets/account.css'
import 'react-image-crop/dist/ReactCrop.css';



class ProfilePic extends Component{

    constructor(props)
    {
        super(props)
        this.state = {
            showImageCropModal  : false,
            isImageUploading    : false,
            uploadedImageLink   : null,
            showProfilePic      : false,
            crop: {
                unit: '%',
                width: 30,
                aspect: 1 / 1,
                },
                croppedImageUrl   : null
        }
        this.fileInput = React.createRef();
    }
    
    onSelectFile = e => {
        if (this.fileInput.current.files.length >0 && this.fileInput.current.files[0]) {
          const reader = new FileReader();
          reader.addEventListener('load', () =>
            this.setState({
                        uploadedImageLink   : reader.result, 
                        showImageCropModal  : true, 
                        isImageUploading    : false})
            );
          reader.readAsDataURL(this.fileInput.current.files[0]);
          this.setState({isImageUploading: true})
        }
      };

    handleUpload = (e) => {
        e.preventDefault()
        const formData = new FormData();
        formData.append("file", this.state.croppedImageBlob)
        const UPLOAD_FILE_DATA_URI = `${API_ENDPOINT}/api/upload`
        axios.post(UPLOAD_FILE_DATA_URI, formData, {crossDomain: true})
            .then(response => {
                let data = {
                    id: this.props.user.id,
                    updateType: "PROFILE_PIC",
                    profile_pic: response.data.link
                }
                this.props.updateUser(data)
                this.setState({showImageCropModal: false})

            }).catch(e => {
                console.log(e)
                this.setState({showImageCropModal: false})

            }) 
    }

    onImageLoaded = image => {
        this.imageRef = image;
      };
    
    onCropComplete = crop => {
        this.makeClientCrop(crop);
    };

    onCropChange = (crop, percentCrop) => {
        this.setState({ crop });
    };

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
        const [croppedImageBlob, croppedImageUrl] =  await this.getCroppedImg(
                                                                        this.imageRef,
                                                                        crop,
                                                                        'newFile.jpeg'
                                                                    );
        this.setState({ croppedImageUrl, croppedImageBlob });
        }
    }

    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    console.error('Canvas is empty');
                    return;
                }
                
                blob.name = fileName;
                window.URL.revokeObjectURL(this.fileUrl);
                this.fileUrl = window.URL.createObjectURL(blob);
                resolve([blob, this.fileUrl]);
            }, 'image/jpeg');
        });
    }


    render()
    {
        return <Col xs={{span:8, offset:2}} id="profilePic">
                    <Col xs={12} >
                        <Row style={{"borderRadius": "100%", "overflow":"hidden"}} onClick={() => this.setState({showProfilePic: true})}>
                            {this.props.user.profile_pic? 
                                <Image src={this.props.user.profile_pic} style={{"width":"100%", "height":"auto"}} fluid />: 
                                <Image src={userIcon} fluid />}
                        </Row>                        
                        
                        <div className="editWrapper">
                            <label htmlFor="fileinput">
                                <Image src={editIcon}></Image>
                                <input  id          = "fileinput" 
                                        ref         = {this.fileInput} 
                                        accept      = "image/*" 
                                        onChange    = {this.onSelectFile} 
                                        className   = "file-input"
                                        style={{display:'none'}} 
                                        type        = "file">

                                </input>
                            </label>
                            
                        </div>

                    </Col>

                    <Modal
                        size="sm"
                        show={this.state.showImageCropModal}
                        onHide={() => this.setState({showImageCropModal: false})}
                        aria-labelledby="example-modal-sizes-title-sm"
                        id="instructions-modal"
                    >
                        <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-sm">
                            Crop Profile Image
                        </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row className="justify-content-center">
                                <ReactCrop
                                    src={this.state.uploadedImageLink}
                                    crop={this.state.crop}
                                    onImageLoaded={this.onImageLoaded}
                                    onComplete={this.onCropComplete}
                                    onChange={this.onCropChange}
                                />
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <Col xs="12">
                                <Col xs={{span:10, offset:1}} lg={{span:8, offset:2}}>
                                    <Row className="justify-content-center">
                                        <Button variant="dark" onClick={this.handleUpload}>Done</Button>
                                    </Row>
                                </Col>
                            </Col>
                        </Modal.Footer>
                    </Modal>

                    <Modal
                        size="md"
                        show={this.state.showProfilePic}
                        onHide={() => this.setState({showProfilePic: false})}
                        aria-labelledby="example-modal-sizes-title-sm"
                        id="instructions-modal"
                    >
                        <Modal.Header closeButton>
                        </Modal.Header>
                        <Modal.Body>
                            <Row className="justify-content-center">
                                <Image src={this.props.user.profile_pic} fluid></Image>
                            </Row>
                        </Modal.Body>
                    </Modal>

                </Col>
    }

}



const mapStateToProps = (state) => {
    return { 
        user: state.user
    }
}

const mapDispatchToProps = (dispatch) => {
    
    return { 
        updateUser: (data) => dispatch(updateUser(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProfilePic))