import React from 'react'
import {Container, Row, Col} from 'react-bootstrap'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { connect } from 'react-redux';   
import {saveAdditionalInfo} from '../../actions/canvasAddOnsActions';


class DeleteAdditionalText extends React.Component
{
    constructor(props)
    {
        super(props)
        this.state = {
            description: "",
            text: ""
        }
    }

    
    render()
    {
            return <Row className={"justify-content-center"}>  
                <h4 style={{"width":"100%", "textAlign":"center"}}>Are you sure you want to delete the following tile?</h4>
                
                <Row>
                    <h5 style={{"width":"100%", "textAlign":"center"}}>{this.props.description}</h5>
                    <p style={{"width":"100%", "textAlign":"center"}}>{this.props.text}</p>

                </Row>

                <Col xs={12}>
                    <Row className={"justify-content-center"}>
                        <Button variant="dark" onClick={() => this.props.confirmDelete(false)}>No</Button>
                        <Button variant="dark" style={{"marginLeft":"1rem", "marginRight":"1rem"}} onClick={() => this.props.confirmDelete(true)}>Yes</Button>
                    </Row>  
                </Col>
            </Row>
    }
}

class AddAdditionalText extends React.Component
{
    constructor(props)
    {
        super(props)
        this.state = {
            description: "",
            text: ""
        }
    }

    handleChange = (e) => {
        let {name, value} = e.target
        this.setState({[name]: value}, () =>  this.props.onChange(this.state))
    }

    render()
    {
            return <Row className={"justify-content-center"}>  
                <Form onSubmit={(e) => {e.preventDefault(); this.props.onSubmit(e)}}>
                    <Form.Group>
                        <Form.Label>Title</Form.Label>
                        <Form.Control type="text" name="description" value={this.state.description} onChange={this.handleChange}/>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Type below</Form.Label>
                        <Form.Control as="textarea" rows={3} name="text" value={this.state.text} onChange={this.handleChange}/>
                    </Form.Group>
                    <Button variant="dark" type="submit">
                        Submit
                    </Button>

                </Form>
            </Row>
    }
}
class EditAdditionalText extends React.Component
{
    constructor(props)
    {
        super(props)
        this.state = {
            description: this.props.data.description? this.props.data.description: "",
            text: this.props.data.text? this.props.data.text: ""
        }

    }

    handleChange = (e) => {
        let {name, value} = e.target
        this.setState({[name]: value}, () =>  this.props.onChange(this.state))
    }


    render()
    {
            return <Row className={"justify-content-center"}>  
                <Form onSubmit={(e) => {e.preventDefault(); this.props.onSubmit(e)}}>
                    <Form.Group>
                        <Form.Label>Title</Form.Label>
                        <Form.Control type="text" name="description" value={this.state.description} onChange={this.handleChange}/>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Type below</Form.Label>
                        <Form.Control as="textarea" rows={3} name="text" value={this.state.text} onChange={this.handleChange}/>
                    </Form.Group>
                    <Button variant="dark" type="submit">
                        Submit
                    </Button>

                </Form>
            </Row>
    }
}

class AdditionalText extends React.Component
{
    constructor(props)
    {
        super(props)
        this.state = {
            description: "",
            text: "",
            editIndex: -1,
            deleteIndex: -1,
            addMode: false,
            mode: "DEFAULT"
        }
    }

    handleSubmit = (e) => {
        e.preventDefault()
        let new_additional_text = this.props.additional_text.slice()
        if(this.state.editIndex >= 0)
        {
            new_additional_text[this.state.editIndex] = {"description":this.state.description, "text":this.state.text}
        }
        else{
            new_additional_text.push({"description":this.state.description, "text":this.state.text})
        }
        this.props.saveAdditionalInfo({"id": this.props.id, "additional_text":new_additional_text})
        // this.props.saveAdditionalInfo()
        this.props.onContinue()
    }

    handleEditInputs = (e) => {
        this.setState({text: e.text, description: e.description})
    }

    handleChange = (e) => {
        let {name, value} = e.target
        this.setState({[name]: value})
    }

    confirmDelete = (toggle) => {
        if(toggle)
        {
            let new_additional_text = this.props.additional_text.slice()
            console.log(this.state.deleteIndex)
            new_additional_text.splice(this.state.deleteIndex, 1)
            console.log(new_additional_text)
            this.props.saveAdditionalInfo({"id": this.props.id, "additional_text":new_additional_text})
        }

        this.setState({mode: "DEFAULT", deleteIndex: -1, editIndex: -1})
    }

    render()
    {
        let additional_texts_renders = []
        for(let i=0; i<this.props.additional_text.length; i++)
        {
            additional_texts_renders.push(<Col xs={12} lg={3}>
                <h5>{this.props.additional_text[i]["description"]}</h5>
                <p>{this.props.additional_text[i]["text"]}</p>
                <Row>
                    <Col xs={6}>
                        <p style={{"cursor":"pointer", "fontWeight":"700"}} onClick={() => this.setState({mode:"EDIT", editIndex: i})}>Edit</p>
                    </Col>
                    <Col xs={6}>
                        <p style={{"cursor":"pointer", "fontWeight":"700"}} onClick={() => this.setState({mode: "DELETE", deleteIndex: i})}>Delete</p>
                    </Col>
                </Row>
            </Col>)
        }

        additional_texts_renders.push(<Button onClick={() => this.setState({mode:"ADD", editIndex: -1, deleteIndex: -1})}>Add New</Button>)

        return (
            <Container>
                <Row className={"justify-content-center"}>
                    <h1>Additional Texts</h1>
                </Row>

                {this.state.mode === "DEFAULT" && 
                <Row>
                    {additional_texts_renders}
                </Row>
                }

                {this.state.mode === "EDIT" && 
                <EditAdditionalText data={this.props.additional_text[this.state.editIndex]} 
                                    onSubmit={this.handleSubmit} 
                                    onChange={this.handleEditInputs}>

                                    </EditAdditionalText>
                }

                {this.state.mode === "ADD" && 
                    <AddAdditionalText  onSubmit={this.handleSubmit} 
                                        onChange={this.handleEditInputs}></AddAdditionalText>
                }

                {this.state.mode === "DELETE" && 
                    <DeleteAdditionalText  description={this.props.additional_text[this.state.deleteIndex]["description"]} 
                                        text={this.props.additional_text[this.state.deleteIndex]["text"]}
                                        confirmDelete={this.confirmDelete}></DeleteAdditionalText>
                }

                {/* 
                SCREENS 
                1. All comments with add, edit and delete options
                2. Add comment modal
                3. Edit comment modal
                4. Delete confirmation
                */}
            </Container>
        )
    }
}

/**
 * 
 * @param {*} state
 * Maps Redux state to component props 
 */
const mapStateToProps = (state) => {
    let additional_text = state.canvas.additional_text;
  
    return {
        id: state.canvas.id,
        additional_text: additional_text
  
    }
  }
  /**
  * Maps Redux actions to component props
  * @param {*} dispatch
  *  
  */
  const mapDispatchToProps = (dispatch) => {
  
    return {
      saveAdditionalInfo: (data) => dispatch(saveAdditionalInfo(data)),
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalText)